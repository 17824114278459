import {
    BackendPackage,
    BackendPackageRequest,
    BackendQuickQuotationPackage,
    Package,
    PackageRequest,
} from 'api/packages/types';
import { Addon, AddonCode, AddonInput } from 'api/search';
import { PriceSubscriptionTier } from 'api/subscriptions/types';
import { Alpha2Code } from 'i18n-iso-countries';
import { DateTime } from 'luxon';
import { Currency } from 'types/UserData';

// The values in this enum are dependent on the backend types.
// Please do not alter them.
export enum ConsignmentStatus {
    ACTIVE = 'ACTIVE',
    ORDERED = 'ORDERED',
    IMPORT = 'IMPORT',
    CONFIRMED = 'CONFIRMED',
    IN_TRANSIT = 'IN TRANSIT',
    DELIVERED = 'DELIVERED',
    CANCEL_PENDING = 'CANCEL PENDING',
    CANCELED = 'CANCELED',
    BOOKING_FAILED = 'BOOKING FAILED',
    DELIVERED_MARKED = 'DELIVERED MARKED',
    EXCEPTION = 'EXCEPTION',
    RETURN_BOOKED = 'RETURN BOOKED',
    DRAFT = 'DRAFT',
    DELETED = 'DELETED',
    PENDING = 'PENDING',
}

export interface Relationship {
    productCode?: ProductCode;
    addonCode1: string;
    addonCode2: string;
    relationshipType: string;
}

export interface AddonDetails {
    carriers: Set<CourierCode>;
    relationships: Relationship[];
}

export enum CourierCode {
    DHL_FREIGHT_SWEDEN = 'dhl_freight_sweden',
    DSV = 'dsv',
    DSV_GERMANY = 'dsv_germany',
    DSV_SWEDEN = 'dsv_sweden',
    TNT = 'tnt',
    UPS = 'ups',
    DPD_GERMANY = 'dpd_germany',
    // Our own courier code is used for freight forwarding
    SENDIFY = 'sendify',
    // below are currently not used in bookng these are used in the qualificaiton modal
    GLS = 'gls',
    DB_SCHENKER = 'db_schenker',
    DHL_EXPESS = 'dhl_express',
    POSTNORD = 'postnord',
    BRING = 'bring',
    DACHSER = 'dachser',
    FEDEX = 'fedex',
    FEDEX_GLOBAL = 'fedex_global',
    DEUTSCHE_POST = 'deutsche_post',
    HERMES = 'hermes',
    K_N = 'k_n',
    DHL_FREIGHT_GERMANY = 'dhl_freight_germany',
    UPS_SWEDEN = 'ups_sweden',
    UPS_GERMANY = 'ups_germany',
    TNT_SWEDEN = 'tnt_sweden',
    NTEX_SWEDEN = 'ntex_sweden',
}

export enum ProductCode {
    DHL_SWEDEN_BATCH = 'dhl_sweden_batch',
    DHL_SWEDEN_HALF_PALLET = 'dhl_sweden_half_pallet',
    DHL_SWEDEN_PALLET = 'dhl_sweden_pallet',
    DHL_SWEDEN_PARCEL = 'dhl_sweden_parcel',
    DHL_SWEDEN_PIECE = 'dhl_sweden_piece',
    DHL_PARCEL_EXPORT = 'dhl_parcel_export',
    DHL_SWEDEN_SERVICE_POINT = 'dhl_sweden_service_point',
    DHL_SWEDEN_SERVICE_POINT_RETURN = 'dhl_sweden_service_point_return',
    DHL_SWEDEN_PARCEL_HOME_EVENING1722 = 'dhl_sweden_parcel_home_evening1722',
    DHL_SWEDEN_PARCEL_HOME_EVENING1722_RETURN = 'dhl_sweden_parcel_home_evening1722_return',
    DHL_EUROCONNECT = 'dhl_euroconnect',
    DHL_EUROLINE = 'dhl_euroline',
    DHL_PARCEL_CONNECT = 'dhl_parcel_connect',
    TNT_ECONOMY_EXPRESS = 'tnt_economy_express',
    TNT_ECONOMY_EXPRESS_1200 = 'tnt_economy_express_1200',
    TNT_EXPRESS = 'tnt_express',
    TNT_EXPRESS_0900 = 'tnt_express_0900',
    TNT_EXPRESS_1000 = 'tnt_express_1000',
    TNT_EXPRESS_1200 = 'tnt_express_1200',
    TNT_SCANDINAVIAN_DOMESTIC = 'tnt_scandinavian_domestic',
    TNT_SWEDEN_EXPRESS_0900_DOMESTIC = 'tnt_sweden_express_0900_domestic',
    TNT_SWEDEN_EXPRESS_1000_DOMESTIC = 'tnt_sweden_express_1000_domestic',
    TNT_SWEDEN_EXPRESS_1200_DOMESTIC = 'tnt_sweden_express_1200_domestic',
    TNT_SWEDEN_EXPRESS_DOMESTIC = 'tnt_sweden_express_domestic',
    TNT_SWEDEN_ECONOMY_EXPRESS = 'tnt_sweden_economy_express',
    TNT_SWEDEN_EXPRESS_INTERNATIONAL = 'tnt_sweden_express_international',
    UPS_EXPRESS = 'ups_express',
    UPS_EXPRESS_PLUS = 'ups_express_plus',
    UPS_EXPRESS_SAVER = 'ups_express_saver',
    UPS_EXPEDITED = 'ups_expedited',
    UPS_STANDARD = 'ups_standard',
    TEST_UPS_EXPRESS_SAVER = 'test_ups_express_saver',
    UPS_EXPRESS_SAVER_ENVELOPE = 'ups_express_saver_envelope',
    DSV_ROAD_PIECE = 'dsv_road_piece',
    DSV_ROAD_PIECE_08 = 'dsv_road_piece_08',
    DSV_ROAD_PIECE_10 = 'dsv_road_piece_10',
    DSV_ROAD_PIECE_12 = 'dsv_road_piece_12',
    DSV_ROAD_PIECE_16 = 'dsv_road_piece_16',
    DSV_ROAD_BATCH = 'dsv_road_batch',
    DSV_ROAD_INTERNATIONAL = 'dsv_road_international',
    DSV_PALLET_DOMESTIC = 'dsv_pallet_domestic',
    DSV_SWEDEN_ROAD_DOMESTIC = 'dsv_sweden_road_domestic',
    DSV_SWEDEN_PALLET_DOMESTIC = 'dsv_sweden_pallet_domestic',
    DSV_SWEDEN_BATCH_DOMESTIC = 'dsv_sweden_batch_domestic',
    DSV_SWEDEN_ROAD_INTERNATIONAL = 'dsv_sweden_road_international',
    DSV_SWEDEN_PALLET_INTERNATIONAL = 'dsv_sweden_pallet_international',
    DSV_SWEDEN_BATCH_INTERNATIONAL = 'dsv_sweden_batch_international',
    DSV_GERMANY_ROAD = 'dsv_germany_road',
    DSV_GERMANY_ROAD_PALLET = 'dsv_germany_road_pallet',
    DSV_GERMANY_ROAD_EXPRESS_08 = 'dsv_germany_road_express_08',
    DSV_GERMANY_ROAD_EXPRESS_10 = 'dsv_germany_road_express_10',
    DSV_GERMANY_ROAD_EXPRESS_12 = 'dsv_germany_road_express_12',
    DSV_GERMANY_ROAD_EXPRESS_16 = 'dsv_germany_road_express_16',
    DSV_GERMANY_ROAD_INTERNATIONAL = 'dsv_germany_road_international',

    DSV_GERMANY_PALLET_DOMESTIC = 'dsv_germany_pallet_domestic',
    DSV_PALLET_INTERNATIONAL = 'dsv_pallet_international',

    DHL_SWEDEN_HOME_DELIVERY = 'dhl_sweden_home_delivery',
    SENDIFY_FREIGHT_FORWARDING = 'sendify_freight_forwarding',
    DPD_GERMANY_CLASSIC_PARCEL = 'dpd_germany_classic_parcel',
    DPD_GERMANY_CLASSIC_PARCEL_NON_CONVEYABLE = 'dpd_germany_classic_parcel_non_conveyable',
    DPD_GERMANY_CLASSIC_PARCEL_EUROPE = 'dpd_germany_classic_parcel_europe',
    DPD_GERMANY_CLASSIC_PARCEL_EUROPE_NON_CONVEYABLE = 'dpd_germany_classic_parcel_europe_non_conveyable',
    DHL_GERMANY_EUROCONNECT_DOMESTIC = 'dhl_germany_euroconnect_domestic',
    DHL_GERMANY_EUROCONNECT_INTERNATIONAL = 'dhl_germany_euroconnect_international',
    UPS_SWEDEN_STANDARD = 'ups_sweden_standard',
    UPS_SWEDEN_EXPRESS_SAVER = 'ups_sweden_express_saver',
    UPS_SWEDEN_EXPRESS = 'ups_sweden_express',
    UPS_SWEDEN_EXPRESS_PLUS = 'ups_sweden_express_plus',
    UPS_SWEDEN_EXPEDITED = 'ups_sweden_expedited',
    UPS_GERMANY_STANDARD = 'ups_germany_standard',
    UPS_GERMANY_EXPRESS = 'ups_germany_express',
    UPS_GERMANY_EXPRESS_PLUS = 'ups_germany_express_plus',
    UPS_GERMANY_EXPRESS_SAVER = 'ups_germany_express_saver',
    UPS_GERMANY_EXPEDITED = 'ups_germany_expedited',
    FEDEX_GERMANY_REGIONAL_ECONOMY = 'fedex_germany_regional_economy',
    FEDEX_SWEDEN_REGIONAL_ECONOMY = 'fedex_sweden_regional_economy',
    FEDEX_GERMANY_INTERNATIONAL_ECONOMY = 'fedex_germany_international_economy',
    FEDEX_GERMANY_INTERNATIONAL_PRIORITY = 'fedex_germany_international_priority',
    FEDEX_GERMANY_INTERNATIONAL_PRIORITY_EXPRESS = 'fedex_germany_international_priority_express',
    FEDEX_GERMANY_PRIORITY_DOMESTIC = 'fedex_germany_priority_domestic',
    FEDEX_GERMANY_FIRST_DOMESTIC = 'fedex_germany_first_domestic',
    FEDEX_GERMANY_PRIORITY_EXPRESS_DOMESTIC = 'fedex_germany_priority_express_domestic',
    FEDEX_SWEDEN_INTERNATIONAL_ECONOMY = 'fedex_sweden_international_economy',
    FEDEX_SWEDEN_INTERNATIONAL_PRIORITY = 'fedex_sweden_international_priority',
    FEDEX_SWEDEN_INTERNATIONAL_PRIORITY_EXPRESS = 'fedex_sweden_international_priority_express',
    FEDEX_SWEDEN_PO_DOMESTIC = 'fedex_sweden_po_domestic',
    FEDEX_SWEDEN_PRIORITY_DOMESTIC = 'fedex_sweden_priority_domestic',
    FEDEX_SWEDEN_PRIORITY_EXPRESS_DOMESTIC = 'fedex_sweden_priority_express_domestic',
    FEDEX_SWEDEN_FIRST_DOMESTIC = 'fedex_sweden_first_domestic',
    NTEX_SWEDEN_DOMESTIC = 'ntex_sweden_domestic',
}

export interface BackendAddress {
    address_line_1: string;
    address_line_2?: string;
    address_line_3?: string;
    zip_code?: string;
    city: string;
    state?: string;
    country_code: Alpha2Code;
    created_at?: string;
    updated_at?: string | null;
}

export interface Erp {
    id: number;
    name: string;
}

export interface Address {
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    postalCode?: string;
    city: string;
    state?: string;
    countryCode: Alpha2Code;
    createdAt?: DateTime;
    updatedAt?: DateTime;
}

export interface BackendPerson {
    id?: number;
    first_name: string;
    last_name: string;
    mobile_number_1: string;
    phone_number_1: string;
    email: string;
    created_at?: string;
    updated_at?: string | null;
}

export interface Person {
    id?: number;
    firstName: string;
    lastName?: string;
    mobilePhoneNumber: string;
    phoneNumber: string;
    email: string;
    createdAt?: DateTime;
    updatedAt?: DateTime;
}

export interface BackendOwner {
    activated: number;
    created_at?: string;
    email: string;
    first_name: string;
    id?: number;
    last_name: string | null;
    first_booker: boolean;
    mobile_number: string;
    updated_at?: string | null;
    username: string;
}

export interface Owner {
    activated: boolean;
    email: string;
    firstName: string;
    id?: number;
    lastName?: string;
    mobileNumber: string;
    hasBooked: boolean;
    createdAt?: DateTime;
    updatedAt?: DateTime;
    username: string;
}

export type TrackingCode =
    | 'ORDERED'
    | 'CONFIRMED'
    | 'PICKUP'
    | 'IN_TRANSIT'
    | 'EXCEPTION'
    | 'DELIVERY'
    | 'DELIVERED';

export interface BackendTrackingEvent {
    code: TrackingCode;
    description: string;
    location_name: string;
    tracking_id: string;
    reference: string;
    created_at: string;
}

export interface TrackingEvent {
    code: TrackingCode;
    description: string;
    locationName: string;
    trackingId: string;
    reference: string;
    createdAt: DateTime;
}

export type Source =
    | 'SENDIFY'
    | 'FORTNOX_ORDER'
    | 'FORTNOX_INVOICE'
    | 'EXTERNAL_API';

export interface BackendExternalSource {
    external_id: string;
    external_source: Source;
    system?: string;
}

export interface ExternalSource {
    id: string;
    source: Source;
    system?: string;
}
export type OrderStatus =
    | 'quote'
    | 'mapping_failure'
    | 'paused'
    | 'parsed'
    | 'invoiced'
    | 'partially_invoiced';
export interface BackendOrder {
    id: number;
    expected_cost: string;
    cost: string;
    tracking_id: string;
    courier_loading_id: string;
    estimated_delivery_earliest_at: string;
    estimated_delivery_latest_at: string;
    created_at?: string;
    updated_at?: string;
    canceled_at?: string;
    status: OrderStatus;
    product: BackendProduct;
    tracking_events?: BackendTrackingEvent[];
    loading_instructions: string;
    unloading_instructions: string;
}

export interface Order {
    id: number;
    expectedCost: number;
    cost: number;
    trackingId: string;
    courierLoadingId: string;
    estimatedDeliveryEarliestAt: DateTime;
    estimatedDeliveryLatestAt: DateTime;
    status: OrderStatus;
    createdAt?: DateTime;
    updatedAt?: DateTime;
    canceledAt?: DateTime;
    product: Product;
    trackingEvents?: TrackingEvent[];
    loadingInstructions: string;
    unloadingInstructions: string;
}

export interface Insurance {
    currency: Currency;
    goodsValue: number;
    price: number;
}

interface BasicBackendAddon {
    cost: number;
    currency: string;
}

export interface BackendCarrierAddons {
    notification?: BasicBackendAddon & {
        is_private: boolean;
    };

    loading_tailgate_lift?: BasicBackendAddon;
    unloading_tailgate_lift?: BasicBackendAddon;
    delivery_notice?: BasicBackendAddon;
    definite_loading?: {
        loading_date: string;
    };
    definite_unloading?: {
        unloading_date: string;
    };
    insurance?: BasicBackendAddon & {
        goods_value: number;
    };
    insurance_service_fee?: BasicBackendAddon;
    guaranteed_delivery_time?: BasicBackendAddon & {
        time_of_day: string;
    };
    delivery_without_proof?: BasicBackendAddon;
    receiver_pays?: {
        receiver_pays_account_number: string;
    };
    without_pickup?: BasicBackendAddon;
    pickup_notification?: BasicBackendAddon;
    green_shipping?: BasicBackendAddon;
    dangerous_goods_limited_quantity?: BasicBackendAddon;
}

export interface BackendConsignment {
    addons?: BackendCarrierAddons;
    desirable_product_code: string | null;
    external_reference: string;
    sender_reference: string;
    receiver_reference: string;
    from_address: BackendAddress;
    from_is_private: boolean;
    from_name: string;
    from_person: BackendPerson | null;
    from_via: boolean;
    from_via_address: BackendAddress | null;
    from_via_is_private: boolean | null;
    from_via_name: string | null;
    from_via_person: BackendPerson | null;
    id: string;
    owner?: BackendOwner;
    packages: BackendPackage[];
    pickup_at_earliest: string;
    pickup_at_latest: string | null;
    status: string;
    team_id: string;
    to_address: BackendAddress;
    to_is_private: boolean;
    to_name: string;
    to_person: BackendPerson | null;
    to_via: boolean;
    to_via_address: BackendAddress | null;
    to_via_is_private: boolean | null;
    to_via_name: string | null;
    to_via_person: BackendPerson | null;
    total_weight_kg: number;
    created_at?: string;
    updated_at?: string | null;
    order?: BackendOrder;
    insurance?: Insurance;
    tags?: string[];
    external_source?: BackendExternalSource;
    error_message?: string;
    has_customs_invoices: boolean;
}

export interface BackendConsignmentRequest {
    external_reference: string;
    sender_reference: string | null;
    receiver_reference: string | null;
    from_address: BackendAddress;
    from_is_private: boolean;
    from_name: string;
    from_person: BackendPerson;
    from_via: boolean | null;
    from_via_address: BackendAddress | null;
    from_via_is_private: boolean | null;
    from_via_name: string | null;
    from_via_person: BackendPerson | null;
    packages: BackendPackageRequest[];
    pickup_at_earliest: string;
    pickup_at_latest: string;
    to_address: BackendAddress;
    to_is_private: boolean;
    to_name: string;
    to_person: BackendPerson;
    to_via: boolean | null;
    to_via_address: BackendAddress | null;
    to_via_is_private: boolean | null;
    to_via_name: string | null;
    to_via_person: BackendPerson | null;
}

export interface BackendConsignmentPatch {
    external_reference?: string;
    from_address?: BackendAddress;
    from_is_private?: boolean;
    from_name?: string;
    from_person?: BackendPerson;
    from_via?: boolean;
    from_via_address?: BackendAddress | null;
    from_via_is_private?: boolean | null;
    from_via_name?: string | null;
    from_via_person?: BackendPerson | null;
    packages?: BackendPackageRequest[];
    pickup_at_earliest?: string;
    pickup_at_latest?: string;
    to_address?: BackendAddress;
    to_is_private?: boolean;
    to_name?: string;
    to_person?: BackendPerson;
    to_via?: boolean;
    to_via_address?: BackendAddress | null;
    to_via_is_private?: boolean | null;
    to_via_name?: string | null;
    to_via_person?: BackendPerson | null;
}

export interface Party {
    address: Address;
    name: string;
    person?: Person;
    isPrivate: boolean;
}

export interface BackendParty {
    address: BackendAddress;
    name: string;
    person?: BackendPerson;
    is_private: boolean;
}

export interface BasicCarrierAddon {
    cost: number;
    currency: string;
}

export interface CarrierAddons {
    withoutPickup?: BasicCarrierAddon;
    notification?: BasicCarrierAddon & {
        isPrivate: boolean;
    };
    loadingTailgateLift?: BasicCarrierAddon;
    unloadingTailgateLift?: BasicCarrierAddon;
    deliveryNotice?: BasicCarrierAddon;
    definiteLoading?: {
        loadingDate: DateTime;
    };
    definiteUnloading?: {
        unloadingDate: DateTime;
    };
    insurance?: BasicCarrierAddon & {
        goodsValue: number;
    };
    insuranceServiceFee?: BasicCarrierAddon;
    guaranteedDeliveryTime?: BasicCarrierAddon & {
        timeOfDay: string;
    };
    deliveryWithoutProof?: BasicCarrierAddon;
    receiverPays?: {
        receiverPaysAccountNumber: string;
    };
    pickupNotification?: BasicCarrierAddon;
    greenShipping?: BasicCarrierAddon;
    dangerousGoodsLimitedQuantity?: BasicCarrierAddon;
}

export interface Consignment {
    id: string;
    carrierAddons?: CarrierAddons;
    productCode?: string;
    externalReference?: string;
    senderReference: string;
    receiverReference: string;
    sender: Party;
    viaSender?: Party;
    recipient: Party;
    viaRecipient?: Party;
    owner?: Owner;
    packages: Package[];
    pickupAtEarliest: DateTime;
    pickupAtLatest?: DateTime;
    status: ConsignmentStatus;
    teamId: string;
    totalWeightKg: number;
    createdAt?: DateTime;
    updatedAt?: DateTime;
    order?: Order;
    insurance?: Insurance;
    errorMessage?: string;
    externalSource?: ExternalSource;
    tags?: string[];
    hasCustomsInvoices: boolean;
}

export interface PartyRequest {
    address: Address;
    name: string;
    person: Person;
    isPrivate: boolean;
}

export interface ConsignmentRequest {
    externalReference?: string;
    senderReference?: string;
    receiverReference?: string;
    externalSource?: ExternalSource;
    sender: PartyRequest;
    viaSender?: PartyRequest;
    recipient: PartyRequest;
    viaRecipient?: PartyRequest;
    packages: PackageRequest[];
    pickupAtEarliest: DateTime;
    pickupAtLatest: DateTime;
}

export interface ConsignmentPatch {
    externalReference?: string;
    sender?: PartyRequest;
    viaSender?: PartyRequest | null; // Set to null to clear it in the backend
    recipient?: PartyRequest;
    viaRecipient?: PartyRequest | null; // Set to null to clear it in the backend
    packages?: PackageRequest[];
    pickupAtEarliest?: DateTime;
    pickupAtLatest?: DateTime;
}

export interface BackendCourier {
    id?: number;
    code: CourierCode;
    short_title: string;
    long_title: string;
    static_pickup: number;
    created_at?: string;
    updated_at?: string | null;
}

export interface Courier {
    id?: number;
    code: CourierCode;
    shortTitle: string;
    longTitle: string;
    staticPickup: boolean;
    createdAt?: DateTime;
    updatedAt?: DateTime;
}

export interface BackendProduct {
    code: ProductCode;
    name?: string; // Sometimes outdated, use our translations instead.
    transaction_type: string;
    earliest_delivery_hour_of_day: number;
    latest_delivery_hour_of_day: number;
    courier: BackendCourier;
}

export interface Product {
    code: ProductCode;
    transactionType: string;
    earliestDeliveryHourOfDay: number;
    latestDeliveryHourOfDay: number;
    courier: Courier;
}

export interface AlternativePrice {
    priceTier: PriceSubscriptionTier;
    price: number;
}

export interface BackendSearchResult {
    customer_cost: number;
    currency: string;
    search_information?: string[];
    customer_surcharge_pct: number;
    weight_kg: number;
    dimensional_weight_kg: number | null;
    chargable_weight_kg: number;
    loading_earliest_at: string;
    loading_latest_at: string;
    loading_cutoff_at: string;
    unloading_earliest_at: string;
    unloading_latest_at: string;
    lead_time_working_days_min: number;
    lead_time_working_days_max: number;
    lead_time_hours_min: number;
    lead_time_hours_max: number;
    timezone: string;
    product: BackendProduct;
    id: number;
    addons: Addon[];
    availableAddonCodes: AddonCode[];
    unsupportedAddonCodes: AddonCode[];
    unsupportedAddonInput: AddonInput[];
    alternativePrice: {
        priceTier: string;
        price: number;
    };
    bookingRequirements: BackendBookingRequirements | null;
}

export interface SearchResults {
    data: SearchResult[];
    addonInput: AddonInput[];
    requestId: string | null;
}
export interface BookingRequirements {
    packages: Package[];
    farthestBookingTimeAllowed?: DateTime;
    pickupDaysAheadMax?: number;
}
export interface BackendBookingRequirements {
    packages: BackendPackage[];
    farthestBookingTimeAllowed?: string;
    pickupDaysAheadMax?: number;
}

export interface SearchResult {
    id: number;
    customerCost: number;
    currency: string;
    searchInformation?: string[];
    weightKg: number;
    loadingEarliestAt: DateTime;
    loadingLatestAt: DateTime;
    loadingCutoffAt: DateTime;
    unloadingEarliestAt: DateTime;
    unloadingLatestAt: DateTime;
    leadTimeWorkingDaysMin: number;
    leadTimeWorkingDaysMax: number;
    product: Product;
    addons?: Addon[];
    unsupportedAddonInput: AddonInput[];
    unsupportedAddonCodes: AddonCode[];
    availableAddonCodes: AddonCode[];
    alternativePrice: AlternativePrice | null;
    bookingRequirements: BookingRequirements | null; // null means that the option can be booked without any changes
}

export interface BackendQuickQuotationConsignment {
    external_reference: null;
    from_address: BackendAddress;
    from_is_private: false;
    from_name: string;
    from_person: BackendPerson;
    from_via: false;
    from_via_address: null;
    from_via_is_private: false;
    from_via_person: null;
    packages: BackendQuickQuotationPackage[];
    pickup_at_earliest: string;
    team_id: string;
    to_address: BackendAddress;
    to_is_private: boolean;
    to_person: BackendPerson;
    to_via: false;
    to_via_address: null;
    to_via_is_private: false;
    to_via_person: null;
}

export interface SearchOptions {
    receiverPays?: ReceiverPaysOptions;
}

export interface ReceiverPaysOptions {
    accountNumber: string;
    carrierCode: string;
}

export function isRequest(
    c: Consignment | ConsignmentRequest
): c is ConsignmentRequest {
    return (c as Consignment).id === undefined;
}
