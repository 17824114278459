import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
    display: grid;
    grid-row-gap: ${({ theme }) => theme.space.s4};
    grid-template-columns: 100%;
    white-space: normal;
`;

export const DimensionsWrapper = styled.div`
    align-items: start;
    display: grid;
    grid-template-columns: 1fr auto 1fr auto 1fr;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
    }
`;

export const DetailsWrapper = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.grey400};
    display: grid;
    grid-row-gap: 16px;
    grid-template-columns: 100%;
    padding: 16px;
    transition: background-color 300ms linear 50ms;
`;
export const PackageTypesWrapper = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
`;
export const TimesWrapper = styled.div`
    padding: 9px;
    padding-top: 29px;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        display: none;
    }
`;

interface PackageProps {
    selected: boolean;
    readOnly?: boolean;
}

export const PackageWrapper = styled.div<PackageProps>`
    align-items: end;
    ${({ theme, selected }) =>
        selected
            ? css`
                  border: 1px solid ${theme.colors.black};
                  box-shadow: inset 0 0 0 1px ${theme.colors.black};
                  grid-template-rows: auto auto auto;
              `
            : css`
                  border: 1px solid ${theme.colors.grey400};
                  grid-template-rows: auto auto;
              `};
    ${({ theme, readOnly, selected }) =>
        readOnly && !selected
            ? css`
                  color: ${theme.colors.grey100};
              `
            : ''};
    background: white;
    cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};
    display: grid;
    justify-items: center;
    padding: 12px;

    img {
        align-self: start;
        height: 38px;
        width: 38px;
        ${({ selected }) => !selected && 'filter: grayscale(100%);'}
    }
`;
