import { SearchResult, isRequest } from 'api/consignments/types';
import { CarrierAddonCosts } from 'api/products/types';
import { Team } from 'api/teams/types';
import { User } from 'api/users/types';
import {
    getConsignmentSource,
    getHashedEmail,
    getMaximumPackageHeight,
    getMaximumPackageLength,
    getMaximumPackageWeight,
    getMaximumPackageWidth,
    getPrivate,
    getQualificationMinMax,
    getTotalWeight,
    getVolume,
} from 'external/analytics/calculations';
import { requiresEuCustomsInvoice } from 'helpers/EuHelper';
import { BookingConsignment } from 'hooks/Booking/types';
import { Qualification } from 'hooks/Qualification/types';

import { BookingRequest } from '../../api/booking/types';
import { QualificationEvent } from './types';

export const getLoginEvent = (user: User, team: Team) => {
    const hashedEmail = getHashedEmail(user.email);
    return {
        hashed_email: hashedEmail,
        email: user.email,
        team_id: team.id,
        system: 'React',
    };
};

export const getFirstCheckoutEvent = (email: string, currency: string) => {
    const hashedEmail = getHashedEmail(email);
    return {
        hashed_email: hashedEmail,
        currency,
        system: 'React',
    };
};

export const getSignupEvent = (
    email: string,
    method: string,
    teamId: string,
    consentsToMarketing: boolean,
    destination?: string,
    product?: string,
    experiments?: { [key: string]: any }
) => {
    const hashedEmail = getHashedEmail(email);

    return {
        hashed_email: hashedEmail,
        method,
        system: 'React',
        product,
        destination,
        team_id: teamId,
        // consents_to_marketing is required in Hubspot (and only in Hubspot) for our sales team
        consents_to_marketing: consentsToMarketing,
        // The label field is required by Google Analytics and Precis,
        // see https://segment.com/docs/connections/destinations/catalog/google-analytics/
        // it will be translated to the "Event Label" of the event
        label: method,
        ...experiments,
    };
};

export const getCheckoutEvent = (
    email: string,
    numberOfConsignments: any,
    currency: string,
    revenue: number
) => {
    const hashedEmail = getHashedEmail(email);
    return {
        hashed_email: hashedEmail,
        number_of_consignments: numberOfConsignments,
        currency,
        revenue,
        system: 'React',
    };
};

export const getConsignmentUnbookedEvent = (shipment: any) => {
    let trackingId = '';
    if (shipment.id) {
        trackingId = shipment.id;
    }

    return {
        consignment_id: shipment.id,
        created_at: shipment.created_at,
        pickup_at_latest: shipment.pickup_at_latest,
        tracking_id: trackingId,
        expected_cost: shipment.order.expected_cost,
        system: 'React',
    };
};

export const getConsigmentBookedEvent = (
    consignment: BookingConsignment,
    searchResultId: string,
    selectedTransportAlternative: SearchResult,
    bookingRequest: BookingRequest,
    revenue: number,
    totalShipments: number,
    addonCosts?: CarrierAddonCosts
) => {
    const heaviestPackage = getMaximumPackageWeight(consignment).toString();
    const longestPackage = getMaximumPackageLength(consignment).toString();
    const widestPackage = getMaximumPackageWidth(consignment).toString();
    const highestPackage = getMaximumPackageHeight(consignment).toString();
    const totalWeight = getTotalWeight(consignment);
    const toIsPrivate = getPrivate(consignment.recipient.isPrivate);
    const totalVolume = getVolume(consignment).toString();
    const needsCustomsInvoice = requiresEuCustomsInvoice(
        {
            country: consignment.sender.address.countryCode,
            postalCode: consignment.sender.address.postalCode,
        },
        {
            country: consignment.recipient.address.countryCode,
            postalCode: consignment.recipient.address.postalCode,
        },
        selectedTransportAlternative.product.courier.code
    );
    return {
        consignment_id: isRequest(consignment) ? undefined : consignment.id,
        search_result_id: searchResultId,
        tracking_id: isRequest(consignment)
            ? undefined
            : consignment?.order?.trackingId,
        booked_product: selectedTransportAlternative.product.code,
        booked_with_courier:
            selectedTransportAlternative.product.courier.shortTitle,
        insurance_currency: bookingRequest.carrierAddons?.insurance?.currency,
        insurance_amount: bookingRequest.carrierAddons?.insurance?.amount,
        insurance_cost: addonCosts?.insurance || 0,
        shipping_cost: bookingRequest.expectedCost,
        from_country_code: consignment.sender.address.countryCode,
        from_post_code: consignment.sender.address.postalCode,
        to_country_code: consignment.recipient.address.countryCode,
        to_post_code: consignment.recipient.address.postalCode,
        number_of_consignments: totalShipments,
        revenue,
        to_private: toIsPrivate,
        total_packages: consignment.packages.length,
        created_at: isRequest(consignment)
            ? undefined
            : consignment.createdAt?.toISO(),
        total_weight: totalWeight,
        stackable: consignment.packages[0].stackable,
        heaviest_package_kg: heaviestPackage,
        longest_package_cm: longestPackage,
        widest_package_cm: widestPackage,
        highest_package_cm: highestPackage,
        total_volume_m3: totalVolume,
        source: getConsignmentSource(consignment),
        needs_customs_invoice: needsCustomsInvoice,
        system: 'React',
    };
};
export const getQualificationEvent = (
    qualification: Qualification
): QualificationEvent => {
    // note that these are em dashes and not regular hyphens
    const turnover = qualification?.company?.turnover;
    const size = qualification?.company?.size;
    const parcel = qualification?.shipping?.volume?.parcel;
    const pallet = qualification?.shipping?.volume.pallet;
    const multipallet = qualification?.shipping?.volume?.multipallet;

    const { min: turnover_min, max: turnover_max } =
        getQualificationMinMax(turnover);
    const { min: size_min, max: size_max } = getQualificationMinMax(size);
    const { min: parcel_min, max: parcel_max } = getQualificationMinMax(parcel);
    const { min: pallet_min, max: pallet_max } = getQualificationMinMax(pallet);
    const { min: multipallet_min, max: multipallet_max } =
        getQualificationMinMax(multipallet);

    return {
        ...qualification,
        company: {
            industry: qualification?.company?.industry,
            size_min,
            size_max,
            turnover_min,
            turnover_max,
        },
        shipping: {
            carriers: qualification?.shipping?.carriers,
            volume: {
                parcel_min,
                pallet_min,
                multipallet_min,
                parcel_max,
                pallet_max,
                multipallet_max,
            },
        },
    };
};
