import useSubscriptionTrialInfo from 'hooks/usePriceTierTrial';

import PlusTrialBanner from './PlusTrialBanner';
import ProTrialBanner from './ProTrialBanner';

const InfoBannerTrial = () => {
    const trialInfo = useSubscriptionTrialInfo();

    if (!trialInfo) {
        return null;
    }

    const allowedStatus = ['started', 'ending', 'ended'];
    if (!allowedStatus.includes(trialInfo.status)) {
        return null;
    }

    switch (trialInfo.productCode) {
        case 'pro_14_trial_global':
            return <ProTrialBanner trialInfo={trialInfo} />;
        case 'plus_14_trial_sweden':
        case 'plus_14_trial_germany':
            return <PlusTrialBanner trialInfo={trialInfo} />;
        default:
            return null;
    }
};

export default InfoBannerTrial;
