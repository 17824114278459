import PageHeader from 'components/Header';
import InfoBannerTrial from 'components/InfoBanner/InfoBannerTrial';
import DesktopMenu from 'components/Navigation/DesktopMenu';
import MobileMenu from 'components/Navigation/MobileMenu';
import Navigation from 'components/Navigation/Navigation';
import { useOnboarding } from 'hooks/Queries';
import { useWindowWidthContext } from 'hooks/WindowWidth';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import breakpoints from '../theme/breakpoints';

const NavBarContainer = styled.div`
    width: 100%;
`;

const NavBar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const mobileMathcer = window.matchMedia(`(max-width: ${breakpoints.md})`);
    const width = useWindowWidthContext();
    const { onboardingCompleted } = useOnboarding();
    useEffect(() => {
        setIsMobile(mobileMathcer.matches);
    }, [mobileMathcer.matches, width]);
    return (
        <NavBarContainer>
            <InfoBannerTrial />
            <Navigation>
                <Link to="/dashboard">
                    <PageHeader logo />
                </Link>
                {isMobile ? (
                    <MobileMenu
                        enableOnboarding={onboardingCompleted === false}
                    />
                ) : (
                    <DesktopMenu
                        enableOnboarding={onboardingCompleted === false}
                    />
                )}
            </Navigation>
        </NavBarContainer>
    );
};

export default NavBar;
