import {
    APISubscriptionTier,
    CustomerInformation,
    PriceSubscriptionTier,
    Subscription,
    Tier,
} from 'api/subscriptions/types';
import { Currency } from 'types/UserData';

import { Team } from '../api/teams/types';
import DateHelper from '../helpers/DateHelper';

export type Price = { [key in Currency]: number };

export const yearlyCosts: { [key in APISubscriptionTier]: Price } = {
    legacy: { ['SEK']: 0, ['EUR']: -1 },
    api_tier_1: { ['SEK']: 2988, ['EUR']: -1 },
    api_tier_2: { ['SEK']: 5148, ['EUR']: -1 },
    integration_starter: { ['SEK']: 199 * 12, ['EUR']: 8 * 12 },
    integration_small: { ['SEK']: 249 * 12, ['EUR']: 16 * 12 },
    integration_medium: { ['SEK']: 429 * 12, ['EUR']: 36 * 12 },
    integration_enterprise: { ['SEK']: Infinity, ['EUR']: Infinity },
};
export const monthlyCosts: { [key in APISubscriptionTier]: Price } = {
    legacy: { SEK: -1, EUR: -1 },
    api_tier_1: { SEK: -1, EUR: -1 },
    api_tier_2: { SEK: -1, EUR: -1 },
    integration_starter: { SEK: 249, EUR: 10 },
    integration_small: { SEK: 311, EUR: 20 },
    integration_medium: { SEK: 536, EUR: 45 },
    integration_enterprise: { SEK: Infinity, EUR: Infinity },
};
export const tierOrderByTier: { [key in APISubscriptionTier]: number } = {
    legacy: -1,
    api_tier_1: 1,
    api_tier_2: 2,
    integration_starter: 0,
    integration_small: 1,
    integration_medium: 2,
    integration_enterprise: 3,
};

/**
 * Returns the next upgrade for a given tier, or undefined if the tier has no upgrade.
 * @param tier
 */
export const getNextTierUpgrade = (
    tier: APISubscriptionTier
): APISubscriptionTier | undefined => {
    switch (tier) {
        case 'integration_starter':
            return 'integration_small';
        case 'integration_small':
            return 'integration_medium';
    }
    return undefined;
};

export const isApiTier = (tier: Tier): tier is APISubscriptionTier => {
    return [
        'legacy',
        'api_tier_1',
        'api_tier_2',
        'integration_starter',
        'integration_small',
        'integration_medium',
        'integration_enterprise',
    ].includes(tier);
};

export const getActiveSubscription = (
    subscriptions: Subscription[],
    code?: string
): Subscription | undefined => {
    if (!code) {
        return undefined;
    }
    return subscriptions.find(
        (sub) =>
            !sub.expiredAt &&
            sub.items.find((item) => item.product.code === code)
    );
};

export const getCurrentPriceTier = (
    subscriptions?: CustomerInformation,
    team?: Team
): PriceSubscriptionTier | undefined => {
    let currentTier: PriceSubscriptionTier | undefined = 'se_tier_1';
    if (
        team?.legacySendifyPlusExpiryDate &&
        DateHelper.isInPast(team.legacySendifyPlusExpiryDate)
    ) {
        // set current tier to undefined since they need choose a new tier, they are currently without tier
        currentTier = undefined;
    } else if (subscriptions?.priceTierSubscription.current.tier) {
        currentTier = subscriptions?.priceTierSubscription.current.tier;
    }

    // if the team has gotten their team set to a paid tier directly in the db without a subscription
    // we need to show what tier they are on we also hide the downgrade button since they can't cancel manually
    const isLegacyTeam =
        team && team?.priceTier !== 'se_tier_1' && currentTier === 'se_tier_1';
    if (isLegacyTeam) {
        switch (team?.priceTier) {
            case 'se_tier_2':
                currentTier = 'se_tier_2';
                break;
        }
    }

    const hasSendifyPlusSub = getActiveSubscription(
        subscriptions?.subscriptions || [],
        'se_tier_2'
    );
    if (hasSendifyPlusSub) {
        currentTier = 'se_tier_2';
    }
    const hasSendifyPlusSubDE = getActiveSubscription(
        subscriptions?.subscriptions || [],
        'de_tier_2'
    );
    if (hasSendifyPlusSubDE) {
        currentTier = 'de_tier_2';
    }
    return currentTier;
};

export const isPriceTier = (tier: Tier): tier is PriceSubscriptionTier => {
    return [
        'se_tier_1',
        'se_tier_2',
        'se_tier_3',
        'de_tier_1',
        'de_tier_2',
    ].includes(tier);
};

export const includedMonthlyShipments: {
    [key in APISubscriptionTier]: number;
} = {
    legacy: 0,
    api_tier_1: 100,
    api_tier_2: 200,
    integration_starter: 25,
    integration_small: 100,
    integration_medium: 200,
    integration_enterprise: 500,
};

export const overageFees: { [key in APISubscriptionTier]: Price } = {
    legacy: { ['SEK']: 0, ['EUR']: -1 },
    api_tier_1: { ['SEK']: 3.5, ['EUR']: -1 },
    api_tier_2: { ['SEK']: 3, ['EUR']: -1 },
    integration_starter: { ['SEK']: Infinity, ['EUR']: Infinity },
    integration_small: { ['SEK']: Infinity, ['EUR']: Infinity },
    integration_medium: { ['SEK']: 3.5, ['EUR']: 0.35 },
    integration_enterprise: { ['SEK']: Infinity, ['EUR']: Infinity },
};
