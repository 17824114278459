import {
    UseMutationOptions,
    UseQueryOptions,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import {
    completeOnboardingStep,
    dismissOnboardingStep,
    getOnboarding,
    getUser,
    markOnboardingStepAsComplete,
    updateUser,
} from 'api/users';
import { OnboardingStatus, OnboardingStep, User } from 'api/users/types';
import { useFlags } from 'external/launchDarkly';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

export const useUser = (options?: UseQueryOptions<User>) => {
    const { data, ...rest } = useQuery<User>(['user'], getUser, {
        ...options,
    });
    return {
        user: data,
        ...rest,
    };
};

export const useUpdateUser = (
    options?: UseMutationOptions<User, unknown, User>
) => {
    const queryClient = useQueryClient();
    const { data: updatedUser, ...rest } = useMutation(updateUser, {
        ...options,
        onSuccess: () => {
            queryClient.invalidateQueries(['user']);
        },
    });
    return { updatedUser, ...rest };
};

export const useOnboarding = () => {
    const { data, ...rest } = useQuery<OnboardingStatus>(
        ['userOnboarding'],
        getOnboarding
    );
    const { userOnboarding } = useFlags();

    // Old users should not see the onboarding.
    const { user } = useUser();
    const onboardingCutoffDate = DateTime.fromISO('2024-05-24T00:00:00.000Z');

    const queryClient = useQueryClient();

    const dismiss = useMutation(dismissOnboardingStep, {
        onMutate: async (step: OnboardingStep) => {
            await queryClient.cancelQueries(['userOnboarding']);
            const previousData = queryClient.getQueryData<OnboardingStatus>([
                'userOnboarding',
            ]);
            queryClient.setQueryData<OnboardingStatus>(
                ['userOnboarding'],
                (old) => {
                    if (!old) {
                        return undefined;
                    }

                    const newStep = { ...old[step], dismissed: true };
                    return {
                        ...old,
                        [step]: newStep,
                    };
                }
            );
            return { previousData };
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(['userOnboarding'], context?.previousData);
        },
        onSettled: () => {
            queryClient.invalidateQueries(['userOnboarding']);
        },
    });

    const dismissOnboardingSteps = async (steps: OnboardingStep[]) => {
        await Promise.all(steps.map((step) => dismissOnboardingStep(step)));
    };
    const dismissMultiple = useMutation(dismissOnboardingSteps, {
        onMutate: async (steps: OnboardingStep[]) => {
            await queryClient.cancelQueries(['userOnboarding']);
            const previousData = queryClient.getQueryData<OnboardingStatus>([
                'userOnboarding',
            ]);
            queryClient.setQueryData<OnboardingStatus>(
                ['userOnboarding'],
                (old) => {
                    if (!old) {
                        return undefined;
                    }

                    const newSteps = { ...old };
                    steps.forEach((step) => {
                        newSteps[step] = {
                            ...old[step],
                            dismissed: true,
                        };
                    });
                    return newSteps;
                }
            );
            return { previousData };
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(['userOnboarding'], context?.previousData);
        },
        onSettled: () => {
            queryClient.invalidateQueries(['userOnboarding']);
        },
    });

    const markAsComplete = useMutation(markOnboardingStepAsComplete, {
        onMutate: async (step: OnboardingStep) => {
            await queryClient.cancelQueries(['userOnboarding']);
            const previousData = queryClient.getQueryData<OnboardingStatus>([
                'userOnboarding',
            ]);
            queryClient.setQueryData<OnboardingStatus>(
                ['userOnboarding'],
                (old) => {
                    if (!old) {
                        return undefined;
                    }

                    const newStep = { ...old[step], completed: true };
                    return {
                        ...old,
                        [step]: newStep,
                    };
                }
            );
            return { previousData };
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(['userOnboarding'], context?.previousData);
        },
        onSettled: () => {
            queryClient.invalidateQueries(['userOnboarding']);
        },
    });

    const complete = useMutation(completeOnboardingStep, {
        onMutate: async (step: OnboardingStep) => {
            await queryClient.cancelQueries(['userOnboarding']);
            const previousData = queryClient.getQueryData<OnboardingStatus>([
                'userOnboarding',
            ]);
            queryClient.setQueryData<OnboardingStatus>(
                ['userOnboarding'],
                (old) => {
                    if (!old) {
                        return undefined;
                    }

                    const newStep = { ...old[step], completed: true };
                    return {
                        ...old,
                        [step]: newStep,
                    };
                }
            );
            return { previousData };
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(['userOnboarding'], context?.previousData);
        },
        onSettled: () => {
            queryClient.invalidateQueries(['userOnboarding']);
        },
    });
    const onboardingCompleted = useMemo(() => {
        // If the LD flag is not on, the user onboarding is not enabled.);
        if (!userOnboarding) {
            return true;
        }
        // If the user is not loaded yet, we can't determine if the onboarding is completed.
        if (!user) {
            return undefined;
        }
        // If the user onboarding is not loaded yet, we can't determine if the onboarding is completed.
        if (!data) {
            return undefined;
        }
        if (user.createdAt < onboardingCutoffDate) {
            return true;
        }
        return Object.values(data).every(
            (step) => step.dismissed || step.completed
        );
    }, [data, user]);
    return {
        onboarding: data,
        dismiss,
        dismissMultiple,
        markAsComplete,
        complete,
        onboardingCompleted,
        enabled: userOnboarding,
        ...rest,
    };
};
