import { Hide, InfoCircle, Show } from 'assets/icons';
import Tooltip from 'components/Tooltip';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text, { TextSize } from 'components/Typography/Text';
import {
    InputHTMLAttributes,
    ReactNode,
    forwardRef,
    useEffect,
    useId,
    useRef,
    useState,
} from 'react';

import {
    DecoratorText,
    ErrorMessage,
    InputContainer,
    InputWrapper,
    LeftTextDecorator,
    MaxLengthText,
    PasswordButton,
    RightTextDecorator,
    SearchIcon,
    StyledInput,
    TopText,
} from './StyledInput';

interface Props {
    /** Toggles a red validation style. */
    error?: boolean;
    /** Shown below the input. Useful for validation messages. */
    errorMessage?: string | ReactNode;
    label?: string;
    /**
     * Displays a counter and allows the user to input a maximum number of
     * characters.
     */
    maxLength?: number;
    /** Updates with the new value when entered by the user. */
    onValueChange?: (value: string) => any;
    /** Shown before the text. It's possible to send a Element for icons etc. */
    prefix?: string | ReactNode;
    /** Shown after the text. Useful for currencies and units. */
    suffix?: string | ReactNode;
    /** Displays an information icon with this as the content of its tooltip. */
    tooltip?: string | ReactNode;

    'data-cy'?: string;
}

type InputProps = Props & Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'>;

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            'data-cy': dataCy,
            'aria-label': ariaLabel,
            error,
            errorMessage,
            label,
            tooltip,
            suffix,
            prefix,
            value,
            onChange,
            onValueChange,
            type,
            id,
            className,
            maxLength,
            readOnly,
            ...rest
        }: InputProps,
        forwardedRef
    ) => {
        const inputRef = useRef<HTMLInputElement | null>(null);

        /*
        We always need an ID if we have a label, for label click etc to work.
        If the developer doesn't supply an ID, generate a unique one.
        */
        const generatedId = useId();
        const inputId = id ?? generatedId;

        const [showPassword, setShowPassword] = useState(false);
        /*
        Use useState for keeping track of the input length rather than accessing
        the ref. Even if the underlying value of the ref changes, this won't trigger
        a re-render.
        */
        const [inputLength, setInputLength] = useState(
            value?.toString().length || 0
        );

        useEffect(() => {
            setInputLength(value?.toString().length || 0);
        }, [value]);

        const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
            setInputLength(event.target.value.length);
            if (onValueChange) {
                onValueChange(event.target.value);
            }
            if (onChange) {
                onChange(event);
            }
        };

        /*
         Merge the forwarded and the internal ref, so we can use the internal ref without
         removing the forwarded one.
         */
        useEffect(() => {
            if (!forwardedRef) return;
            if (typeof forwardedRef === 'function') {
                forwardedRef(inputRef.current);
            } else {
                // eslint-disable-next-line no-param-reassign
                forwardedRef.current = inputRef.current;
            }
        }, [forwardedRef]);

        return (
            <InputContainer className={className}>
                {(label || tooltip || maxLength !== undefined) && (
                    <TopText>
                        {label && (
                            <Heading size={HeadingSize.XSMALL}>
                                <label htmlFor={inputId}>{label}</label>
                            </Heading>
                        )}
                        {tooltip && (
                            <>
                                <InfoCircle
                                    height="20"
                                    data-tooltip-id={`tooltip-${inputId}`}
                                />
                                <Tooltip id={`tooltip-${inputId}`}>
                                    {tooltip}
                                </Tooltip>
                            </>
                        )}
                        {maxLength && (
                            <MaxLengthText
                                error={inputLength > maxLength}
                                data-testid="max-length"
                            >
                                {`${inputLength}/${maxLength}`}
                            </MaxLengthText>
                        )}
                    </TopText>
                )}
                <InputWrapper
                    readOnly={!!readOnly}
                    hasError={!!error}
                    onClick={() => {
                        inputRef.current?.focus();
                    }}
                >
                    {type === 'search' && (
                        <LeftTextDecorator>
                            <SearchIcon width="20" />
                        </LeftTextDecorator>
                    )}
                    {prefix && (
                        <LeftTextDecorator>
                            {typeof prefix === 'string' ? (
                                <DecoratorText
                                    size={TextSize.MEDIUM}
                                    readOnly={!!readOnly}
                                >
                                    {prefix}
                                </DecoratorText>
                            ) : (
                                prefix
                            )}
                        </LeftTextDecorator>
                    )}
                    <StyledInput
                        aria-invalid={!!error}
                        data-testid="input"
                        type={showPassword ? 'text' : type}
                        value={value}
                        onChange={changeValue}
                        data-cy={dataCy}
                        data-hj-whitelist
                        aria-label={ariaLabel}
                        maxLength={maxLength}
                        id={inputId}
                        ref={inputRef}
                        hasError={!!error}
                        readOnly={!!readOnly}
                        hasRightDecorator={!!suffix}
                        hasLeftDecorator={!!prefix || type === 'search'}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...rest}
                    />
                    {suffix && (
                        <RightTextDecorator>
                            <DecoratorText
                                size={TextSize.MEDIUM}
                                readOnly={!!readOnly}
                            >
                                {suffix}
                            </DecoratorText>
                        </RightTextDecorator>
                    )}
                    {type === 'password' && (
                        <PasswordButton
                            data-testid="show-password-button"
                            onClick={() => setShowPassword(!showPassword)}
                            icon={showPassword ? <Show /> : <Hide />}
                        />
                    )}
                </InputWrapper>
                {error && errorMessage && (
                    <ErrorMessage>
                        <Text>{errorMessage}</Text>
                    </ErrorMessage>
                )}
            </InputContainer>
        );
    }
);

export default Input;
