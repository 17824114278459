import { ChevronUpOutline, InfoCircle } from 'assets/icons';
import Text from 'components/Typography/Text';
import styled from 'styled-components/macro';

export const ProductListHeader = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledProductList = styled.div`
    min-height: ${({ theme }) => theme.space.s16};
    /*  For the spinner */
    position: relative;
`;

export const ProductListActions = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /*
    The negative margin offsets the button padding. It is to make the button
    content aligned to the rest of the card.
    */
    margin: ${({ theme }) =>
        `0 -${theme.space.s2} ${theme.space.s3} -${theme.space.s2}`};
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding-top: ${({ theme }) => theme.space.s3};
        border-top: ${({ theme }) => `1px solid ${theme.colors.grey300}`};
    }
`;

export const ProductListHeadings = styled.div`
    align-items: center;
    color: ${({ theme }) => theme.colors.grey100};
    display: grid;
    grid-column-gap: ${({ theme }) => theme.space.s3};
    grid-template-columns: 40px 160px 1fr auto;
    margin-bottom: ${({ theme }) => theme.space.s1};

    span:first-child {
        /* To offset the carrier logo. */
        grid-column: 1/3;
    }

    span:last-child {
        text-align: right;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        display: none;
    }
`;

export const CarrierHeading = styled(Text)`
    grid-column: 1/3;
`;

export const SortButtonGroup = styled.div`
    align-items: center;
    display: flex;
    gap: ${({ theme }) => theme.space.s1};
    justify-content: space-between;
    margin-left: auto;
`;

export const ArrowIcon = styled(ChevronUpOutline)<{ open: boolean }>`
    height: 20px;
    transition: transform 0.2s ease-in-out;
    ${({ open }) => (open ? 'transform: rotate(-180deg)' : '')};
    width: 20px;
`;

export const LoadingWrapper = styled.div`
    background: rgba(255, 255, 255, 0.8);
    display: grid;
    height: 100%;
    left: 0;
    padding: ${({ theme }) => theme.space.s4};
    place-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
`;

export const List = styled.ol`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const InfoTooltipIcon = styled(InfoCircle)`
    height: 16px;
    margin-left: ${({ theme }) => theme.space.s1};
    vertical-align: text-bottom;
`;
