import { Button } from 'components/Buttons';
import styled, { css } from 'styled-components/macro';

export const Label = styled.label`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
`;
export const ButtonWrapper = styled.div<{ fullWidth: boolean }>`
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'max-content')};
`;

export const TopText = styled.span`
    align-items: center;
    display: flex;
    grid-gap: ${({ theme }) => theme.space.s1};
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space.s1};
    width: fit-content;
`;

export const StyledButton = styled(Button)<{
    active: boolean;
    error: boolean;
    disabled: boolean;
}>`
    border: 1px solid
        ${({ theme, error }) =>
            error ? theme.colors.red100 : theme.colors.grey300};

    &:not(:first-of-type) {
        /* Offsets the border. */
        margin-left: -1px;
    }

    :focus-visible {
        // The focused element should always be on top to prevent its outline from being cut off.
        z-index: 2;
    }

    ${({ active, disabled }) => {
        if (active) {
            return css`
                border-color: ${({ theme }) => {
                    if (disabled) {
                        return theme.colors.grey200;
                    }
                    return theme.colors.black;
                }};
                // Make the black border always on top.
                z-index: 1;
            `;
        }
    }};
`;
