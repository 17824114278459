import { Button } from 'components/Buttons';
import Text from 'components/Typography/Text';
import { useTrialContext } from 'hooks/TrialProvider/TrialProvider';
import { TrialInfo } from 'hooks/usePriceTierTrial';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import InfoBanner from './InfoBanner';
import { InfoText } from './StyledInfoBanner';

interface Props {
    trialInfo: TrialInfo;
}

export default function ProTrialBanner(props: Props) {
    const { trialInfo } = props;
    const { t } = useTranslation('dashboard');
    const {
        showTrialBanner,
        closeTrialBanner,
        setModalTrigger,
        hasSeenModal,
        setHasSeenModal,
    } = useTrialContext();

    useEffect(() => {
        if (trialInfo?.status === 'ended' && !hasSeenModal) {
            setModalTrigger('auto');
            setHasSeenModal(true);
        }
    }, [trialInfo]);

    return (
        <InfoBanner show={showTrialBanner} onClose={closeTrialBanner}>
            <InfoText>
                <Trans
                    t={t}
                    i18nKey={`dashboard.trialProBanner.${trialInfo.status}.subtitle`}
                >
                    <SendifyProText />
                    <Button
                        variant="secondary"
                        onClick={() => setModalTrigger('click')}
                    />
                </Trans>
            </InfoText>
        </InfoBanner>
    );
}

const SendifyProText = styled(Text)`
    background-color: ${({ theme }) => theme.colors.cardboardBrown300};
    color: black;
    padding: ${({ theme }) => theme.space.s1};
`;
