import {
    GroupBase,
    OptionProps,
    components,
} from 'components/FormElements/Select';
import Text, { TextSize } from 'components/Typography/Text';
import styled from 'styled-components/macro';

import { Option } from './AddressBookSearch';

const { Option: SelectOption } = components;

const OptionContent = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

interface Props {
    data: Option;
}

/**
 * A single option in the AddressBook dropdown.
 */
const AddressBookOption = <
    OptionType,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(
    props: OptionProps<OptionType, false, GroupType> & Props
) => {
    const { data } = props as Props;
    const { value } = data;
    const { contact, person } = value;

    return (
        <SelectOption {...props}>
            <OptionContent>
                <Text size={TextSize.MEDIUM}>{contact.name}</Text>
                {person && (
                    <Text size={TextSize.MEDIUM}>
                        {person.firstName} {person.lastName}
                    </Text>
                )}
                <Text>
                    {contact.address.addressLine1}, {contact.address.city},{' '}
                    {contact.address.countryCode}
                    {contact.address.postalCode && (
                        <span>-{contact.address.postalCode}</span>
                    )}
                </Text>
                <Text>{person?.email}</Text>
            </OptionContent>
        </SelectOption>
    );
};

export default AddressBookOption;
