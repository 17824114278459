import { setLanguage } from 'external/beamer/beamer';
import i18n from 'i18next';
import { Settings } from 'luxon';
import { LANGUAGE } from 'types/LocalStorage';

// Used by the language switchers
export const languages = ['sv', 'de', 'en'] as const;
export type Language = typeof languages[number];

// The default version or dialect we use for each language. Note that this can not be stored in the backend.
const defaultVariant = (language: Language) => {
    switch (language) {
        case 'sv':
            return 'sv-SE';
        case 'de':
            return 'de-DE';
        case 'en':
            return 'en-GB';
    }
};

/**
 * Sets the language in i18n, and Luxon, and stores it in
 * localstorage.
 *
 * Where possible, a default language variant is used to clarify which dialect
 * should be used.
 */
export const setFrontendLanguage = (language?: Language) => {
    if (!language) {
        return;
    }
    const currentLanguage = i18n.language;
    const languageVariant = defaultVariant(language);
    if (languageVariant === currentLanguage) {
        return;
    }

    document.querySelector('html')?.setAttribute('lang', languageVariant);
    i18n.changeLanguage(languageVariant);
    Settings.defaultLocale = languageVariant; // Luxon locale
    setLanguage(language); // Beamer

    try {
        localStorage.setItem(LANGUAGE, language);
    } catch (exception) {
        console.debug('Local storage disabled');
    }
};

/**
 * Returns the language stored in local storage
 */
export const getFrontendLanguage = (): Language | undefined => {
    try {
        return (localStorage.getItem(LANGUAGE) as Language) || undefined;
    } catch (exception) {
        console.debug('Local storage disabled');
    }
    return undefined;
};
