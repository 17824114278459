import {
    UseQueryResult,
    useQueries,
    useQueryClient,
} from '@tanstack/react-query';
import { setConsignmentStatusDraft } from 'api/consignments';
import {
    Consignment,
    ReceiverPaysOptions,
    isRequest,
} from 'api/consignments/types';
import { newFreightForwarding } from 'api/freight-forwarding';
import { FreightForwarding } from 'api/freight-forwarding/types';
import { getSendifyInsurancePrice } from 'api/insurance';
import { InsurancePrice } from 'api/insurance/types';
import { CarrierAddons } from 'api/products/types';
import { QuickQuotationConsignment } from 'api/qq/types';
import { Addon, AddonInput, setAddons } from 'api/search';
import { hasExceededImportLimit } from 'api/subscriptions/helper';
import { Team } from 'api/teams/types';
import { trackStartBookingFlow } from 'external/analytics';
import { useFlags } from 'external/launchDarkly';
import { getAddonName } from 'helpers/AddonHelper';
import {
    BookingConsignment,
    BookingOptions,
    ProductAddonCosts,
    SendifyInsurance,
    TransportAlternative,
} from 'hooks/Booking/types';
import { useSubscriptions } from 'hooks/Queries/subscriptions';
import { useSendifySecure } from 'hooks/useSendifySecure';
import produce from 'immer';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Currency } from 'types/UserData';

import { parseDates } from '../../helpers/FormatHelper';
import { useTeam, useTeamSettings } from '../Queries';
import { usePersistentState } from '../usePersistentState';
import {
    getProductCost as getTransportAlternativeCost,
    showADBOptions,
    showCustomsInvoiceOptions,
} from './helper';
import { Flow, QQ_BOOKING_ID, UseBookingFlowOptions } from './types';

// These are exported since they are currently used when clearing the provider in the booking overview.

// These are exported since they are currently used when clearing the provider in the booking overview.

// These are exported since they are currently used when clearing the provider in the booking overview.
export const persistedKeys = [
    'consignment_map',
    'transport_alternatives_map',
    'selected_transport_alternatives_map',
    'booking_info_map',
    'flow',
    'freight_forwarding_map',
    'addon_input_map',
    'insurance_form_map',
    'insurance_addon_map',
    'receiver_pays_account_number_map',
] as const;
export type PersistedKey = typeof persistedKeys[number];

export const useBookingFlowMethods = (): UseBookingFlowOptions => {
    const { subscriptions } = useSubscriptions();
    const { teamSettings } = useTeamSettings();
    const { pathname, state } = useLocation<{ consignment?: string }>();
    const [isLoadingAlternatives, setIsLoadingAlternatives] = useState<{
        [key: string]: boolean;
    }>({});
    const { team } = useTeam();
    const { t } = useTranslation('compare');

    const [bookings, setBookings] = usePersistentState<string[] | undefined>(
        'bookings',
        undefined,
        sessionStorage
    );
    const receiverPaysKey: PersistedKey = 'receiver_pays_account_number_map';
    const [receiverPaysOptionsMap, setReceiverPaysMap] = usePersistentState<{
        [key: string]: ReceiverPaysOptions | undefined;
    }>(receiverPaysKey, {}, sessionStorage);

    const consignmentKey: PersistedKey = 'consignment_map';
    const [consignmentMap, setConsignmentMap] = usePersistentState<{
        [key: string]: BookingConsignment;
    }>(consignmentKey, {}, sessionStorage);

    // The insurance price is a special case - for Swedish customers, we need to show
    // what it costs even when the user has deselected the insurance, for legal reasons.
    // We therefore need to keep track of the insurance price.
    const insuranceAddonKey: PersistedKey = 'insurance_addon_map';
    const [insuranceAddonMap, setInsuranceAddonMap] = usePersistentState<{
        [key: string]: Addon | undefined;
    }>(insuranceAddonKey, {}, sessionStorage);

    const insuranceFormKey: PersistedKey = 'insurance_form_map';
    const [insuranceFormMap, setInsuranceFormMap] = usePersistentState<{
        [key: string]: SendifyInsurance | undefined;
    }>(insuranceFormKey, {}, sessionStorage);

    const addonInputKey: PersistedKey = 'addon_input_map';
    const [addonInputMap, setAddonInputMap] = usePersistentState<{
        [key: string]: AddonInput[];
    }>(addonInputKey, {}, sessionStorage);

    const transportAlternativesKey: PersistedKey = 'transport_alternatives_map';
    const [transportAlternativeMap, setTransportAlternativeMap] =
        usePersistentState<{
            [key: string]: {
                requestId: string | null;
                searchResultId: string;
                addonInput: AddonInput[];
                alternatives: TransportAlternative[];
            };
        }>(transportAlternativesKey, {}, sessionStorage);

    const queryClient = useQueryClient();
    const selectedKey: PersistedKey = 'selected_transport_alternatives_map';
    const [
        selectedTransportAlternativeCodeMap,
        setSelectedTransportAlternativeCodeMap,
    ] = usePersistentState<{
        [key: string]: string | undefined;
    }>(selectedKey, {}, sessionStorage);

    const bookingInfoKey: PersistedKey = 'booking_info_map';
    const [rawBookingOptionsMap, setBookingOptionsMap] = usePersistentState<{
        /**
         * sendifyInsurance may be undefined in local storage
         * during the experiment period, as we toggle it on and off.
         */
        [key: string]: Omit<BookingOptions, 'sendifyInsurance'> & {
            sendifyInsurance: SendifyInsurance;
        };
    }>(bookingInfoKey, {}, sessionStorage);

    /**
     * We need to reset the flow if the user changes team. This is because
     * the user might otherwise see prices and addons that are not available
     * for the new team. This is most notable when an admin user switches
     * from between teams in different markets.
     */
    const [bookingTeam, setBookingTeam] = usePersistentState<Team | undefined>(
        'usedTeam',
        team
    );
    useEffect(() => {
        if (!bookingTeam && team) {
            setBookingTeam(team);
        }
    }, [team]);
    useEffect(() => {
        if (bookingTeam && team && bookingTeam.id !== team.id) {
            clear();
        }
    }, [bookingTeam, team]);

    const { enabled, enabledByDefault } = useSendifySecure();
    const hasSendifyInsurance = enabled;
    const sendifyInsuranceEnabledByDefault = enabled && enabledByDefault;

    /** Base value for Sendify's insurance. */
    const initialSendifyInsurance: SendifyInsurance = {
        amount: teamSettings?.teamSendifySecureDefaultGoodsValue ?? undefined,
        currency: team?.currency,
        enabled: hasSendifyInsurance,
    };
    /**
     * Since users will go from having sendifyInsurance set to undefined to it being
     * required in the types, as we toggle the experiment on and off, we parse the
     * booking option data stored in local storage and add the insurance data if missing.
     */
    const bookingOptionsMap = (bookings || []).reduce((acc, b) => {
        const bookingOptions = rawBookingOptionsMap[b];
        const parsedBookingOptions: BookingOptions = {
            ...bookingOptions,
            sendifyInsurance:
                bookingOptions.sendifyInsurance || initialSendifyInsurance,
            // Remove carrier insurance, if Sendify insurance is turned on. We use
            // our own insurance instead.
            carrierAddons: {
                ...bookingOptions.carrierAddons,
                insurance: hasSendifyInsurance
                    ? undefined
                    : bookingOptions.carrierAddons?.insurance,
            },
        };

        return { ...acc, [b]: parsedBookingOptions };
    }, {} as { [key: string]: BookingOptions });

    useEffect(() => {
        const amount = teamSettings?.teamSendifySecureDefaultGoodsValue;
        if (amount && team) {
            (bookings || []).forEach((b) => {
                if (
                    bookingOptionsMap[b].sendifyInsurance.amount ===
                        undefined ||
                    !bookingOptionsMap[b].sendifyInsurance.currency
                ) {
                    setGoodsValue(b, {
                        amount,
                        currency: team.currency,
                    });
                }
            });
        }
    }, [teamSettings, team]);
    const flowKey: PersistedKey = 'flow';
    const [flow, setFlow] = usePersistentState<Flow | undefined>(
        flowKey,
        undefined,
        sessionStorage
    );

    const freightForwardingKey: PersistedKey = 'freight_forwarding_map';
    const [freightForwardingMap, setFreightForwardingMap] = usePersistentState<{
        [key: string]: boolean;
    }>(freightForwardingKey, {}, sessionStorage);

    /**
     * Resets the booking flow.
     */
    const clear = () => {
        setFlow(undefined);
        setBookingOptionsMap({});
        setConsignmentMap({});
        setTransportAlternativeMap({});
        setInsuranceFormMap({});
        setInsuranceAddonMap({});
        setAddonInputMap({});
        setSelectedTransportAlternativeCodeMap({});
        setFreightForwardingMap({});
        setBookings(undefined);
        setIsLoadingAlternatives({});
        setBookingTeam(undefined);
    };

    const defaultBookingOptions: BookingOptions = {
        carrierAddons: {},
        /** Once the A/B insurance test is concluded, this should have a proper default value. */
        sendifyInsurance: initialSendifyInsurance,
    };

    /**
     * Gets the price of Sendify's insurance. Will only
     * return a value if the goods value is correct, and
     * the insurance option is turned on in LaunchDarkly
     * and not opted out.
     */
    const calculateSendifyInsurancePrice = async (
        goodsValue?: number,
        currency?: Currency
    ): Promise<InsurancePrice | undefined> => {
        if (
            !hasSendifyInsurance ||
            goodsValue == undefined ||
            currency == undefined
        ) {
            return undefined;
        }
        return getSendifyInsurancePrice(goodsValue, currency);
    };

    /**
     * The booking flow has been properly initialized with consignments or a
     * QQ consignment.
     */
    const isInitialized =
        bookings !== undefined &&
        bookings.every((b) => !!bookingOptionsMap[b]) &&
        bookings.every((b) => !!consignmentMap[b]) &&
        !!flow;

    const setIsLoading = (bookingId: string, isLoading: boolean) => {
        setIsLoadingAlternatives(
            produce((draft) => {
                draft[bookingId] = isLoading;
            })
        );
    };

    const bookingOptionsList = Object.entries(bookingOptionsMap);
    /**
     * The Sendify insurance price for each shipment, based on the
     * inputted goods values. The prices are fetched and cached
     * using react-query.
     */

    const sendifyInsurancePrices = useQueries({
        queries: bookingOptionsList.map(([, options]) => {
            return {
                queryKey: [
                    'insurancePrice',
                    options.sendifyInsurance.amount,
                    options.sendifyInsurance.currency,
                ],
                queryFn: () =>
                    calculateSendifyInsurancePrice(
                        options.sendifyInsurance.amount || undefined,
                        options.sendifyInsurance.currency
                    ),
                enabled:
                    isInitialized &&
                    hasSendifyInsurance &&
                    options.sendifyInsurance.amount !== undefined &&
                    options.sendifyInsurance.currency !== undefined,
                keepPreviousData: true,
                retry: false,
            };
        }),
    }).reduce((acc, query, i) => {
        const [id] = bookingOptionsList[i];
        return {
            ...acc,
            [id]: query,
        };
    }, {} as { [bookingId: string]: UseQueryResult<InsurancePrice | undefined> });

    /**
     * Starts the booking flow with an array of consignments.
     * Each consignment gets assigned default booking info, and added
     * to the consignment structure for easier access.
     *
     * The initialize function, or its sibling initializeFromQQ, needs to be called
     * for any searches to be visible in the compare page.
     *
     * There may be a consignment in the route state. This is the case if and only if
     * the consignment has been rebooked from the admin panel.
     *
     * @param consignments These should be active consignments, i.e. in the cart.
     */
    const startBookingFlow = (consignments: Consignment[]) => {
        clear();

        setFlow('newshipment');
        trackStartBookingFlow(pathname, consignments);

        setBookings(consignments.map((c) => c.id));

        const newBookingInfoMap = consignments.reduce(
            (acc, consignment) => {
                const { id } = consignment;
                return {
                    ...acc,
                    [id]: defaultBookingOptions,
                };
            },
            {} as {
                [key: string]: BookingOptions;
            }
        );
        setBookingOptionsMap(newBookingInfoMap);

        const newConsignmentMap = consignments.reduce(
            (acc, consignment) => {
                const { id } = consignment;
                return {
                    ...acc,
                    [id]: consignment,
                };
            },
            {} as {
                [key: string]: Consignment;
            }
        );
        setConsignmentMap(newConsignmentMap);

        const freightForwardingMap = consignments.reduce(
            (acc, consignment) => {
                const { id } = consignment;
                return {
                    ...acc,
                    [id]: false,
                };
            },
            {} as {
                [key: string]: boolean;
            }
        );
        setFreightForwardingMap(freightForwardingMap);

        if (state?.consignment) {
            // Handle Sendify insurance from the rebooked consignment, if present.
            const rebookingConsignment: Consignment = JSON.parse(
                state.consignment
            );
            if (rebookingConsignment.insurance && bookings?.length === 1) {
                // We are unable to add the value of goods if there are more than one consignment (i.e.
                // the rebooked shipment and at least one more), since we can't determine which is which.
                const { goodsValue, currency } = rebookingConsignment.insurance;
                setGoodsValue(bookings[0], { amount: goodsValue, currency });
            }
        }

        const defaultInsuranceForm = {
            enabled: sendifyInsuranceEnabledByDefault,
            amount: null,
        };
        const newInsuranceFormMap = consignments.reduce(
            (acc, consignment) => {
                const { id } = consignment;
                return {
                    ...acc,
                    [id]: defaultInsuranceForm,
                };
            },
            {} as {
                [key: string]: SendifyInsurance;
            }
        );
        setInsuranceFormMap(newInsuranceFormMap);

        const newInsuranceAddonMap = consignments.reduce(
            (acc, consignment) => {
                const { id } = consignment;
                return {
                    ...acc,
                    [id]: undefined,
                };
            },
            {} as {
                [key: string]: Addon | undefined;
            }
        );
        setInsuranceAddonMap(newInsuranceAddonMap);

        const newAddonInputMap = consignments.reduce(
            (acc, consignment) => {
                const { id } = consignment;
                return {
                    ...acc,
                    [id]: [],
                };
            },
            {} as {
                [key: string]: AddonInput[];
            }
        );

        setAddonInputMap(newAddonInputMap);

        const newIsLoadingAlternatives = consignments.reduce(
            (acc, consignment) => {
                const { id } = consignment;
                return {
                    ...acc,
                    [id]: false,
                };
            },
            {} as {
                [key: string]: boolean;
            }
        );
        setIsLoadingAlternatives(newIsLoadingAlternatives);
        setReceiverPaysMap(
            parseDates(sessionStorage.getItem(receiverPaysKey))?.value || {}
        );
    };

    /**
     * Starts booking flow with a QQ consignment. The consignment gets
     * assigned default booking info, and added to the consignment structure
     * for easier access.
     *
     * The initializeFromQQ function, or its sibling initialize, needs to be called
     * for any searches to be visible in the compare page.
     *
     * @param consignments A validated QQ consignment, returned as a response from
     * the validation endpoint.
     */
    const startBookingFlowFromQQ = (consignment: QuickQuotationConsignment) => {
        clear();

        setFlow('qq');

        setBookings([QQ_BOOKING_ID]);

        setBookingOptionsMap({
            [QQ_BOOKING_ID]: defaultBookingOptions,
        });

        setConsignmentMap({ [QQ_BOOKING_ID]: consignment });

        setFreightForwardingMap({ [QQ_BOOKING_ID]: false });

        setInsuranceFormMap({
            [QQ_BOOKING_ID]: {
                enabled: sendifyInsuranceEnabledByDefault,
                amount: null,
            },
        });
        setInsuranceAddonMap({ [QQ_BOOKING_ID]: undefined });
        setAddonInputMap({ [QQ_BOOKING_ID]: [] });
        setIsLoadingAlternatives({ [QQ_BOOKING_ID]: false });
    };

    /**
     * Sets the desired pickup time span in the booking info.
     * @param earliestTime must be before latestTime
     * @param latestTime must be after earliestTime
     * @throw Will throw an error if the earliest pickup time is later than the latest
     * pickup time.
     */
    const setPickupTime = (
        bookingId: string,
        earliestTime?: DateTime,
        latestTime?: DateTime
    ) => {
        if (earliestTime && latestTime && earliestTime > latestTime) {
            throw new Error(
                'The latest pickup time must be after the earliest pickup time.'
            );
        }

        const transportAlternatives = transportAlternativeMap[bookingId];
        if (!transportAlternatives) {
            throw new Error('No available transport alternative.');
        }
        const transportAlternative = transportAlternatives.alternatives.find(
            (t) =>
                t.product.code ===
                selectedTransportAlternativeCodeMap[bookingId]
        );

        setBookingOptionsMap(
            produce((draft) => {
                draft[bookingId].pickupAtEarliest =
                    earliestTime || transportAlternative?.loadingEarliestAt;
                draft[bookingId].pickupAtLatest =
                    latestTime || transportAlternative?.loadingLatestAt;
            })
        );
    };

    /**
     * Sets the insured amount of a shipment. Used for Sendify's insurance.
     *
     * @param price the updated costs
     */
    const setGoodsValue = (
        bookingId: string,
        goodsValue: { amount: number; currency: Currency }
    ) => {
        setBookingOptionsMap(
            produce((draft) => {
                draft[bookingId].sendifyInsurance.amount = goodsValue.amount;
                draft[bookingId].sendifyInsurance.currency =
                    goodsValue.currency;
            })
        );
    };

    /**
     * Toggles Sendify insurance on or off.
     *
     * May be called programatically or when a user presses the toggle button.
     */
    const toggleSendifyInsurance = (bookingIds: string[], enabled: boolean) => {
        bookingIds.forEach((id) => {
            const currentForm = insuranceFormMap[id];
            setSendifyInsurance(
                id,
                enabled,
                currentForm?.amount,
                currentForm?.currency
            );
        });
    };

    // Returns the error message if the insurance is incompatible with other addons.
    const getInsuranceIncompatError = (bookingId: string, enabled: boolean) => {
        let error: string | undefined;
        // We currently don't support delivery without proof together with insurance.
        const hasDeliveryWithoutProof = addonInputMap[bookingId]?.find(
            (addon) => addon.code === 'delivery_without_proof'
        );
        if (enabled && hasDeliveryWithoutProof) {
            error = t('compare:addons.errors.incompatibleWith', {
                service: getAddonName('delivery_without_proof', t),
            });
        }
        const hasReceiverPays = addonInputMap[bookingId]?.find(
            (addon) => addon.code === 'receiver_pays'
        );
        if (enabled && hasReceiverPays) {
            error = t('compare:addons.errors.incompatibleWith', {
                service: getAddonName('receiver_pays', t),
            });
        }

        return error;
    };

    /** Validates the insurance form, and sets any errors if the input is invalid.
     *  Returns whether the input is valid.
     */
    const validateSendifyInsurance = (bookingId: string) => {
        const current = insuranceFormMap[bookingId];
        if (!current) {
            return false;
        }
        let error = getInsuranceIncompatError(bookingId, current.enabled);

        if (current.enabled && !(current.amount && current.currency)) {
            error = t('errors.required');
        }

        setInsuranceFormMap(
            produce((draft) => {
                draft[bookingId] = {
                    ...current,
                    error,
                };
            })
        );

        return !error;
    };

    /** Validates the insurance form, and adds it to the addons of the quotes if it's valid. */
    const setSendifyInsurance = async (
        bookingId: string,
        enabled: boolean,
        amount?: number | null,
        currency?: Currency
    ) => {
        const currentAddons = addonInputMap[bookingId];
        if (amount === null || !currency) {
            // We don't have a price, since the user hasn't entered the goods value.
            setInsuranceAddonMap(
                produce((draft) => {
                    draft[bookingId] = undefined;
                })
            );
        }
        if (!enabled || amount === null || !currency) {
            // Clear everything
            setInsuranceFormMap(
                produce((draft) => {
                    draft[bookingId] = {
                        enabled,
                        amount: null,
                        currency: undefined,
                        error: undefined,
                    };
                })
            );

            // Remove insurance from the displayed addons
            const otherAddons = currentAddons.filter(
                (addon) => addon.code !== 'sendify_insurance'
            );

            return setQuoteAddons(bookingId, otherAddons);
        }

        const current = insuranceFormMap[bookingId];
        if (!current) {
            return;
        }
        let error = getInsuranceIncompatError(bookingId, enabled);
        if (enabled && !(amount && currency)) {
            error = t('errors.required');
        }
        setInsuranceFormMap(
            produce((draft) => {
                draft[bookingId] = {
                    enabled,
                    amount,
                    currency,
                    error,
                };
            })
        );
        if (error) {
            return;
        }

        const insuranceInput = [];
        if (enabled) {
            insuranceInput.push({
                code: 'sendify_insurance',
                payload: {
                    declaredValue: amount || undefined,
                    declaredCurrency: currency,
                },
            });
        }

        // Merge with the other addons
        const otherAddons = currentAddons.filter(
            (addon) => addon.code !== 'sendify_insurance'
        );

        const newAddons = [...otherAddons, ...insuranceInput];

        return setQuoteAddons(bookingId, newAddons);
    };

    /**
     * Sets notification, pickup instructions, delivery instructions, and carrier insurance. The service cost is
     * updated for all transport alternatives to reflect the cost of the added services.
     *
     * Some transport alternatives do not charge for notifications. This is not handled by our backend, and
     * is instead handled here; if a transport alternative includes notifications in the cost, it is not added
     * to its service cost.
     * @param bookingId the id of the booking
     * @param addons the addons to be set. All others will be unset.
     * @param costs note that all active service costs need to be present, including insurance.
     */
    const setSelectedAddons = (
        bookingId: string,
        addons: CarrierAddons,
        costs?: ProductAddonCosts[]
    ) => {
        setBookingOptionsMap(
            produce((draft) => {
                draft[bookingId].carrierAddons = addons;
            })
        );
        if (transportAlternativeMap[bookingId]) {
            setTransportAlternativeMap(
                produce((draft) => {
                    if (costs) {
                        draft[bookingId]?.alternatives?.forEach(
                            (
                                transportAlternative: TransportAlternative,
                                index: number
                            ) => {
                                const matchingService = costs.find(
                                    (entry) =>
                                        entry.code ===
                                        transportAlternative.product.code
                                );
                                const alternative =
                                    draft[bookingId]?.alternatives?.[index];
                                if (alternative && matchingService?.cost) {
                                    alternative.addonCosts =
                                        matchingService.cost;
                                }
                            }
                        );
                    }
                })
            );
        }
    };

    /**
     * Resets an addon back to undefined
     * @param bookingId
     * @param service the name of the addon
     */
    const removeAddon = (bookingId: string, addon: keyof CarrierAddons) => {
        setBookingOptionsMap(
            produce((draft) => {
                // Null check. The booking options for QQ may be undefined
                // if the user alters the query params in the URL manually.
                if (draft[bookingId]) {
                    const addonDraft = draft[bookingId].carrierAddons;
                    if (addonDraft) {
                        addonDraft[addon] = undefined;
                    }
                }
            })
        );
    };

    /**
     * Sets the selected transport alternative, and also resets the pickup time to the default
     * of that transport alternative.
     */
    const setSelectedTransportAlternative = (
        bookingId: string,
        transportAlternative: TransportAlternative
    ) => {
        setBookingOptionsMap(
            produce((draft) => {
                draft[bookingId].pickupAtEarliest =
                    transportAlternative.loadingEarliestAt;
                draft[bookingId].pickupAtLatest =
                    transportAlternative.loadingLatestAt;
                draft[bookingId].alternativeFromCity = undefined;
                draft[bookingId].alternativeToCity = undefined;
                draft[bookingId].pickupLocationInternalId = undefined;
            })
        );

        setSelectedTransportAlternativeCodeMap(
            produce((draft) => {
                draft[bookingId] = transportAlternative.product.code;
            })
        );
    };

    /**
     * Resets the selected transport alternative
     */
    const removeSelectedTransportAlternative = (bookingId: string) => {
        setBookingOptionsMap(
            produce((draft) => {
                draft[bookingId].alternativeFromCity = undefined;
                draft[bookingId].alternativeToCity = undefined;
                draft[bookingId].pickupLocationInternalId = undefined;
            })
        );

        setSelectedTransportAlternativeCodeMap(
            produce((draft) => {
                delete draft[bookingId];
            })
        );
    };

    /**
     * Sets the consignment used in a booking, for example if it has been edited from the
     * compare page.
     */
    const setConsignment = (
        bookingId: string,
        consignment: BookingConsignment
    ) => {
        setConsignmentMap(
            produce((draft) => {
                draft[bookingId] = consignment;
            })
        );
    };
    /**
     * Adds transport alternatives, note that it only adds an alternative if it doesn't already exist.
     * @param id the booking id
     * @param searchResultId
     * @param newResults
     * @param requestId
     * @param addonInput
     */
    const addTransportAlternatives = (
        id: string,
        searchResultId: string,
        newResults: TransportAlternative[],
        requestId: string | null,
        addonInput: AddonInput[]
    ) => {
        setAddonInputMap(
            produce((draft) => {
                draft[id] = addonInput;
            })
        );

        // If some part of the addon input is for insurance, we need to set it in the insurance form.
        const insuranceAddonInput = addonInput.find(
            (addon) => addon.code === 'sendify_insurance'
        );
        if (insuranceAddonInput) {
            setInsuranceFormMap(
                produce((draft) => {
                    draft[id] = {
                        enabled: true,
                        amount: insuranceAddonInput.payload?.declaredValue,
                        currency: insuranceAddonInput.payload?.declaredCurrency,
                        error: undefined,
                    };
                })
            );
        }

        // If insurance is part of the default addons, we need to set the cached insurance addon.
        newResults.forEach((result) => {
            if ((result.addons?.length || 0) > 0) {
                const insuranceAddon = result.addons?.find(
                    (addon) => addon.code === 'sendify_insurance'
                );
                setInsuranceAddonMap(
                    produce((draft) => {
                        draft[id] = insuranceAddon;
                    })
                );
            }
        });
        setTransportAlternativeMap(
            produce((draft) => {
                draft[id] = {
                    requestId,
                    searchResultId,
                    alternatives: newResults,
                    addonInput,
                };
            })
        );

        // We need to reset the addons if the transport alternatives change, since the addon
        // costs aren't included in the search result. See https://sendify.atlassian.net/browse/NUC-1114
        setBookingOptionsMap(
            produce((draft) => {
                draft[id].carrierAddons = {};
            })
        );
    };

    /**
     * Clears the transport alternative, the selected transport alternative, and any booking info
     * associated with this booking.
     */
    const resetBooking = (bookingId: string) => {
        setTransportAlternativeMap(
            produce((draft) => {
                delete draft[bookingId];
            })
        );
        setSelectedTransportAlternativeCodeMap(
            produce((draft) => {
                delete draft[bookingId];
            })
        );
        setBookingOptionsMap(
            produce((draft) => {
                draft[bookingId] = defaultBookingOptions;
            })
        );
        setInsuranceFormMap(
            produce((draft) => {
                draft[bookingId] = {
                    enabled: sendifyInsuranceEnabledByDefault,
                    amount: null,
                };
            })
        );
        setInsuranceAddonMap(
            produce((draft) => {
                delete draft[bookingId];
            })
        );
        setAddonInputMap(
            produce((draft) => {
                delete draft[bookingId];
            })
        );
        setIsLoadingAlternatives(
            produce((draft) => {
                delete draft[bookingId];
            })
        );
        setReceiverPaysMap(
            produce((draft) => {
                delete draft[bookingId];
            })
        );
    };

    /**
     * Sets an pickup city, from a list of suggested pickup cities.
     */
    const setAlternativePickupCity = (id: string, city?: string) => {
        setBookingOptionsMap(
            produce((draft) => {
                if (consignmentMap[id].viaSender) {
                    draft[id].alternativeFromViaCity = city;
                } else {
                    draft[id].alternativeFromCity = city;
                }
            })
        );
    };

    /**
     * Sets a delivery city, from a list of suggested delivery cities.
     */
    const setAlternativeDeliveryCity = (id: string, city?: string) => {
        setBookingOptionsMap(
            produce((draft) => {
                if (consignmentMap[id].viaRecipient) {
                    draft[id].alternativeToViaCity = city;
                } else {
                    draft[id].alternativeToCity = city;
                }
            })
        );
    };

    /**
     * Sets the ID of a DHL Service Point or Parcel Connect location.
     */
    const setPickupLocationInternalId = (id: string, internalId?: string) => {
        setBookingOptionsMap(
            produce((draft) => {
                draft[id].pickupLocationInternalId = internalId;
            })
        );
    };

    /**
     * Stores and ABD reference to the booking.
     * @param id booking id
     * @param abd Set to null to clear the ABD.
     */
    const setABD = (id: string, abd: FileList | null) => {
        setBookingOptionsMap(
            produce((draft) => {
                draft[id].abd = abd || undefined;
            })
        );
    };

    // Saves the consignment as a draft, and removes its associated booking and related data from the flow.
    const saveAsDraft = async (id: string, consignment: Consignment) => {
        await setConsignmentStatusDraft(consignment.id);
        queryClient.invalidateQueries(['cart']);

        if (bookings === undefined) {
            throw new Error('The booking hook is not initialized');
        }

        setBookings(
            produce((draft) => {
                const index = bookings.findIndex((booking) => booking === id);
                draft?.splice(index, 1);
            })
        );
        setTransportAlternativeMap(
            produce((draft) => {
                delete draft[id];
            })
        );
        setInsuranceFormMap(
            produce((draft) => {
                delete draft[id];
            })
        );
        setInsuranceAddonMap(
            produce((draft) => {
                delete draft[id];
            })
        );
        setAddonInputMap(
            produce((draft) => {
                delete draft[id];
            })
        );
        setSelectedTransportAlternativeCodeMap(
            produce((draft) => {
                delete draft[id];
            })
        );
        setBookingOptionsMap(
            produce((draft) => {
                draft[id] = defaultBookingOptions;
            })
        );
        setConsignmentMap(
            produce((draft) => {
                delete draft[id];
            })
        );
        setFreightForwardingMap(
            produce((draft) => {
                draft[id] = false;
            })
        );
        setIsLoadingAlternatives(
            produce((draft) => {
                delete draft[id];
            })
        );
        setReceiverPaysMap(
            produce((draft) => {
                delete draft[id];
            })
        );
    };

    /**
     * The amount of bookings that have a selected transport alternative.
     */
    const numberOfSelectedTransportAlternatives = (bookings || []).filter(
        (b) => selectedTransportAlternativeCodeMap[b] !== undefined
    ).length;

    const consignments = (bookings || []).reduce<Consignment[]>((acc, b) => {
        const consignment = consignmentMap[b];
        if (consignment && !isRequest(consignment)) {
            return [...acc, consignment];
        }
        return acc;
    }, []);

    const blockImports =
        !!subscriptions?.apiSubscription &&
        hasExceededImportLimit(
            team?.apiTier,
            subscriptions?.apiSubscription.importedOrdersBooked,
            consignments
        );

    /**
     * If there are any bookings, this returns an object with the booking IDs as keys and
     * its selected transport alternative (or undefined, if no transport alternative has been selected) as value.
     * Returns undefined if there are no bookings.
     */
    const selectedTransportAlternativeMap = useMemo(
        () =>
            bookings?.reduce(
                (acc, bookingId) => {
                    return {
                        ...acc,
                        [bookingId]: transportAlternativeMap[
                            bookingId
                        ]?.alternatives?.find(
                            (b) =>
                                b.product.code ===
                                selectedTransportAlternativeCodeMap[bookingId]
                        ),
                    };
                },
                {} as {
                    [key: string]: TransportAlternative | undefined;
                }
            ),

        [bookings, transportAlternativeMap, selectedTransportAlternativeCodeMap]
    );

    /**
     * The sum of all selected transport alternatives. Ignores the bookings without selected
     * options.
     */
    const totalCost = useMemo(() => {
        return (bookings || []).reduce((accumulator, b) => {
            const selectedAlternative = selectedTransportAlternativeMap?.[b] as
                | TransportAlternative
                | undefined;
            if (!selectedAlternative) {
                return accumulator;
            }
            if (
                selectedAlternative.addons?.find(
                    (a) => a.code === 'receiver_pays'
                )
            ) {
                return accumulator;
            }
            return (
                accumulator +
                getTransportAlternativeCost(selectedAlternative, 0)
            );
        }, 0);
    }, [
        bookings,
        selectedTransportAlternativeMap,
        bookingOptionsMap,
        sendifyInsurancePrices,
    ]);

    /**
     * Sends a freight forwarding requests and performs necessary updates
     * in the booking flow, such as clearing the other transport options.
     */
    const requestFreightForwarding = async (
        bookingId: string,
        freightForwarding: FreightForwarding
    ) => {
        await newFreightForwarding(freightForwarding);
        setFreightForwardingMap(
            produce((draft) => {
                draft[bookingId] = true;
            })
        );
        setTransportAlternativeMap(
            produce((draft) => {
                const transportAlternatives = draft[bookingId];
                if (transportAlternatives) {
                    transportAlternatives.alternatives = [];
                }
            })
        );
        setSelectedTransportAlternativeCodeMap(
            produce((draft) => {
                delete draft[bookingId];
            })
        );
        setBookingOptionsMap(
            produce((draft) => {
                draft[bookingId] = defaultBookingOptions;
            })
        );
        setInsuranceFormMap(
            produce((draft) => {
                delete draft[bookingId];
            })
        );
        setInsuranceAddonMap(
            produce((draft) => {
                delete draft[bookingId];
            })
        );
        setIsLoadingAlternatives(
            produce((draft) => {
                draft[bookingId] = false;
            })
        );
    };

    /**
     *
     * @returns true if we should show a page where the user can upload an ADB, false
     * otherwise.
     */
    const showDocumentPage = useMemo(() => {
        const { customsBeforeBook } = useFlags();
        return (
            bookings?.some(
                (b) =>
                    showADBOptions(
                        consignmentMap[b],
                        selectedTransportAlternativeMap?.[b],
                        bookingOptionsMap[b]
                    ) ||
                    (showCustomsInvoiceOptions(
                        consignmentMap[b],
                        selectedTransportAlternativeMap?.[b]
                    ) &&
                        customsBeforeBook)
            ) || false
        );
    }, [
        bookings,
        consignmentMap,
        selectedTransportAlternativeMap,
        bookingOptionsMap,
    ]);

    /**
     * Sets the selected addons to the quotes. This will set all supported addons to the transport alternatives,
     * as well as a list of unsupported addon codes. It will also override any previously set addons.
     *
     * Included/required addons will remain in the list, even if they are not included in the input.
     */
    const setQuoteAddons = async (id: string, addonInput: AddonInput[]) => {
        // Update the quote addons. This will return all addons set on the quotes, as well as unsupported
        // addon codes.
        setIsLoading(id, true);
        const res = await setAddons(
            transportAlternativeMap[id].alternatives.map((a) => a.id),
            consignmentMap[id] as QuickQuotationConsignment,
            addonInput
        );

        // This is called after the async call, to be able to show the loading spinner in the form
        // before resetting (syncing) it with the current addons.
        setAddonInputMap(
            produce((draft) => {
                draft[id] = addonInput;
            })
        );

        let insuranceAddon: Addon | undefined;
        Object.values(res).forEach(({ addons }) => {
            // The insurance price is the same for all transport alternatives. Find the first one, if it exists.
            // We cache this to show the price of it, even if the user deselects it. This is for legal reasons.
            if (!insuranceAddon) {
                insuranceAddon = addons.find(
                    (addon: Addon) => addon.code === 'sendify_insurance'
                );
            }
        });
        if (insuranceAddon) {
            setInsuranceAddonMap(
                produce((draft) => {
                    draft[id] = insuranceAddon;
                })
            );
        }

        // Update the transport alternatives with the new addons and unsupported addon codes.
        setTransportAlternativeMap(
            produce((draft) => {
                const transportAlternatives = draft[id];

                const updatedAlternatives =
                    transportAlternatives.alternatives.map((t) => ({
                        ...t,
                        addons: res?.[t.id]?.addons || [],
                        unsupportedAddonCodes:
                            res?.[t.id]?.unsupportedAddonCodes || [],
                        unsupportedAddonInput:
                            res?.[t.id]?.unsupportedAddonInput || [],
                    }));
                draft[id].alternatives = updatedAlternatives;
            })
        );

        setIsLoading(id, false);
    };
    const setReceiverPaysOption = (
        bookingId: string,
        option?: ReceiverPaysOptions
    ) => {
        setReceiverPaysMap(
            produce((draft) => {
                if (option) {
                    draft[bookingId] = option;
                } else {
                    draft[bookingId] = undefined;
                }
            })
        );
    };

    return {
        startBookingFlow,
        startBookingFlowFromQQ,
        clear,
        isInitialized,
        bookings,
        setPickupTime,
        setSelectedTransportAlternative,
        removeSelectedTransportAlternative,
        addTransportAlternatives,
        setConsignment,
        setABD,
        consignmentMap,
        setSelectedAddons,
        saveAsDraft,
        selectedTransportAlternativeMap,
        numberOfSelectedTransportAlternatives,
        totalCost,
        blockImports,
        flow,
        bookingOptionsMap,
        setAlternativePickupCity,
        setAlternativeDeliveryCity,
        setPickupLocationInternalId,
        transportAlternativeMap,
        resetBooking,
        requestFreightForwarding,
        freightForwardingMap,
        showDocumentPage,
        removeAddon,
        setGoodsValue,
        setSendifyInsurance,
        validateSendifyInsurance,
        insuranceFormMap,
        insuranceAddonMap,
        toggleSendifyInsurance,
        sendifyInsurancePrices,
        setQuoteAddons,
        addonInputMap,
        isLoading: isLoadingAlternatives,
        setIsLoading,
        receiverPaysOptionsMap,
        setReceiverPaysOption,
    };
};
