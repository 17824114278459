/* eslint-disable no-undef */
import ApiError from 'api/ApiError';
import { HttpResponse } from 'api/types';
import { goDomain } from 'config';
import UserDataHelper from 'helpers/UserDataHelper';
import i18n from 'i18next';
import { queryClient } from 'queryClient';

import { REFRESH_TOKEN, TOKEN } from '../types/LocalStorage';

const isObject = (value: any) => value !== null && typeof value === 'object';
const isFormData = (value: any) =>
    typeof FormData !== 'undefined' && value instanceof FormData;

const getRequestBody = (body: any) => {
    if (isFormData(body)) {
        return body;
    }
    if (isObject(body)) {
        return JSON.stringify(body);
    }
    return body;
};

const getRequest = (url: string, config: RequestInit): Request => {
    const transformedConfig = { ...config };
    transformedConfig.body = getRequestBody(transformedConfig.body);
    const request = new Request(url, transformedConfig);

    const token = localStorage.getItem(TOKEN);
    request.headers.set('Authorization', `Bearer ${token}`);

    if (!isFormData(transformedConfig.body)) {
        request.headers.set('Content-Type', 'application/json');
    }
    request.headers.set('Accept', 'application/json');

    request.headers.set('X-Api-Locale', i18n.language);
    return request;
};

export const http = async <T = any>(
    url: string,
    config: RequestInit,
    isRetry = false
): Promise<HttpResponse<T>> => {
    const request = getRequest(url, config);

    const response: HttpResponse<T> = await fetch(request).catch(
        (e: unknown) => {
            // The fetch request failed, log additional data to Sentry.
            console.debug(`Failing fetch call: ${config.method} ${url}`);
            console.debug(`Error: ${JSON.stringify(e)}`);
            console.debug(e); // Fallback if the error is not an object.
            throw new Error('Failed to fetch');
        }
    );

    if (response.headers.get('Content-Type')?.includes('application/json')) {
        response.parsedBody = await response.json();
    }

    if (!response.ok) {
        // If we have a token we try to refresh
        if (response.status === 401) {
            if (localStorage.getItem(TOKEN)) {
                // If we have already tried to refresh to token we clear the user data.
                if (isRetry) {
                    UserDataHelper.clearUserData();
                    queryClient.clear();
                } else {
                    try {
                        await refreshToken();
                        return http(url, config, true);
                    } catch (err) {
                        UserDataHelper.clearUserData();
                    }
                }
            } else {
                // The user does not have a token.
                UserDataHelper.clearUserData();
                if (window.location.href.includes('login')) {
                    // It's only on the login page we show an error for 401s (invalid credentials)
                    throw new ApiError(response);
                } else {
                    // Refresh the window to clear the cache.
                    queryClient.clear();
                }
            }
        } else if (response.status === 403) {
            const code = (response?.parsedBody as any)?.code;
            if (
                // Backend sends an error with code 4000 if team is blocked.
                code === 4000 ||
                // Backend sends an error with code 4001 if a user is not part of the team.
                code === 4001
            ) {
                UserDataHelper.clearUserData();
                if (window.location.href.includes('login')) {
                    // Throw an error so the blocked team modal can be shown (only available on the login page).
                    throw new ApiError(response);
                } else {
                    // Refresh the window to clear the cache.
                    queryClient.clear();
                }
            }
            throw new ApiError(response);
        } else {
            throw new ApiError(response);
        }
    }

    return response;
};

export const get = async <T = any>(
    url: string,
    config?: RequestInit
): Promise<HttpResponse<T>> => {
    return http<T>(url, { method: 'GET', mode: 'cors', ...config });
};

export const post = async <T = any>(
    url: string,
    data?: any,
    config?: RequestInit
): Promise<HttpResponse<T>> => {
    return http<T>(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        ...config,
    });
};

export const put = async <T = any>(
    url: string,
    data?: any,
    config?: RequestInit
): Promise<HttpResponse<T>> => {
    return http<T>(url, { method: 'PUT', mode: 'cors', body: data, ...config });
};

export const patch = async <T = any>(
    url: string,
    data: any,
    config?: RequestInit
): Promise<HttpResponse<T>> => {
    return http<T>(url, {
        method: 'PATCH',
        mode: 'cors',
        body: data,
        ...config,
    });
};

export const remove = async <T = any>(
    url: string,
    config?: RequestInit
): Promise<HttpResponse<T>> => {
    return http<T>(url, { method: 'DELETE', mode: 'cors', ...config });
};
const refreshToken = async () => {
    const refresh = await fetch(`${goDomain}v2/auth/refresh`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
            refresh_token: localStorage.getItem('refreshToken'),
        }),
    });
    const json = await refresh.json();
    localStorage.setItem(TOKEN, json.access_token);
    localStorage.setItem(REFRESH_TOKEN, json.refresh_token);
};
