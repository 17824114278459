import { PackageType } from 'api/packages/types';
import { ImageProps, Package, Pallet, Unspecified } from 'assets/packages';
import { TFunction } from 'i18next';

export interface PackageOptions {
    key: string;
    value: PackageType;
    image: (props: ImageProps) => JSX.Element;
}
export const packageOptions: PackageOptions[] = [
    {
        key: 'goods:parcel',
        value: 'PACKAGE',
        image: Package,
    },
    {
        key: 'goods:pallet',
        value: 'PALLET',
        image: Pallet,
    },
    {
        key: 'goods:unspecified',
        value: 'UNSPECIFIED',
        image: Unspecified,
    },
];

export const translatedPackageOptions = (t: TFunction, quantity = 1) => {
    return packageOptions.map((option) => {
        return {
            label: t(option.key, { count: quantity }),
            ...option,
        };
    });
};
