import { UseQueryResult } from '@tanstack/react-query';
import { Consignment, ReceiverPaysOptions } from 'api/consignments/types';
import { FreightForwarding } from 'api/freight-forwarding/types';
import { InsurancePrice } from 'api/insurance/types';
import { CarrierAddons } from 'api/products/types';
import { QuickQuotationConsignment } from 'api/qq/types';
import { Addon, AddonInput } from 'api/search';
import { DateTime } from 'luxon';
import { Currency } from 'types/UserData';

import {
    BookingConsignment,
    BookingOptions,
    ProductAddonCosts,
    SendifyInsurance,
    TransportAlternative,
} from '../Booking/types';

export interface UseBookingFlowOptions {
    startBookingFlow: (consignments: Consignment[]) => void;
    startBookingFlowFromQQ: (consignment: QuickQuotationConsignment) => void;
    isInitialized: boolean;
    clear: () => void;
    blockImports: boolean;
    flow?: Flow;
    bookings: string[] | undefined;
    setSelectedTransportAlternative: (
        id: string,
        transportAlternative: TransportAlternative
    ) => void;
    setABD: (id: string, abd: FileList | null) => void;
    removeSelectedTransportAlternative: (id: string) => void;
    addTransportAlternatives: (
        id: string,
        searchResultId: string,
        transportAlternatives: TransportAlternative[],
        requestId: string | null,
        addonInput: AddonInput[]
    ) => void;
    resetBooking: (id: string) => void;
    bookingOptionsMap: {
        [key: string]: BookingOptions;
    };
    transportAlternativeMap: {
        [key: string]:
            | {
                  requestId: string | null;
                  searchResultId: string;
                  alternatives: TransportAlternative[];
                  addonInput: AddonInput[];
              }
            | undefined;
    };
    selectedTransportAlternativeMap:
        | {
              [key: string]: TransportAlternative | undefined;
          }
        | undefined;
    consignmentMap: {
        [key: string]: BookingConsignment;
    };
    setConsignment: (id: string, consignment: BookingConsignment) => void;
    setPickupTime: (
        id: string,
        earliestTime?: DateTime,
        latestTime?: DateTime
    ) => void;
    saveAsDraft: (id: string, consignment: Consignment) => Promise<void>;
    numberOfSelectedTransportAlternatives: number;
    setPickupLocationInternalId: (id: string, internalId?: string) => void;
    setAlternativePickupCity: (id: string, city?: string) => void;
    setAlternativeDeliveryCity: (id: string, city?: string) => void;
    requestFreightForwarding: (
        id: string,
        freightForwarding: FreightForwarding
    ) => Promise<void>;
    freightForwardingMap: {
        [key: string]: boolean;
    };
    totalCost: number;
    showDocumentPage: boolean;

    // Carrier addons, including carrier insurance

    /** Must include costs, to keep things in sync. */
    setSelectedAddons: (
        id: string,
        addons: CarrierAddons,
        costs?: ProductAddonCosts[]
    ) => void;
    //  Sendify insurance
    insuranceAddonMap: {
        [key: string]: Addon | undefined;
    };
    setSendifyInsurance: (
        bookingId: string,
        enabled: boolean,
        amount?: number | null,
        currency?: Currency
    ) => Promise<void>;
    validateSendifyInsurance: (bookingId: string) => boolean;
    insuranceFormMap: {
        [key: string]: SendifyInsurance | undefined;
    };
    removeAddon: (id: string, addon: keyof CarrierAddons) => void;
    setQuoteAddons: (id: string, addons: AddonInput[]) => Promise<void>;
    addonInputMap: {
        [key: string]: AddonInput[];
    };
    /** Used for  Sendify's own insurance. */
    setGoodsValue: (
        id: string,
        goodsValue: { amount: number; currency: Currency }
    ) => void;
    /** Toggles insurance on or off for one or more bookings. */
    toggleSendifyInsurance: (bookingId: string[], enabled: boolean) => void;
    /** The customer price of the shipment, based on the goods value. */
    sendifyInsurancePrices: {
        [bookingId: string]: UseQueryResult<InsurancePrice | undefined>;
    };
    isLoading: {
        [bookingId: string]: boolean;
    };
    setIsLoading: (bookingId: string, isLoading: boolean) => void;
    receiverPaysOptionsMap: {
        [key: string]: ReceiverPaysOptions | undefined;
    };
    setReceiverPaysOption: (id: string, account?: ReceiverPaysOptions) => void;
}

/**
 * The 'qq' flow is used where there is no complete consignment added during
 * the initialization call, for example when going from the dashboard or search signup.
 * 'newshipment' is used when accessing the booking flow from the cart, which has one or
 * more active consignments.
 */
export type Flow = 'qq' | 'newshipment';

/**
 * Used as the booking ID of a QQ consignment, since it does not have a consignment id.
 */
export const QQ_BOOKING_ID = 'qq';
