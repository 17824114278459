/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';

import { PackageType } from '../../api/packages/types';
import RawMultiPallet from './multipallet.png';
import RawRegularPackage from './package.png';
import RawLargePackage from './package@2x.png';
import RawRegularPallet from './pallet.png';
import RawLargePallet from './pallet@2x.png';
import RawRegularUnspecified from './unspecified.png';

export interface ImageProps
    extends React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    > {
    size?: 'regular' | 'large';
}

export const Package = ({ size, ...rest }: ImageProps) => {
    const { t } = useTranslation('goods');
    if (size === 'large') {
        return <img {...rest} src={RawLargePackage} alt={t('parcel')} />;
    }
    return <img {...rest} src={RawRegularPackage} alt={t('parcel')} />;
};
export const MultiPallet = (props: ImageProps) => {
    const { t } = useTranslation('goods');

    return <img {...props} src={RawMultiPallet} alt={t('multipallet')} />;
};

export const Pallet = ({ size, ...rest }: ImageProps) => {
    const { t } = useTranslation('goods');
    if (size === 'large') {
        return <img {...rest} src={RawLargePallet} alt={t('parcel')} />;
    }
    return <img {...rest} src={RawRegularPallet} alt={t('pallet')} />;
};

export const Unspecified = (props: ImageProps) => {
    const { t } = useTranslation('goods');
    return (
        <img {...props} src={RawRegularUnspecified} alt={t('unspecified')} />
    );
};

interface PackageImageProps extends ImageProps {
    type: PackageType;
}

export const ParcelImage = (props: PackageImageProps) => {
    switch (props.type) {
        case 'PALLET':
            return <Pallet {...props} />;
        case 'PACKAGE':
            return <Package {...props} />;
        case 'UNSPECIFIED':
            return <Unspecified {...props} />;
    }
};
