import { InfoCircle } from 'assets/icons';
import ErrorMessage from 'components/FormElements/ErrorMessage';
import Tooltip from 'components/Tooltip';
import Text from 'components/Typography/Text';
import { Ref, useId } from 'react';

import {
    ButtonWrapper,
    Label,
    StyledButton,
    TopText,
} from './StyledToggleButton';

interface Option<T> {
    value: T;
    title: string;
}

interface Props<T> {
    onChange: (value: T) => void;
    value: T;
    options: Option<T>[];
    fullWidth?: boolean;
    errorMessage?: string;
    label?: string;
    name?: string;
    className?: string;
    tooltip?: string;
    disabled?: boolean;
}

/**
 * A radio button group variant, where each radio button is a button. The buttons
 * are arranged horizontally.
 */
const ToggleButton = <T extends string | number>({
    onChange: onSelect,
    value,
    options,
    fullWidth = false,
    ref,
    errorMessage,
    name,
    label,
    className,
    tooltip,
    disabled = false,
}: Props<T> & { ref?: Ref<HTMLDivElement> | null }) => {
    const id = useId();
    return (
        <div className={className}>
            {(label || tooltip) && (
                <TopText>
                    {label && <Label htmlFor={id}>{label}</Label>}{' '}
                    {tooltip && (
                        <>
                            <InfoCircle
                                height="20"
                                data-tooltip-id={`tooltip-${id}`}
                            />
                            <Tooltip id={`tooltip-${id}`}>{tooltip}</Tooltip>
                        </>
                    )}
                </TopText>
            )}
            <ButtonWrapper ref={ref} id={id} fullWidth={fullWidth}>
                {options.map((option) => (
                    <StyledButton
                        disabled={disabled}
                        error={!!errorMessage}
                        name={name}
                        key={String(option.value)}
                        fullWidth={fullWidth}
                        variant="secondary"
                        onClick={() => {
                            onSelect(option.value);
                        }}
                        active={value === option.value}
                    >
                        {option.title}
                    </StyledButton>
                ))}
            </ButtonWrapper>
            {errorMessage && (
                <ErrorMessage>
                    <Text>{errorMessage}</Text>
                </ErrorMessage>
            )}
        </div>
    );
};

export default ToggleButton;
