import * as Sentry from '@sentry/react';
import { get, post, remove } from 'api';
import ApiError from 'api/ApiError';
import { partialBackendConsignmentToConsignment } from 'api/consignments/mapper';
import { goDomain } from 'config';
import UserDataHelper from 'helpers/UserDataHelper';
import { DateTime } from 'luxon';

import {
    AddFavoriteResponse,
    FavoriteRequest,
    FavoritesResponse,
} from './models';

export const getFavorites = async () => {
    const response = await get<FavoritesResponse[]>(`${goDomain}v2/favorites/`);

    if (response.parsedBody) {
        const favorites = response.parsedBody.map((favorite) => {
            const newFavorite = { ...favorite.favorite };
            const nowISO = DateTime.now().toISO();

            // Set pickup times to current time
            newFavorite.pickup_at_earliest = nowISO;
            newFavorite.pickup_at_latest = nowISO;

            // Set from_via to false if all via fields are empty the mapping from backend to frontend is not 1:1
            if (
                newFavorite.from_via_name === '' &&
                newFavorite.from_via_is_private === false
            ) {
                newFavorite.from_via = false;
            }

            return {
                ...favorite,
                favorite: newFavorite,
            };
        });

        return favorites.map((favorite) => ({
            favorite: partialBackendConsignmentToConsignment(favorite.favorite),
            favoriteName: favorite.favoriteName,
            id: favorite.id,
        }));
    }
    const shipmentError = new Error(
        'Failed to fetch favorites from the server. No or malformed data was returned.'
    );
    Sentry.captureException(shipmentError, {
        tags: {
            teamId: UserDataHelper.getTeamId() || 'undefined',
        },
    });
    throw new ApiError(response);
};

function favoriteReplacer(key: string, value: any) {
    if (
        key === 'id' ||
        key === 'desirable_product_code' ||
        key === 'order' ||
        key === 'pickup_at_latest' ||
        key === 'booked_at_at'
    ) {
        return undefined;
        // undefined properties are automatically omitted in JSON.stringify
    }
    if (
        key === 'created_at' ||
        key === 'updated_at' ||
        key === 'pickup_at_earliest'
    ) {
        return DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
    }
    if (key === 'addons' && Object.keys(value).length === 0) {
        return undefined;
    }
    return value;
}

export const addFavorite = async (favorite: FavoriteRequest) => {
    const cleanedFavorite = { ...favorite };

    const response = await post<AddFavoriteResponse>(
        `${goDomain}v2/favorites/`,
        JSON.stringify(cleanedFavorite, favoriteReplacer)
    );

    if (response.parsedBody) {
        return response.parsedBody;
    }
    const shipmentError = new Error(
        'Failed to add favorite from the server. No or malformed data was returned.'
    );
    Sentry.captureException(shipmentError, {
        tags: {
            teamId: UserDataHelper.getTeamId() || 'undefined',
        },
    });
    throw new ApiError(response);
};

interface DeleteFavoriteResponse {
    status: string;
}

export const deleteFavorite = async (favoriteId: number) => {
    const response = await remove<DeleteFavoriteResponse>(
        `${goDomain}v2/favorites/${favoriteId}`
    );

    if (response.parsedBody) {
        return response.parsedBody;
    }
    throw new ApiError(response);
};
