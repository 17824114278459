import { Account, ChevronDown } from 'assets/icons';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, withTheme } from 'styled-components/macro';

const DropDownContainer = styled.ul`
    max-height: 30vh;
    width: unset;
`;

const DropDownDownArrow = styled(ChevronDown)`
    fill: currentColor;
    height: 20px;
    width: 20px;
    ${({ isDropdownDisabled }) =>
        isDropdownDisabled
            ? css`
                  opacity: 0.4;
              `
            : ''}
`;
const ListItem = styled.li``;
const DropdownTitle = styled.span``;
const BadgeIcon = styled.div``;
const HeaderImg = styled.img.attrs((props) => ({
    src: props.img ? props.img : '',
}))``;

const DropdownStyle = styled.div`
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    ${({ dtype, theme, withBorder }) =>
        dtype === 'button' && dtype !== 'text' && !withBorder
            ? ''
            : css`
                  border: 1px solid ${theme.colors.grey100};
              `}
    ${({ dtype, img, theme, withBorder }) =>
        dtype === 'text' && !img && withBorder
            ? css`
                  border: 1px solid ${theme.colors.grey400};
              `
            : ''}
    display: inline-flex;
    justify-content: center;
    padding: 8px 8px 8px 12px;
    position: relative;

    ${(props) =>
        props.dtype === 'icon'
            ? 'height: 36px; width: 36px; '
            : 'min-width:auto;'}
    ${({ dtype }) =>
        dtype === 'img'
            ? css`
                  border: none;
                  height: 44px;
                  padding: 0;
                  width: 44px;
                  & img {
                      border-radius: 24px;
                      height: 24px;
                      transition: all 0.2s ease-in-out;
                      width: 24px;
                  }
              `
            : ''}

    @media (max-width:${({ theme }) => theme.breakpoints.md}) {
        ${({ dtype }) =>
            dtype === 'img'
                ? css`
                      height: 48px;
                      width: 48px;
                  `
                : ''}
    }

    &:focus {
        background: ${({ theme }) => theme.colors.grey500};
    }
    &:hover {
        background: ${({ theme }) => theme.colors.grey500};
        ${({ isDropdownDisabled }) =>
            isDropdownDisabled
                ? css`
                      border-color: ${({ theme }) => theme.colors.grey400};
                  `
                : ''}
    }
    ${({ isDropdownDisabled }) =>
        isDropdownDisabled
            ? css`
                  background: ${({ theme }) => theme.colors.grey500};
                  border-color: ${({ theme }) => theme.colors.grey400};
                  color: ${({ theme }) => theme.colors.grey200};
                  cursor: not-allowed;
                  pointer-events: all;
              `
            : `
    `}
    .dropdown-open {
        transform: rotate(90deg);
    }

    ${HeaderImg} {
        height: 20px;
        margin-left: 2px;
        margin-right: 8px;
        vertical-align: top;
        width: 20px;
    }
    ${DropdownTitle} {
        align-items: center;
        color: ${({ color }) => color};
        display: flex;
        flex-direction: row;
    }

    svg {
        position: relative;
    }
    ${BadgeIcon} {
        ${({ badge }) => (badge ? 'display:block;' : 'display:none;')}
        background-color: ${({ theme }) => theme.colors.red200};
        border: 2px solid ${({ theme }) => theme.colors.grey100};
        height: 10px;
        left: 7px;
        position: relative;
        top: -24px;
        width: 10px;
        z-index: 1;
    }

    ${({ hoverToOpen }) =>
        hoverToOpen &&
        css`
            &:hover {
                ${DropDownContainer} {
                    display: block;
                }
            }
        `}
    ${({ isDropdownDisabled }) =>
        isDropdownDisabled
            ? css`
                  background: ${({ theme }) => theme.colors.grey500};
                  border-color: ${({ theme }) => theme.colors.grey400};
                  color: ${({ theme }) => theme.colors.grey200};
                  cursor: not-allowed;
                  pointer-events: all;
              `
            : `
    `}
    ${DropDownContainer} {
        background-color: ${({ theme }) => theme.colors.white};
        ${({ open }) => (open === 'top' ? 'bottom:-12px;' : 'top:18px;')};
        ${({ open }) => (open === 'left' ? 'right:0;' : 'left:0;')};
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05),
            0 2px 8px 0 rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        cursor: pointer;
        list-style: none;
        overflow-y: auto;
        padding: 8px 0px;
        position: absolute;
        text-align: left;
        ${({ dtype }) => (dtype === 'icon' ? 'width: 320px;' : '')}
        ${(props) => (props.dtype === 'button' ? 'width: auto;' : '')}
        z-index: ${({ theme }) => theme.zIndex.dropdown};

        ${ListItem} {
            align-items: center;
            color: ${({ theme }) => theme.colors.black};
            cursor: pointer;
            display: flex;
            padding: 8px 20px 8px 16px;
            text-align: left;
            gap: 2px;

            ${({ dtype, theme }) =>
                dtype === 'icon'
                    ? css`
                          border-bottom: 1px solid ${theme.colors.grey400};
                          padding: 8px 8px;
                      `
                    : ''}
            &:hover {
                background-color: ${({ theme }) => theme.colors.grey500};
                background-position: '';
            }
        }
        ${ListItem} > span {
            color: ${({ theme }) => theme.colors.black};
            font-size: 12px;
            line-height: 16px;
            ${({ dtype }) =>
                dtype === 'icon'
                    ? 'word-wrap: break-word;'
                    : 'white-space: nowrap;'}
        }
        ${ListItem} > a {
            gap: 5px;
            text-decoration: none;
            span {
                color: ${({ theme }) => theme.colors.black};
                font-size: 13px;
                line-height: 18px;
                ${({ dtype }) =>
                    dtype === 'icon'
                        ? 'word-wrap: break-word;'
                        : 'white-space: nowrap;'}
            }
            img {
                height: 16px;
                width: auto;
            }
        }
    }
`;

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listOpen: false,
            headerTitle: this.props.title,
        };

        this.toggleList = this.toggleList.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                listOpen: false,
            });
        }
    }

    toggleList(openState = null) {
        if (openState !== null) {
            this.setState({
                listOpen: openState,
            });
        } else {
            this.setState((prevState) => ({
                listOpen: !prevState.listOpen,
            }));
        }
    }

    toggleSelected(item, onclickFunction, itemData, state) {
        if (onclickFunction) onclickFunction(item, itemData, state);
        this.setState(() => ({
            headerTitle: item.title,
        }));
    }

    render() {
        const { list, onTitleClick, hoverToOpen } = this.props;
        const { listOpen } = this.state;

        return (
            <DropdownStyle
                style={this.props.style}
                onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick();
                    }
                    if (!this.props.isDropdownDisabled && !hoverToOpen) {
                        this.toggleList();
                    }
                }}
                onMouseEnter={() => {
                    if (hoverToOpen) {
                        this.toggleList(true);
                    }
                }}
                onMouseLeave={() => {
                    if (hoverToOpen) {
                        this.toggleList(false);
                    }
                }}
                hoverToOpen={hoverToOpen}
                dtype={this.props.dtype}
                withBorder={this.props.withBorder}
                ref={this.setWrapperRef}
                badge={this.props.badge}
                color={this.props.color}
                open={this.props.open}
                img={this.props.img}
                isDropdownDisabled={this.props.isDropdownDisabled}
                toggleIcon={this.props.toggleIcon}
                light={this.props.light}
            >
                {this.props.children}
                <BadgeIcon badge={this.props.badge} />
                {this.props.title
                    ? [
                          (() => {
                              if (this.props.img) {
                                  return (
                                      <HeaderImg
                                          img={this.props.img}
                                          key="img"
                                      />
                                  );
                              }
                              return null;
                          })(),
                          <DropdownTitle
                              onClick={onTitleClick}
                              key="title"
                              color={this.props.color}
                          >
                              {this.props.title}
                          </DropdownTitle>,
                      ]
                    : (() => {
                          if (this.props.toggleIcon) {
                              return (
                                  <img
                                      alt=""
                                      key="icnup"
                                      className={(() => {
                                          if (this.props.className) {
                                              if (listOpen) {
                                                  return `${this.props.className} dropdown-open`;
                                              }
                                              return this.props.className;
                                          }
                                          return '';
                                      })()}
                                      src={this.props.toggleIcon}
                                      color={this.props.color}
                                      size="sm"
                                  />
                              );
                          }
                          return '';
                      })()}
                {this.props.dtype === 'img'
                    ? (() => {
                          if (this.props.img) {
                              return (
                                  <img alt="" src={this.props.img} key="img" />
                              );
                          }
                          return <Account />;
                      })()
                    : ''}
                {this.props.hoverToOpen ? '' : <DropDownDownArrow />}
                {listOpen && list.length > 0 && (
                    <DropDownContainer
                        dtype={this.props.dtype}
                        img={this.props.img}
                        open={this.props.open}
                        icon={this.props.icon}
                    >
                        {list.map((item) =>
                            item.link ? (
                                <ListItem
                                    dtype={this.props.dtype}
                                    key={item.id}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.toggleSelected(item);
                                    }}
                                >
                                    <Link
                                        to={{
                                            pathname: item.link,
                                            state: item.linkState,
                                        }}
                                    >
                                        {item.icon ? (
                                            <img
                                                alt=""
                                                className="dropdown-icon"
                                                src={`misc/icons/${item.icon}.svg`}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        <span
                                            style={{
                                                color: item.danger
                                                    ? this.props.theme.colors
                                                          .red200
                                                    : '',
                                            }}
                                        >
                                            {item.title}
                                        </span>
                                    </Link>
                                </ListItem>
                            ) : (
                                <ListItem
                                    dtype={this.props.dtype}
                                    key={item.id}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.toggleSelected.call(
                                            this,
                                            item,
                                            item.toggleItem,
                                            this.props.toggleData,
                                            this.props.toggleState
                                        );
                                    }}
                                >
                                    {item.icon ? (
                                        <img
                                            alt=""
                                            className="dropdown-icon"
                                            src={`misc/icons/${item.icon}.svg`}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    <span
                                        style={{
                                            color: item.danger
                                                ? this.props.theme.colors.red200
                                                : '',
                                        }}
                                    >
                                        {item.title}
                                    </span>
                                </ListItem>
                            )
                        )}
                    </DropDownContainer>
                )}
            </DropdownStyle>
        );
    }
}
Dropdown.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    list: PropTypes.arrayOf(PropTypes.any),
    /** you don't need to pass title prop incase of icon dropdown */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**  url of the icon if you want icon with title */
    img: PropTypes.string,
    /** Icon Drop down won't change show or change title on any selection,
     * you will have to add icon markup inside dropdown */
    dtype: PropTypes.oneOf(['icon', 'text', 'button', 'img']),
    /** badge is only for dropdown type icon, it will show a red badge */
    badge: PropTypes.bool,
    /** color code, applies on dropdown title and arrow */
    color: PropTypes.string,
    /** Specify the position of dropdown of screen when it's open */
    open: PropTypes.oneOf(['left', 'right', 'top']),
    withBorder: PropTypes.bool,

    /** callback on dropdown clicked */
    toggleData: PropTypes.shape({}),
    toggleState: PropTypes.shape({}),
    style: PropTypes.shape({}),
    isDropdownDisabled: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.string,
    light: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            red200: PropTypes.string,
        }),
    }),
    toggleIcon: PropTypes.string,
    hoverToOpen: PropTypes.bool,
    onTitleClick: PropTypes.func,
};

Dropdown.defaultProps = {
    dtype: 'text',
    open: 'right',
    withBorder: true,
    style: {},
    hoverToOpen: false,
};
export default withTheme(Dropdown);
