import { getPlusTier } from 'constants/priceTiers';
import { getFrontendLanguage } from 'helpers/LanguageHelper';
import { getSendifyPlusHelpCenterLink } from 'helpers/LinkHelper';
import { useTeam } from 'hooks/Queries';
import { useTrialContext } from 'hooks/TrialProvider/TrialProvider';
import { TrialInfo } from 'hooks/usePriceTierTrial';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import InfoBanner from './InfoBanner';
import { InfoText } from './StyledInfoBanner';

interface Props {
    trialInfo: TrialInfo;
}

const PlusTrialBanner = (props: Props) => {
    const { trialInfo } = props;
    const { t } = useTranslation('dashboard');
    const { team } = useTeam();
    const lang = getFrontendLanguage();

    const { hasSeenModal, showTrialBanner, closeTrialBanner } =
        useTrialContext();

    const { push } = useHistory();
    // Show the payment modal if the trial has ended. German teams are not yet
    // onboarded on this flow, so show it for Swedish teams only via LD.
    useEffect(() => {
        if (!team) return;
        const tier = getPlusTier(team?.systemCountry);
        if (trialInfo?.status === 'ended' && !hasSeenModal) {
            push(`/settings/price-tier?tier=${tier}`);
        }
    }, [trialInfo, hasSeenModal, team]);

    if (!lang || !team) {
        return null;
    }

    const tier = getPlusTier(team?.systemCountry);

    return (
        <InfoBanner show={showTrialBanner} onClose={closeTrialBanner}>
            <InfoText>
                <Trans
                    t={t}
                    i18nKey={`dashboard.trialBanner.${trialInfo.status}.subtitle`}
                >
                    <SendifyPlusLink
                        href={getSendifyPlusHelpCenterLink(lang)}
                        id="trial-banner-helpcenter"
                    />

                    <StyledReactLink
                        to={{
                            pathname: '/settings/price-tier',
                            search: `?tier=${tier}`,
                        }}
                        id="trial-banner-upgrade"
                    />
                </Trans>
            </InfoText>
        </InfoBanner>
    );
};

export default PlusTrialBanner;

const StyledReactLink = styled(Link)`
    color: black;
    text-decoration: underline;
`;

const SendifyPlusLink = styled.a`
    background-color: ${({ theme }) => theme.colors.cardboardBrown300};
    color: black;
    padding: ${({ theme }) => theme.space.s1};
    text-decoration: underline;
`;
