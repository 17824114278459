import {
    LinkButton,
    TwoColumnInput,
    TwoColumnNotification,
} from 'components/NewShipmentForm/components/AddressForm/StyledAddressForm';
import Text from 'components/Typography/Text';
import { useTeam } from 'hooks/Queries';
import { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
    prefix: string;
}

const AdditionalAddressLines = ({ prefix }: Props) => {
    const [additionalAddressLines, setAdditionalAddressLines] = useState(false);
    const { errors, setValue, control } = useFormContext();
    const hideAdditionalAddressLines = () => {
        setValue(`${prefix}.addressLine2`, '');
        setValue(`${prefix}.addressLine3`, '');
        setAdditionalAddressLines(false);
    };

    const addressLine2 = useWatch<string>({
        control,
        name: `${prefix}.addressLine2`,
        defaultValue: '',
    });

    const addressLine3 = useWatch<string>({
        control,
        name: `${prefix}.addressLine3`,
        defaultValue: '',
    });

    const { team } = useTeam();
    const { t } = useTranslation(['address']);
    useEffect(() => {
        if (!additionalAddressLines) {
            setAdditionalAddressLines(!!addressLine2 || !!addressLine3);
        }
        // Only run on actual address line change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressLine2, addressLine3]);

    const additionalFieldWarningText =
        team?.systemCountry === 'SE'
            ? t('addressLineNote.SE')
            : t('addressLineNote.DE');

    return (
        <>
            {additionalAddressLines && (
                <LinkButton type="button" onClick={hideAdditionalAddressLines}>
                    {`- ${t('button.hideExtraAddressLines')}`}
                </LinkButton>
            )}

            <Controller
                control={control}
                name={`${prefix}.addressLine2`}
                defaultValue=""
                render={({ onChange, value }) =>
                    additionalAddressLines ? (
                        <TwoColumnInput
                            id={`${prefix}-input-addressline2`}
                            data-testid="addressLine2"
                            type="text"
                            label={t('address:addressLine2')}
                            error={!!errors[prefix]?.addressLine2}
                            errorMessage={errors[prefix]?.addressLine2?.message}
                            onChange={onChange}
                            value={value}
                        />
                    ) : (
                        <></>
                    )
                }
            />

            <Controller
                control={control}
                name={`${prefix}.addressLine3`}
                defaultValue=""
                render={({ onChange, value }) =>
                    additionalAddressLines ? (
                        <>
                            <TwoColumnInput
                                id={`${prefix}-input-addressLine3`}
                                data-testid="addressLine3"
                                type="text"
                                label={t('address:addressLine3')}
                                error={!!errors[prefix]?.addressLine3}
                                errorMessage={
                                    errors[prefix]?.addressLine3?.message
                                }
                                onChange={onChange}
                                value={value}
                            />
                            <TwoColumnNotification variant="warning">
                                <Text>{additionalFieldWarningText}</Text>
                            </TwoColumnNotification>
                        </>
                    ) : (
                        <></>
                    )
                }
            />

            {!additionalAddressLines && (
                <LinkButton
                    type="button"
                    data-cy={`${prefix}-input-show-address-lines`}
                    onClick={() => setAdditionalAddressLines(true)}
                >
                    {`+ ${t('button.showExtraAddressLines')}`}
                </LinkButton>
            )}
        </>
    );
};

export default AdditionalAddressLines;
