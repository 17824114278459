/* eslint-disable react/jsx-props-no-spreading */
import * as Sentry from '@sentry/react';
import { getToken, isAuthenticated, parseToken } from 'api/auth';
import PageTracker from 'components/PageTracker';
import { launchdarklyAccountId } from 'config';
import ConfirmEmail from 'containers/ConfirmEmail';
import ErrorBoundary from 'containers/ErrorBoundary';
import NavBar from 'containers/NavBar';
import { BeamerProvider } from 'external/beamer';
import { isSupportedBrowser } from 'helpers/BrowserHelper';
import {
    SupportedCountries,
    getCountryFromDomain,
} from 'helpers/SystemCountryHelper';
import { WindowWidthProvider } from 'hooks/WindowWidth';
import { enableMapSet } from 'immer';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Suspense, lazy, useEffect } from 'react';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import { Slide } from 'react-toastify';
import styled, { ThemeProvider } from 'styled-components/macro';
import lightTheme from 'theme/lightTheme';
import 'whatwg-fetch';

import GlobalStyles from './components/Globals/GlobalStyles';
import DEPRECATEDFlexContainer from './components/Layout/DEPRECATEDFlexContainer';
import GoogleTranslateWarning from './components/Notifications/GoogleTranslateWarning';
import PrivateRoute from './components/PrivateRoute';
import Spinner, {
    SpinnerContainer,
    spinnerSize,
    spinnerWidth,
} from './components/Spinner/Spinner';
import Toast from './components/Toast';
import OldBrowserSpinner from './containers/ErrorPages/OldBrowserSpinner';
import OldBrowserWarning from './containers/ErrorPages/OldBrowserWarning';
import UserDataHelper from './helpers/UserDataHelper';
import useDetectGoogleTranslate from './hooks/DetectGoogleTranslate';
import './styles.css';

const Content = lazy(() => import('./containers/Content'));
const InviteSignup = lazy(() => import('./containers/InviteSignup'));
const LoginPage = lazy(() => import('./containers/Login'));
const ResetPassword = lazy(() => import('./containers/ResetPassword'));
const SearchSignUp = lazy(() => import('./containers/SearchSignUp'));
const StandardSignup = lazy(() =>
    import('./containers/Signup/Standard/StandardSignup')
);

const PartnershipSignup = lazy(() => import('containers/Signup/Partnership'));
const Tracking = lazy(() => import('./containers/Tracking'));
const ForgotPassword = lazy(() => import('./containers/ForgotPassword'));
const Maintenance = lazy(() => import('./containers/Maintenance'));
const Wrapper = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
`;

const AuthRedirectComponent = (Component) => {
    return isAuthenticated() ? <Redirect to="/dashboard" /> : <Component />;
};

const App = () => {
    // Disable Sentry if the user uses Google Translate.
    const isTranslated = useDetectGoogleTranslate();

    const isSwedish = getCountryFromDomain() === SupportedCountries.Sweden;
    useEffect(() => {
        Sentry.getCurrentHub().getClient().getOptions().enabled = !isTranslated;
    }, [isTranslated]);

    let isMaintenance = false;
    if (localStorage.getItem('maintenance') === 'false') {
        isMaintenance = false;
    } else if (process.env.REACT_APP_MAINTENANCE === 'true') {
        isMaintenance = true;
    } else if (
        process.env[
            `REACT_APP_MAINTENANCE_${UserDataHelper.getSystemCountry()}`
        ] === 'true'
    ) {
        isMaintenance = true;
    }

    const currentTheme = lightTheme;
    if (isMaintenance) {
        return (
            <ThemeProvider theme={lightTheme}>
                <WindowWidthProvider>
                    <Suspense
                        fallback={
                            <DEPRECATEDFlexContainer
                                minHeight="100vh"
                                fullWidth
                                fullHeight
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Spinner
                                    size={spinnerSize.large}
                                    width={spinnerWidth.large}
                                />
                            </DEPRECATEDFlexContainer>
                        }
                    >
                        <GlobalStyles />
                        <Maintenance />
                    </Suspense>
                </WindowWidthProvider>
            </ThemeProvider>
        );
    }
    if (!isSupportedBrowser()) {
        return (
            <>
                <ThemeProvider theme={currentTheme}>
                    <Suspense fallback={<OldBrowserSpinner />}>
                        <GlobalStyles />
                        <WindowWidthProvider>
                            <OldBrowserWarning />
                        </WindowWidthProvider>
                    </Suspense>
                </ThemeProvider>
            </>
        );
    }

    // Enable Immer's use of sets
    enableMapSet();

    return (
        <ThemeProvider theme={currentTheme}>
            <WindowWidthProvider>
                <BeamerProvider>
                    <Suspense
                        fallback={
                            <DEPRECATEDFlexContainer
                                minHeight="100vh"
                                fullWidth
                                fullHeight
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Spinner
                                    size={spinnerSize.large}
                                    width={spinnerWidth.large}
                                />
                            </DEPRECATEDFlexContainer>
                        }
                    >
                        <GlobalStyles />
                        <Router>
                            <ErrorBoundary>
                                <PageTracker>
                                    <Switch>
                                        <Route
                                            path="/login"
                                            component={(props) => (
                                                <LoginPage {...props} />
                                            )}
                                            exact
                                        />
                                        <Route path="/sign-up" exact>
                                            {AuthRedirectComponent(
                                                StandardSignup
                                            )}
                                        </Route>
                                        {isSwedish && (
                                            <Route
                                                path="/sign-up/foretagarna"
                                                exact
                                            >
                                                <Redirect to="/sign-up/partnership" />
                                            </Route>
                                        )}

                                        {isSwedish && (
                                            <Route
                                                path="/sign-up/partnership"
                                                exact
                                            >
                                                {AuthRedirectComponent(
                                                    PartnershipSignup
                                                )}
                                            </Route>
                                        )}
                                        <Route path="/search-sign-up" exact>
                                            {AuthRedirectComponent(
                                                SearchSignUp
                                            )}
                                        </Route>
                                        <Route path="/register/invite">
                                            <InviteSignup />
                                        </Route>
                                        <Route
                                            path="/forgot-password"
                                            component={(props) => (
                                                <ForgotPassword {...props} />
                                            )}
                                            exact
                                        />
                                        <Route
                                            path="/login/reset"
                                            component={(props) => (
                                                <ResetPassword {...props} />
                                            )}
                                        />
                                        <Route path="/activate">
                                            <ConfirmEmail />
                                        </Route>

                                        <Route
                                            path="/tracking/:consignmentHash"
                                            component={(props) => (
                                                <Tracking {...props} />
                                            )}
                                        />
                                        <Wrapper>
                                            <PrivateRoute>
                                                <NavBar />
                                                <Suspense
                                                    fallback={
                                                        <SpinnerContainer>
                                                            <Spinner
                                                                size={
                                                                    spinnerSize.large
                                                                }
                                                                width={
                                                                    spinnerWidth.large
                                                                }
                                                            />
                                                        </SpinnerContainer>
                                                    }
                                                >
                                                    <Content />
                                                </Suspense>
                                            </PrivateRoute>
                                        </Wrapper>
                                    </Switch>
                                    <GoogleTranslateWarning />
                                </PageTracker>
                            </ErrorBoundary>
                        </Router>
                        <Toast position="bottom-center" transition={Slide} />
                    </Suspense>
                </BeamerProvider>
            </WindowWidthProvider>
        </ThemeProvider>
    );
};

// Get the correct LD keys immediately, if the user is signed in.
// This is to prevent it from loading default flag values when the
// user is signed in, which otherwise can cause flickering.
// If the user is not signed in, we will use the default, anonymous values.
const ldConfig = {
    clientSideID: launchdarklyAccountId ?? '',
    context: {
        kind: 'user',
        country: getCountryFromDomain(),
        key: `anonymous_${getCountryFromDomain()}`,
    },
};

const token = getToken();
if (token) {
    try {
        const { sub, team_hash } = parseToken(token);
        ldConfig.context = {
            kind: 'user',
            key: sub,
            country: getCountryFromDomain(),
            teamId: team_hash,
        };
    } catch (e) {
        // Nothing to do, the user will be identified later using the
        // Identifier component.
    }
}

export default Sentry.withProfiler(withLDProvider(ldConfig)(App));
