import { CalendarClearOutline } from 'assets/icons';
import Input from 'components/FormElements/Input';
import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de';
import { english } from 'flatpickr/dist/l10n/default';
import { Swedish } from 'flatpickr/dist/l10n/sv';
import DateHelper from 'helpers/DateHelper';
import { getReadableDate } from 'helpers/TranslationHelper';
import useHolidays from 'hooks/Queries/useHolidays';
import { Alpha2Code } from 'i18n-iso-countries';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    defaultDate?: DateTime;
    onChange?: (date: DateTime) => void;
    isReadOnly?: boolean;
    countryCode?: Alpha2Code;
    state?: string;
    className?: string;
    error?: boolean;
    label?: string;
}

const PickupCalendar = ({
    countryCode,
    state,
    onChange,
    defaultDate,
    isReadOnly,
    className,
    error,
    label,
}: Props) => {
    const ref = useRef<HTMLInputElement>(null);
    const { t, i18n } = useTranslation();
    const { holidays } = useHolidays(countryCode, state);

    const isInvalid = (date: DateTime) =>
        !DateHelper.isValidPickupDate(date, holidays || []);

    const locale = useMemo(() => {
        switch (i18n.languages[0]) {
            case 'sv':
                return {
                    ...Swedish,
                    // This range separator can be removed when this is merged
                    // https://github.com/flatpickr/flatpickr/pull/2434
                    rangeSeparator: ' till ',
                };
            case 'en':
                return english;
            case 'de':
                return German;
            default:
                return Swedish;
        }
    }, [i18n.languages]);

    const getDateFormat = (date: DateTime) => {
        // We show just the date and month if it's either the current year or
        // within the near future.
        if (
            date.year === DateTime.now().year ||
            date.diffNow('months').months < 1
        ) {
            return { month: 'long', day: 'numeric' } as DateTimeFormatOptions;
        }
        return DateTime.DATE_MED;
    };

    useEffect(() => {
        /**
         * Initialize flatpickr if component has mounted (ref.current exists) and the input is not read only.
         * If the input is read only we don't need a calendar.
         */
        let instance: flatpickr.Instance;
        if (ref.current && !isReadOnly) {
            instance = flatpickr(ref.current, {
                locale,
                defaultDate: defaultDate?.toJSDate(),
                minDate: 'today',
                monthSelectorType: 'static',
                // We only take the first element from the list since we only allow to selec one date
                onChange: ([newDate]) => {
                    if (onChange) {
                        // {hour: 8} is based on that we send in 8:00 as the default time in e.g. NewShipment.
                        onChange(DateTime.fromJSDate(newDate).set({ hour: 8 }));
                    }
                },
                formatDate: (date) =>
                    getReadableDate(
                        t,
                        DateTime.fromJSDate(date),
                        getDateFormat(DateTime.fromJSDate(date))
                    ),
                disable: [(date) => isInvalid(DateTime.fromJSDate(date))],
            });
        }
        return () => {
            instance?.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, locale, holidays, defaultDate]);

    return (
        <Input
            label={label}
            error={error}
            className={className}
            readOnly={isReadOnly}
            prefix={<CalendarClearOutline height="20" />}
            ref={ref}
            value={
                defaultDate
                    ? getReadableDate(
                          t,
                          defaultDate,
                          getDateFormat(defaultDate)
                      )
                    : ''
            }
        />
    );
};

export default PickupCalendar;
