/* eslint-disable max-len */
import * as Sentry from '@sentry/react';
import { CourierCode } from 'api/consignments/types';
import UserDataHelper from 'helpers/UserDataHelper';

import { getFrontendLanguage } from './LanguageHelper';
import { getCountryFromDomain } from './SystemCountryHelper';

export const getHelpCenterLink = () => {
    const country = UserDataHelper.getSystemCountry();
    switch (country) {
        case 'DE':
            return 'https://help.sendify.com/de';
        case 'SE':
            return 'https://help.sendify.com/sv';
        default:
            return 'https://help.sendify.com/sv';
    }
};

export const getMarketingSiteLink = () => {
    const country = getCountryFromDomain();
    switch (country) {
        case 'SE':
            return 'https://sendify.se';
        case 'DE':
            return 'https://sendify.de';
        default:
            return 'https://sendify.se';
    }
};

export const getDsvInsuranceLink = () => {
    return 'https://www.dsv.com/de-de/our-solutions/value-added-services/cargo-insurance';
};

// Only Swedish link since insurance is only offered in sweden
export const getSendifyTntLink = () => {
    return 'https://www.tnt.com/express/sv_se/site/how-to/understand-surcharges/international-additional-services-surcharges.html#forsakring';
};

// Only Swedish link since insurance is only offered in sweden
export const getDhlInsuranceLink = () => {
    return 'https://www.dhl.com/content/dam/dhl/local/se/dhl-freight/documents/pdf/sv/se-freight-insurance-sv.pdf';
};

export const getInsuranceInfoLink = () => {
    const country = UserDataHelper.getSystemCountry();
    switch (country) {
        case 'DE':
            return 'https://help.sendify.com/de/warum-sollte-ich-meine-sendung-versichern';
        case 'SE':
            return 'https://help.sendify.com/sv/hur-och-varf%C3%B6r-ska-jag-f%C3%B6rs%C3%A4kra-mitt-gods';
        default:
            return 'https://help.sendify.com/sv/hur-och-varf%C3%B6r-ska-jag-f%C3%B6rs%C3%A4kra-mitt-gods';
    }
};

export const getFortnoxInfoLink = () => {
    return 'https://help.sendify.se/sv/articles/5664029-koppla-ihop-fortnox-med-sendify';
};

export const getSendifyPartnersLink = () => {
    return 'https://www.sendify.se/sv/partners';
};

export const getPrivacyPolicyLink = () => {
    // On signup we don't have a stored system country, so we use the domain.
    const country = UserDataHelper.getSystemCountry() || getCountryFromDomain();
    switch (country) {
        case 'DE':
            return 'https://sendify.de/datenschutzerklaerung/';
        case 'SE':
            return 'https://www.sendify.se/sv/personuppgiftspolicy/';
        default:
            return 'https://www.sendify.se/sv/personuppgiftspolicy/';
    }
};

export const getAgreementLink = () => {
    // On signup we don't have a stored system country, so we use the domain.
    const country = UserDataHelper.getSystemCountry() || getCountryFromDomain();
    switch (country) {
        case 'DE':
            return 'https://sendify.de/nutzungsbedingungen/';
        case 'SE':
            return 'https://www.sendify.se/sv/avtal/';
        default:
            return 'https://www.sendify.se/sv/avtal/';
    }
};

export const getEoriLink = () => {
    const country = UserDataHelper.getSystemCountry();
    const language = getFrontendLanguage();
    switch (country) {
        case 'DE':
            return 'https://www.zoll.de/DE/Fachthemen/Zoelle/EORI-Nummer/eori-nummer_node.html';
        case 'SE':
            return language === 'sv'
                ? 'https://www.tullverket.se/sv/foretag/tillstandochregistreringar/eori.4.6ac2c843157b7beb007364.html'
                : 'https://www.tullverket.se/eng/business/eorieconomicoperatorregistrationandidentification/applicationforeorinumber.4.7df61c5915510cfe9e76040.html';
        default:
            return 'https://www.tullverket.se/eng/business/eorieconomicoperatorregistrationandidentification/applicationforeorinumber.4.7df61c5915510cfe9e76040.html';
    }
};

export const getHsCodeLink = () => {
    const language = getFrontendLanguage();
    switch (language) {
        case 'de':
            return 'https://www.zolltarifnummern.de/';
        default:
            return 'https://tariffnumber.com';
    }
};

export const getEoriValidateLink = () => {
    const country = UserDataHelper.getSystemCountry();
    if (country === 'DE') {
        return 'https://ec.europa.eu/taxation_customs/dds2/eos/eori_validation.jsp';
    }
    return undefined;
};

export const getCustomsEmail = () => {
    const country = UserDataHelper.getSystemCountry();
    switch (country) {
        case 'DE':
            return 'zoll@sendify.de';
        case 'SE':
            return 'tull@sendify.se';
        default:
            return 'tull@sendify.se';
    }
};

export const getCustomsGuide = () => {
    const country = UserDataHelper.getSystemCountry();
    switch (country) {
        case 'SE':
            return 'https://help.sendify.com/sv/fem-tips-f%C3%B6r-att-komma-ig%C3%A5ng-med-tull';
        case 'DE':
            return 'https://help.sendify.com/de/checkliste-fuer-ihre-erste-zoll-erfahrung';
    }
};

export const getDataPolicy = () => {
    // On signup we don't have a stored system country, so we use the domain.
    const country = UserDataHelper.getSystemCountry() || getCountryFromDomain();
    switch (country) {
        case 'DE':
            return 'https://sendify-public.s3-eu-west-1.amazonaws.com/Auftragsverarbeitung.pdf';
        case 'SE':
            return 'https://sendify-public.s3-eu-west-1.amazonaws.com/Personuppgiftsbitra%CC%88desavtal.pdf';
        default:
            return 'https://sendify-public.s3-eu-west-1.amazonaws.com/Personuppgiftsbitra%CC%88desavtal.pdf';
    }
};

const getDhlTerms = () => {
    return 'https://www.dhl.com/content/dam/dhl/local/se/dhl-freight/documents/pdf/sv/se-freight-general-terms-and-conditions-sv.pdf';
};

const getDhlFreightGermanyTerms = () => {
    return 'https://www.dhl.com/content/dam/dhl/local/de/dhl-freight/documents/pdf/de-freight-general-terms-and-conditions-for-offered-services-en.pdf';
};

const getUpsTerms = () => {
    const country = UserDataHelper.getSystemCountry();
    switch (country) {
        case 'DE':
            return 'https://www.ups.com/assets/resources/webcontent/de_DE/terms_carriage_de.pdf';
        case 'SE':
            return 'https://www.ups.com/se/sv/support/shipping-support/legal-terms-conditions.page';
        default:
            return 'https://www.ups.com/se/sv/support/shipping-support/legal-terms-conditions.page';
    }
};
const getDsvTerms = () => {
    const country = UserDataHelper.getSystemCountry();
    switch (country) {
        case 'DE':
            return 'https://www.dsv.com/de-de/ueber-dsv/terms-and-conditions';
        case 'SE':
            return 'https://www.dsv.com/sv-se/om-dsv/villkor-och-ansvar/transportvillkor';
        default:
            return 'https://www.dsv.com/sv-se/om-dsv/villkor-och-ansvar/transportvillkor';
    }
};

const getNtexSwedenTerms = () => {
    return 'https://www.ntex.se/certifikat-och-reklamation';
};

const getTntTerms = () => {
    return 'https://help.sendify.com/sv/tnt-villkor';
};

const getDPDTerms = () => {
    return 'https://www.dpd.com/de/de/agb/';
};

const getFedExTerms = () => {
    return 'https://www.fedex.com/se-se/conditions-of-carriage.html';
};

export const getCarrierTerms = (carrier: CourierCode) => {
    switch (carrier) {
        case CourierCode.DHL_FREIGHT_SWEDEN:
            return getDhlTerms();
        case CourierCode.DSV:
        case CourierCode.DSV_SWEDEN:
        case CourierCode.DSV_GERMANY:
            return getDsvTerms();
        case CourierCode.UPS:
        case CourierCode.UPS_SWEDEN:
        case CourierCode.UPS_GERMANY:
            return getUpsTerms();
        case CourierCode.TNT:
        case CourierCode.TNT_SWEDEN:
            return getTntTerms();
        case CourierCode.DPD_GERMANY:
            return getDPDTerms();
        case CourierCode.DHL_FREIGHT_GERMANY:
            return getDhlFreightGermanyTerms();
        case CourierCode.FEDEX:
        case CourierCode.FEDEX_GLOBAL:
            return getFedExTerms();
        case CourierCode.NTEX_SWEDEN:
            return getNtexSwedenTerms();
        default:
            Sentry.captureException(new Error('missing carrier terms'), {
                tags: {
                    carrier,
                },
            });
            return '#';
    }
};

export const getIntegrationDemoLink = () => {
    return 'https://help.sendify.com/meetings/sendify/integration-demo';
};
const dhlLink =
    'https://activetracing.dhl.com/DatPublic/datSelection.do?search=consignmentId&focus=search2&at=cons_ordercode&valueShipmentOrderField=';
const upsLink = 'https://www.ups.com/track?tracknum=';
const fedexLink = 'https://www.fedex.com/wtrk/track/?trknbr=';
const tntLink =
    'https://www.tnt.com/express/sv_se/site/shipping-tools/tracking.html?searchType=con&cons=';
const dsvLink = 'https://mydsv.com/shipment-details-public?bookingId=';
const dpdLink = 'https://my.dpd.de/myParcel.aspx';

export const getTrackingLink = (trackingId: string, courier?: CourierCode) => {
    switch (courier) {
        case CourierCode.DHL_FREIGHT_GERMANY:
        case CourierCode.DHL_FREIGHT_SWEDEN:
            return `${dhlLink}${trackingId}`;
        case CourierCode.DSV:
        case CourierCode.DSV_GERMANY:
        case CourierCode.DSV_SWEDEN:
            return `${dsvLink}${trackingId}`;
        case CourierCode.TNT:
            return `${tntLink}${trackingId}`;
        case CourierCode.UPS:
        case CourierCode.UPS_SWEDEN:
        case CourierCode.UPS_GERMANY:
            return `${upsLink}${trackingId}`;
        case CourierCode.DPD_GERMANY:
            return `${dpdLink}`;
        case CourierCode.FEDEX:
        case CourierCode.FEDEX_GLOBAL:
            return `${fedexLink}${trackingId}`;
        default:
            return undefined;
    }
};

export const getPreServiceLink = (service: 'pre7' | 'pre10' | 'pre12') => {
    switch (service) {
        case 'pre7':
            return 'https://sendify-public.s3.eu-west-1.amazonaws.com/se-freight-pre7-label.pdf';
        case 'pre10':
            return 'https://sendify-public.s3.eu-west-1.amazonaws.com/se-freight-pre10-label.pdf';
        case 'pre12':
            return 'https://sendify-public.s3.eu-west-1.amazonaws.com/se-freight-pre12-label.pdf';
        default:
            return undefined;
    }
};

export const getInsuranceTermsLink = () => {
    const country = UserDataHelper.getSystemCountry();
    switch (country) {
        case 'DE':
            return 'https://hub.sendify.com/hubfs/Carriers%20Terms%20and%20Conditions/Germany/Insurance/Versicherungsbedingungen.pdf';
        case 'SE':
            return 'https://help.sendify.com/sv/sendify-gruppforsakring';
        default:
            return 'https://help.sendify.com/sv/sendify-gruppforsakring';
    }
};

export const getSendifyPlusHelpCenterLink = (lang: string) => {
    return `https://help.sendify.com/${lang}/sendify-plus`;
};
