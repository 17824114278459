import { QueryClient } from '@tanstack/react-query';

// react-query setup
// We don't want any logs showing up, as they clutter the terminal
const noop = () => null;
export const queryClient = new QueryClient({
    logger: {
        error: noop,
        log: noop,
        warn: noop,
    },
    defaultOptions: {
        queries: {
            /**
             * Average session time on web app is around 8.5 minutes
             * Assume we don't need to update the data within this time, by default.
             **/
            staleTime: 1000 * 60 * 8.5,
            /**
             * Based on the average session we can extend the cache time to 10 minutes
             * This ensures that the data is kept in cache longer than the average session duration.
             **/
            cacheTime: 1000 * 60 * 10,
            /**
             * Leaving this on `true` causes the queries to refetch on every LaunchDarkly flag change,
             * which leads to our database being overloaded when multiple clients are open. We thus
             * set it to `false`.
             */
            refetchOnMount: false,
        },
    },
});
