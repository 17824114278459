import { BadgeVariant } from 'components/Badge/Badge';
import Caption from 'components/Typography/Caption';
import Text from 'components/Typography/Text';
import styled, { css } from 'styled-components/macro';

const BadgeStyles = css<{
    variant?: BadgeVariant;
    divider?: boolean;
}>`
    background: ${({ theme, variant }) => {
        switch (variant) {
            case 'success':
                return theme.colors.green300;
            case 'brand':
                return theme.colors.tapeBeige;
            case 'brandSecondary':
                return theme.colors.vinylGreen200;
            case 'beta':
                return theme.colors.shrinkwrapPurple100;
            case 'secondary':
                return theme.colors.blue300;
            case 'danger':
                return theme.colors.red300;
            case 'primary':
                return theme.colors.black;
            default:
                return theme.colors.black;
        }
    }};
    color: ${({ theme, variant }) => {
        switch (variant) {
            case 'success':
                return theme.colors.green100;
            case 'brand':
                return theme.colors.black;
            case 'brandSecondary':
                return theme.colors.black;
            case 'beta':
                return theme.colors.black;
            case 'secondary':
                return theme.colors.blue100;
            case 'danger':
                return theme.colors.red100;
            case 'primary':
                return theme.colors.white;
            default:
                return theme.colors.white;
        }
    }};
    display: flex;
    gap: ${({ theme }) => theme.space.s1};
    width: max-content;
    ${({ divider }) => {
        if (divider) {
            return css`
                display: inline-block;
                overflow: unset;
                position: relative;

                ::after,
                ::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    width: 50vw;
                    height: 1px;
                    background: ${({ theme }) => theme.colors.grey300};
                }

                ::before {
                    right: 100%;
                    margin-right: ${({ theme }) => theme.space.s2};
                }

                :after {
                    left: 100%;
                    margin-left: ${({ theme }) => theme.space.s2};
                }
            `;
        }
    }};

    &:hover {
        cursor: default;
    }
`;
export const StyledTextBadge = styled(Text)<{
    variant?: BadgeVariant;
    divider?: boolean;
}>`
    ${BadgeStyles};
    padding: ${({ theme }) => `${theme.space.s1} ${theme.space.s2}`};
    width: max-content !important;
`;
export const StyledCaptionBadge = styled(Caption)<{
    variant?: BadgeVariant;
    divider?: boolean;
}>`
    ${BadgeStyles};
    padding: ${({ theme }) => `${theme.space.s1} ${theme.space.s2}`};
`;
export const BadgeWrapper = styled.div<{ divider?: boolean }>`
    ${({ divider }) => {
        if (divider) {
            return css`
                display: block;
                overflow: hidden;
                text-align: center;
                white-space: nowrap;
            `;
        }
    }}
`;
