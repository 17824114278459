import { Team } from 'api/teams/types';
import { User } from 'api/users/types';
import { GTMParameters } from 'external/gtm/types';
import { sha256 } from 'js-sha256';

// get GTM hashed personal identifier
const getHash = (s: string) => {
    const hash = sha256.create();
    hash.update(s);
    return hash.hex();
};

/**
 * Generate a unique transaction ID for the user on seconds granularity.
 * Should be used when no transaction ID can be provided easily.
 * @param userId - The user ID of the current user
 */
const generateTransactionID = (userId: number) => {
    return `${userId}-${Math.floor(Date.now() / 1000)}`;
};

/**
 * Get the GTM parameters for a specific event.
 * If a transaction ID can be provided, it should be passed as well.
 * Will hash all personal identifiers before sending them to GTM.
 * @param user
 * @param team
 * @param transactionId
 * @param extendedParams
 */
export const getGTMParameters = (
    user: User,
    team: Team,
    transactionId?: string
): GTMParameters => {
    const teamAddress = team.invoiceAddress || team.homeAddress;

    return {
        sha256_email: getHash(user.email),
        em: getHash(user.email),
        ph: getHash(user.mobileNumber),
        fn: getHash(user.firstName),
        ln: getHash(user.lastName || ''),
        country: getHash(teamAddress.countryCode),
        zp: getHash(teamAddress.postalCode || ''),
        user_agent: navigator.userAgent,
        transaction_id: transactionId ?? generateTransactionID(user.id),
        team_id: team.id,
        sendify_user_id: user.id.toString(),
    };
};
