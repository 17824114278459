import { Colors } from 'styled-components/macro';

const colors: Colors = {
    black: '#000000',
    grey100: '#717171',
    grey200: '#A3A3A3',
    grey300: '#DADADA',
    grey400: '#E9E9E9',
    grey500: '#F6F6F6',
    white: '#FFFFFF',

    cardboardBrown100: '#fae0b1',
    cardboardBrown200: '#e8d5c2',
    cardboardBrown300: '#dab99b',
    cardboardBrown400: '#d8a15c',

    vinylGreen100: '#586836',
    vinylGreen200: '#c3d0ab',
    vinylGreen300: '#f2f5eb',

    shrinkwrapPurple100: '#E2D6EB',
    shrinkwrapPurple300: '#F5F1F8',
    tapeBeige: '#faeed8',

    brown100: '#BD834C',
    brown200: '#DAB99B',
    brown300: '#FBF0E5',

    blue100: '#193b61',
    blue200: '#2e6cb5',
    blue250: '#EFF4FB',
    blue300: '#E3F3FB',

    yellow100: '#524400',
    yellow200: '#ffdb1e',
    yellow300: '#fff8d6',

    green100: '#2a5c1e',
    green200: '#65c64d',
    green300: '#e4f5e0',

    red100: '#A30000',
    red200: '#FF1E1E',
    red300: '#FFEBEB',
};
export default colors;
