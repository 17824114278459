import {
    MutableRefObject,
    ReactElement,
    RefAttributes,
    forwardRef,
    useId,
} from 'react';
import type { GroupBase, Props, SelectInstance } from 'react-select';
import Creatable from 'react-select/creatable';

import Wrapper from './Wrapper';
import useSelectProps from './useSelectProps';

export type SelectComponent = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: Props<Option, IsMulti, Group> &
        RefAttributes<SelectInstance<Option, IsMulti, Group>>
) => ReactElement;

/**
 * A component for selecting one or more options from a dropdown, with the
 * ability to create new options.
 */
const CreatableSelect = forwardRef(
    <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
        props: Props<Option, IsMulti, Group>,
        ref:
            | ((
                  instance: SelectInstance<Option, IsMulti, Group> | null
              ) => void)
            | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
            | null
    ) => {
        const selectProps = useSelectProps(props);
        const { inputId: originalId } = selectProps;
        /* 
        We always need an ID if we have a label, for label click etc to work. 
        If the developer doesn't supply an ID, generate a unique one.
        */
        const generatedId = useId();
        const inputId = originalId ?? generatedId;

        return (
            <Wrapper
                {...selectProps}
                className={props.className}
                inputId={inputId}
            >
                <Creatable
                    ref={ref}
                    {...selectProps}
                    inputId={inputId}
                    // Override the default "Create {value}" label with just the value,
                    // to make it look like they are selecting an existing option.
                    formatCreateLabel={(value) => value}
                />
            </Wrapper>
        );
    }
) as SelectComponent;

export default CreatableSelect;
