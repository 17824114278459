// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components/macro';

import Link from '../Typography/Link';

export const StyledTooltipContainer = styled.div<{ type: 'plain' | 'rich' }>`
    div[role='tooltip'] {
        background: ${({ theme }) => theme.colors.black};
        border-radius: 0;
        max-width: 375px;
        white-space: pre-line; // Allows for new lines in the tooltip
        z-index: ${({ theme }) => theme.zIndex.tooltip} !important;
        ${({ type, theme }) => {
            switch (type) {
                case 'plain':
                    return css`
                        padding: ${theme.space.s1} ${theme.space.s2};
                    `;
                case 'rich':
                    return css`
                        padding: ${theme.space.s3} ${theme.space.s4};
                    `;
            }
        }}
    }
`;
export const TooltipContent = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s3};
`;
export const TooltipBody = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s1};
    a {
        color: ${({ theme }) => theme.colors.white};
        font-weight: 500;
        text-decoration: underline;
    }
`;
export const TooltipLink = styled(Link)`
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    text-decoration: underline;
`;
