/* eslint-disable react-hooks/rules-of-hooks */
import {
    CloseButton,
    ModalBackground,
    ModalContainer,
    ModalContentWrapper,
} from 'components/Modal/StyledModal';
import Spinner, { spinnerSize, spinnerWidth } from 'components/Spinner/Spinner';
import { ReactNode, Suspense, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { Close } from '../../assets/icons';

interface Props {
    children?: ReactNode;
    showModal: boolean;
    closeOnOverlayClick?: boolean;
    onClose?: () => void;
    width?: string;
    warning?: boolean;
    className?: string;
}

const Modal = ({
    showModal,
    onClose,
    warning,
    width = '760px',
    closeOnOverlayClick = true,
    children,
    className,
}: Props) => {
    //  disable scrolling on the content behind the modal when open
    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showModal]);
    const [container] = useState(() => {
        // This will be executed only on the initial render
        // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
        return document.createElement('div');
    });

    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, []);
    if (!showModal) {
        return null;
    }
    return createPortal(
        <Suspense
            fallback={
                <ModalBackground>
                    <Spinner
                        size={spinnerSize.large}
                        width={spinnerWidth.large}
                    />
                </ModalBackground>
            }
        >
            <ModalBackground
                onClick={(e) => {
                    if (onClose && closeOnOverlayClick) {
                        onClose();
                    }
                    e.stopPropagation();
                }}
            >
                <ModalContainer
                    className={className}
                    width={width}
                    onClick={(e) => e.stopPropagation()}
                >
                    {onClose && (
                        <CloseButton onClick={onClose} icon={<Close />} />
                    )}
                    <ModalContentWrapper warning={warning}>
                        {children}
                    </ModalContentWrapper>
                </ModalContainer>
            </ModalBackground>
        </Suspense>,
        container
    );
};

export default Modal;
