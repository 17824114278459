import { CardElement, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import Divider from 'components/Divider';
import CountrySelect from 'components/FormElements/CountrySelect';
import ErrorMessage from 'components/FormElements/ErrorMessage';
import Input from 'components/FormElements/Input';
import { Option, Select } from 'components/FormElements/Select';
import Heading, { HeadingSize } from 'components/Typography/Heading';
import Text, { TextSize } from 'components/Typography/Text';
import { getStateOptions } from 'helpers/FormHelper';
import { useTeam, useUser } from 'hooks/Queries';
import { Alpha2Code } from 'i18n-iso-countries';
import { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components/macro';

import {
    AddressWrapper,
    CardInputWrapper,
    CardWrapper,
    FullWidthInput,
} from './StyledPaymentForm';
import { PaymentForm as Form } from './types';

const PaymentForm = () => {
    const { colors } = useContext(ThemeContext);

    const { black, grey100, red100 } = colors;
    const { team } = useTeam();
    const { user } = useUser();
    const { setValue, register, errors, control, clearErrors, setError } =
        useFormContext<Form>();
    const { t } = useTranslation('settings');
    const [stateList, setStateList] = useState<Option[]>([]);

    const countryCode = useWatch<Alpha2Code>({
        control,
        name: 'address.countryCode',
    });

    const stripe = useStripe();

    useEffect(() => {
        const updateStates = async (countryCode?: Alpha2Code) => {
            setStateList(await getStateOptions(countryCode));
        };
        updateStates(countryCode);
    }, [countryCode]);

    useEffect(() => {
        if (team) {
            const address = team.invoiceAddress
                ? team.invoiceAddress
                : team.homeAddress;
            setValue('address', address);
        }
    }, [team, setValue]);

    useEffect(() => {
        if (user) {
            setValue('email', user.email);
        }
    }, [user]);

    const onCardChange = (event: StripeCardElementChangeEvent) => {
        setError('cardComplete', { message: event.error?.message });
        setValue('cardComplete', event.complete);
    };

    return (
        <>
            <Divider />
            <Heading size={HeadingSize.SMALL}>
                {t('settings:api.invoiceAddress')}
            </Heading>
            <AddressWrapper>
                <FullWidthInput
                    name="address.addressLine1"
                    ref={register({
                        required: t('errors.required').toString(),
                    })}
                    type="text"
                    label={t('api.paymentAddressLine1')}
                    placeholder=""
                    error={!!errors?.address?.addressLine1}
                    errorMessage={errors?.address?.addressLine1?.message}
                />
                <Input
                    name="address.city"
                    ref={register({
                        required: t('errors.required').toString(),
                    })}
                    type="text"
                    label={t('api.paymentCity')}
                    placeholder=""
                    error={!!errors?.address?.city}
                    errorMessage={errors?.address?.city?.message}
                />
                <Input
                    name="address.postalCode"
                    ref={register({
                        required: t('errors.required').toString(),
                    })}
                    type="text"
                    label={t('api.paymentPostalCode')}
                    placeholder=""
                    error={!!errors?.address?.postalCode}
                    errorMessage={errors?.address?.postalCode?.message}
                />
                <Controller
                    control={control}
                    name="address.countryCode"
                    defaultValue={team?.invoiceAddress?.countryCode}
                    render={({ onChange, value }) => (
                        <CountrySelect
                            menuPortalTarget={document.body}
                            inputId="address-country"
                            countryCode={value}
                            placeholder={t('api.paymentCountryPlaceholder')}
                            label={t('api.paymentCountry')}
                            onValueChange={(option) => {
                                onChange(option);
                                setValue(`address.state`, '');
                                clearErrors(`address.postalCode`);
                            }}
                            error={!!errors?.address?.countryCode}
                            errorMessage={errors?.address?.countryCode?.message}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="address.state"
                    rules={{
                        required: {
                            value: stateList.length > 0,
                            message: t('errors.required').toString(),
                        },
                    }}
                    defaultValue={null}
                    render={({ onChange, value }) => (
                        <>
                            {stateList.length > 0 && (
                                <Select<Option>
                                    inputId="address-state"
                                    menuPortalTarget={document.body}
                                    value={
                                        stateList?.find(
                                            (state) => state?.value === value
                                        ) || null
                                    }
                                    options={stateList}
                                    placeholder={t(
                                        'api.paymentStatePlaceholder'
                                    )}
                                    label={t('api.paymentState')}
                                    onChange={(option) => {
                                        onChange(option?.value);
                                    }}
                                    error={!!errors?.address?.state}
                                    errorMessage={
                                        errors?.address?.state?.message
                                    }
                                />
                            )}
                        </>
                    )}
                />
            </AddressWrapper>
            <Divider />
            <Heading size={HeadingSize.SMALL}>
                {t('settings:api.cardDetails')}
            </Heading>
            <CardWrapper>
                <Input
                    name="name"
                    ref={register({
                        required: t('errors.required').toString(),
                    })}
                    type="text"
                    id="paymentName"
                    label={t('api.paymentName')}
                    placeholder=""
                    error={!!errors?.name}
                    errorMessage={errors?.name?.message}
                />
                <Controller
                    control={control}
                    name="cardComplete"
                    rules={{
                        required: t('errors.required').toString(),
                    }}
                    defaultValue={null}
                    render={() => (
                        <div>
                            <Text size={TextSize.MEDIUM}>
                                {t('settings:api.cardDetails')}
                            </Text>
                            <CardInputWrapper
                                hasLoaded={!!stripe}
                                invalid={!!errors?.cardComplete?.message}
                            >
                                <CardElement
                                    onChange={onCardChange}
                                    options={{
                                        iconStyle: 'solid',
                                        hidePostalCode: true,
                                        style: {
                                            base: {
                                                iconColor: black,
                                                color: black,
                                                fontWeight: 500,
                                                fontFamily:
                                                    'Circular, Segoe UI, Roboto, Open Sans, sans-serif',
                                                fontSize: '16px',
                                                ':-webkit-autofill': {
                                                    color: black,
                                                },
                                                '::placeholder': {
                                                    color: grey100,
                                                },
                                            },
                                            invalid: {
                                                iconColor: red100,
                                                color: red100,
                                            },
                                        },
                                    }}
                                />
                            </CardInputWrapper>
                            {!!errors?.cardComplete && (
                                <ErrorMessage>
                                    {errors?.cardComplete?.message}
                                </ErrorMessage>
                            )}
                        </div>
                    )}
                />
            </CardWrapper>
            <Divider />
            <Input
                name="email"
                ref={register({
                    required: t('errors.required').toString(),
                })}
                type="text"
                label={t('api.receiptEmail')}
                placeholder=""
                error={!!errors?.email}
                errorMessage={errors?.email?.message}
            />
        </>
    );
};

export default PaymentForm;
