import { GTMParameters } from './types';

export const trackGTMEvent = <T>(
    name: string,
    baseParams: GTMParameters,
    eventParams?: T
) => {
    const params = {
        ...baseParams,
        ...eventParams,
    };
    try {
        (window as any).dataLayer.push({
            event: name,
            ...params,
        });
    } catch (e) {
        // gtm is likely blocked by an adblocker
    }
};
