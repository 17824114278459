import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FavoritesResponse } from 'api/favorites/models';

import { addFavorite, deleteFavorite, getFavorites } from '../../api/favorites';

export const useFavorites = () => {
    const queryClient = useQueryClient();

    const favoritesQuery = useQuery(
        ['favorites'],
        async () => await getFavorites()
    );

    const addFavoriteMutation = useMutation(addFavorite, {
        onSuccess: () => {
            queryClient.invalidateQueries(['favorites']);
        },
    });

    const removeFavoriteMutation = useMutation(deleteFavorite, {
        onMutate: async (favoriteId: number) => {
            // Cancel any ongoing queries related to favorites
            await queryClient.cancelQueries(['favorites']);

            // Capture the current state of favorites
            const previousFavorites = queryClient.getQueryData<number[]>([
                'favorites',
            ]);

            // Optimistically update the cache
            queryClient.setQueryData(
                ['favorites'],
                (oldFavorites: FavoritesResponse[] | undefined) =>
                    oldFavorites?.filter((f) => f.id !== favoriteId)
            );

            // Return the previous state so we can roll back if needed
            return { previousFavorites };
        },
        onError: (error, favoriteId, context) => {
            // Revert the cache to the previous state if the mutation fails
            if (context?.previousFavorites) {
                queryClient.setQueryData(
                    ['favorites'],
                    context.previousFavorites
                );
            }
            Sentry.captureException(error);
        },
        onSettled: () => {
            // Refetch the favorites list to ensure it's up-to-date
            queryClient.invalidateQueries(['favorites']);
        },
    });

    return {
        ...favoritesQuery,
        addFavorite: addFavoriteMutation.mutateAsync,
        removeFavorite: removeFavoriteMutation.mutateAsync,
        isAddingFavorite: addFavoriteMutation.isLoading,
        isRemovingFavorite: removeFavoriteMutation.isLoading,
    };
};
