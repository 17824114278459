import { ChevronDown } from 'assets/icons';
import Text from 'components/Typography/Text';
import styled from 'styled-components/macro';

export const StyledPlaceholder = styled(Text)`
    color: ${({ theme }) => theme.colors.grey100};
    margin-left: ${({ theme }) => theme.space.s3};
    position: absolute;
`;

export const StyledSingleValue = styled(Text)`
    margin-left: ${({ theme }) => theme.space.s3};
    pointer-events: none;
    position: absolute;
`;

export const StyledIndicator = styled(ChevronDown)<{ isDisabled: boolean }>`
    cursor: pointer;
    display: inline-block;
    fill: ${({ theme, isDisabled }) =>
        isDisabled ? theme.colors.grey200 : theme.colors.black};
    height: 24px;
    line-height: 1;
    padding-right: ${({ theme }) => theme.space.s2};
    width: 24px;
`;

export const StyledValueContainer = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: grid;
    flex: 1;
    flex-wrap: wrap;
    overflow: hidden;
    padding-left: ${({ theme }) => theme.space.s2};
    position: relative;
`;

export const StyledMultiValueLabel = styled(Text)`
    padding: ${({ theme }) => `0 ${theme.space.s1} 0 ${theme.space.s3}`};
`;

export const StyledMultiValueContainer = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.grey400};
    display: flex;
    margin: ${({ theme }) => theme.space.s1};
`;

export const StyledMultiValueRemove = styled.div`
    align-items: stretch;
    cursor: pointer;
    display: grid;
    height: 24px;
    justify-content: center;
    width: 24px;
    &:hover {
        background: ${({ theme }) => theme.colors.grey300};
        color: ${({ theme }) => theme.colors.black};
        cursor: pointer;
    }
`;
