import { get, post } from 'api';
import ApiError from 'api/ApiError';
import { getUserSettings, getUserTeams, updateUserSettings } from 'api/users';
import { setFrontendLanguage } from 'helpers/LanguageHelper';
import { useEffect, useState } from 'react';
import {
    REFRESH_TOKEN,
    TEAM_ID,
    TOKEN,
    tokenChangedKey,
} from 'types/LocalStorage';

import * as config from '../../config';
import { ApiKeyInformation, BackendTokenPair } from './types';

// Used to continously check if the user is logged in.
export const useIsAuthenticated = () => {
    const [hasToken, setHasToken] = useState(isAuthenticated());

    const handler = () => {
        setHasToken(isAuthenticated());
    };

    useEffect(() => {
        document.addEventListener(tokenChangedKey, handler);
        return () => document.removeEventListener(tokenChangedKey, handler);
    }, []);

    return hasToken;
};

export const getToken = () => {
    // Retrieves the user token from localStorage
    return localStorage.getItem(TOKEN);
};

/**
 * Parses a JWT token and returns the payload. Might throw an error
 */
export const parseToken = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};

const setToken = (idToken: string) => {
    // Saves user token to localStorage
    localStorage.setItem(TOKEN, idToken);
    document.dispatchEvent(new Event(tokenChangedKey)); // Required for useIsAuthenticated hook.
};

const setRefreshToken = (idToken: string) => {
    localStorage.setItem(REFRESH_TOKEN, idToken);
};

export const isAuthenticated = () => {
    // Checks if there is a saved token and it's still valid
    const token = getToken(); // GEtting token from localstorage
    return !!token; // handwaiving here
};

export const login = async (email: string, password: string): Promise<void> => {
    const response = await post<BackendTokenPair>(
        `${config.goDomain}v2/auth/login`,
        {
            email,
            password,
        }
    );
    if (!response.parsedBody) {
        throw new ApiError(response);
    }
    setToken(response.parsedBody.access_token);
    setRefreshToken(response.parsedBody.refresh_token);
    const userSettings = await getUserSettings();
    if (userSettings.userDefaultTeamId) {
        localStorage.setItem(TEAM_ID, userSettings.userDefaultTeamId);
    } else {
        const teamsResponse = await getUserTeams();
        const teamId = teamsResponse?.[0].id;
        updateUserSettings({
            userDefaultTeamId: teamId,
        });
        localStorage.setItem(TEAM_ID, teamId);
    }
    setFrontendLanguage(userSettings.userLang);
};

export const sendResetEmail = async (email: string): Promise<void> => {
    await post<void>(`${config.goDomain}v2/auth/sendReset`, {
        email,
    });
};

export const resetPassword = async (resetRequest: {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
}): Promise<void> => {
    await post<void>(`${config.goDomain}v2/auth/reset`, resetRequest);
};

export const generateApiKey = async (userId: number): Promise<string> => {
    const response = await post<{ api_key: string }>(
        `${config.goDomain}v2/auth/api`,
        { user_id: userId }
    );
    const body = response.parsedBody;
    if (body?.api_key) {
        return body.api_key;
    }
    throw new ApiError(response);
};

export const getApiKeyInformation = async () => {
    const response = await get<ApiKeyInformation>(
        `${config.goDomain}v2/auth/api`
    );
    const information = response.parsedBody;
    if (information) {
        return information;
    }
    throw new ApiError(response);
};

export const switchTeam = async (teamHash: string) => {
    const response = await get<BackendTokenPair>(
        `${config.goDomain}v2/auth/${teamHash}/token`
    );
    localStorage.setItem(TEAM_ID, teamHash);
    if (!response.parsedBody) {
        throw new ApiError(response);
    }
    setToken(response.parsedBody.access_token);
    setRefreshToken(response.parsedBody.refresh_token);
};
