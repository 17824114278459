/* eslint-disable import/no-cycle */
import { SelfDescribingJson } from '@snowplow/browser-tracker';
import { BookingRequest } from 'api/booking/types';
import {
    Consignment,
    ConsignmentRequest,
    ConsignmentStatus,
    ProductCode,
    SearchResult,
    isRequest,
} from 'api/consignments/types';
import { LabelFormat } from 'api/docs/types';
import { PackageRequest } from 'api/packages/types';
import { QuickQuotationConsignment } from 'api/qq/types';
import { AddonCode } from 'api/search';
import { CustomerInformation, Integration } from 'api/subscriptions/types';
import { Team } from 'api/teams/types';
import { getUser } from 'api/users';
import { CurrentUser, SignupMethod, User } from 'api/users/types';
import { CompletedBooking } from 'containers/Confirm/types';
import { CustomsInvoice, InvoiceType } from 'customsInvoice/types';
import {
    clearUserData as clearAmplitudeUserData,
    group as groupAmplitude,
    identify as identifyAmplitude,
    track as trackAmplitude,
    trackRevenue as trackRevenueAmplitude,
} from 'external/amplitude';
import {
    getCheckoutEvent,
    getConsigmentBookedEvent,
    getConsignmentUnbookedEvent,
    getFirstCheckoutEvent,
    getLoginEvent,
} from 'external/analytics/events';
import {
    FreightForwarding,
    FreightForwardingSubmission,
    MonthlyShipmentsData,
    PaymentUserData,
    QualificationEvent,
    SearchResultEvent,
} from 'external/analytics/types';
import {
    clearUser as clearDataPipelineUser,
    getExperimentContext,
    getTransactionIdContext,
    identify as identifyDataPipeline,
    track as trackDataPipeline,
    trackPage,
} from 'external/dataPipeline';
import { CheckoutEvent, SignupEvent } from 'external/gtm/events';
import { getGTMParameters } from 'external/gtm/helpers';
import { identifyHubspot, track as trackHubspot } from 'external/hubspot';
import UserDataHelper from 'helpers/UserDataHelper';
import { BookingConsignment, TransportAlternative } from 'hooks/Booking/types';
import { Flow } from 'hooks/BookingFlow/types';
import { Qualification } from 'hooks/Qualification/types';
import { sha256 } from 'js-sha256';
import { DateTime } from 'luxon';
import { TOKEN } from 'types/LocalStorage';

import { Organization } from '../../api/enrichment/types';
import { trackGTMEvent } from '../gtm';
import { getPrivate } from './calculations';
import { getQualificationEvent, getSignupEvent } from './events';

/**
 * Tracks a page visit in Hubspot, Amplitude, and our own data pipeline
 */
export const page = () => {
    try {
        const pageEvent = {
            path: window.location.pathname,
            logged_in: !!localStorage.getItem(TOKEN),
            category: 'React',
        };
        trackAmplitude('Loaded a Page', pageEvent);
        const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
        _hsq.push(['setPath', window.location.pathname]);
        _hsq.push(['trackPageView']);
        trackPage();
    } catch (e) {
        // tracking is likely blocked by an adblocker
    }
};

/**
 * Sets the currently identified user to be grouped into a team.
 */
export const group = (team: Team) => {
    try {
        groupAmplitude(team.name);
    } catch (e) {
        // tracking is likely blocked by an adblocker
    }
};

/**
 * Tracks an event across Snowflake and Amplitude.
 */
const track = (
    name: string,
    event: any,
    context?: SelfDescribingJson<Record<string, unknown>>[]
) => {
    // Amplitude
    const { revenue, ...rest } = event;
    if (!isNaN(revenue)) {
        trackRevenueAmplitude(revenue, rest);
    }
    trackAmplitude(name, event);
    trackDataPipeline(name, event, context);
};

const identifyFromId = (userId: number, userProperties: any) => {
    identifyAmplitude(userId.toString(), userProperties);
    identifyHubspot(userId.toString(), userProperties);
    identifyDataPipeline(userId);
};

/**
 * Identifies a user in Snowflake, Amplitude, Hubspot, and GTM.
 */
export const identifyUser = (
    user: User,
    team: Team,
    customerInformation: CustomerInformation
) => {
    const integrations = customerInformation.apiSubscription.integrations || [];
    const integrationsProperties = integrations.reduce(
        (acc, integration) => ({
            ...acc,
            [`integration_${integration}`]: 'true',
        }),
        {}
    );
    const userProperties: { [key: string]: string } = {
        first_name: user.firstName,
        last_name: user.lastName || '',
        system_country: team.systemCountry,
        email: user.email,
        mobile_number: user.mobileNumber,
        team: team.name,
        price_tier: customerInformation.priceTierSubscription.current.tier,
        ...integrationsProperties,
        has_integrated: customerInformation.apiSubscription.hasIntegrated
            ? 'true'
            : 'false',
    };
    if (customerInformation.apiSubscription.current?.tier) {
        userProperties.api_tier =
            customerInformation.apiSubscription.current.tier;
    }
    identifyFromId(user.id, userProperties);
    trackGTMEvent('IdentifyUser_', getGTMParameters(user, team));
};

/**
 * Un-identifies a user, where possible. Should be called
 * on sign-out.
 */
export const clearUser = () => {
    clearAmplitudeUserData();
    clearDataPipelineUser();
};

/**
 * Identifies a user based on the qualification modal or search signup.
 * Sends the data to Snowflake, and Amplitude.
 */
const identifyQualificationUserProperties = (
    userId: number,
    qualification: Qualification
) => {
    identifyFromId(userId, qualification);
};

export const trackCustomsInvoicePrint = (invoice_type: InvoiceType) => {
    track('Print Customs Invoice', { invoice_type });
};

export const trackShipmentInfoSliderClick = (product_code: ProductCode) => {
    track('ShipmentInfoSlider Click', { product_code });
};

export const trackHomeButtonClick = (party: string) => {
    track('Fill Home Address', { party, location: window.location.pathname });
};

interface SignupExperiments {
    qualification_experiment?: boolean;
    onboarding_experiment?: boolean;
}

export const trackCustomsInvoices = (
    consignmentId: string,
    customsInvoices?: CustomsInvoice
) => {
    const event = {
        customs_invoices: customsInvoices,
        consignment_id: consignmentId,
    };
    track('Submit customs invoice form', event);
};

export const trackSignup = (
    method: SignupMethod,
    user: CurrentUser,
    team: Team,
    consentsToMarketing: boolean,
    experiments?: SignupExperiments,
    destination?: string,
    product?: 'Package' | 'Pallet'
) => {
    const event = getSignupEvent(
        user.email,
        method,
        team.id,
        consentsToMarketing,
        destination,
        product,
        experiments
    );

    const transactionId = user.id.toString();
    const context = [getTransactionIdContext(transactionId)];

    if (experiments) {
        context.push(getExperimentContext(experiments));
    }

    track('Sign Up', event, context);
    trackHubspot('pe25159882_sign_up', event);
    trackGTMEvent<SignupEvent>(
        'SignUp_',
        getGTMParameters(user, team, transactionId),
        {
            signup_method: method,
            experiments,
        }
    );
};

export const trackLogin = (user: User, team: Team) => {
    const event = getLoginEvent(user, team);
    track('Login Authenticated', event);
    trackGTMEvent('LoginAuthenticated_', getGTMParameters(user, team));
};

export const trackRecommendationShown = (
    requestId: string,
    recommendedProduct: string,
    recommendedQuoteId: number,
    recommendedPrice: number,
    searchResultId: string,
    packages: PackageRequest[]
) => {
    track('trackRecommendationShown', {
        recommendedProduct,
        recommendedQuoteId,
        recommendedPrice,
        requestId: requestId,
        searchResultId: searchResultId,
        packages: packages,
    });
};
/**
 * Tracks when a recommendation is clicked
 * @param recommendedProduct the recommended product
 * @param recommendedPrice the recommended price
 * @param searchResultId the search result id where the recommendation was shown
 */
export const trackRecommendationClicked = (
    recommendedProduct: string,
    recommendedQuoteId: number,
    recommendedPrice: number,
    searchResultId: string,
    requestId: string
) => {
    track('trackRecommendationClicked', {
        teamId: UserDataHelper.getTeamId(),
        recommendedProduct,
        recommendedPrice,
        recommendedQuoteId,
        searchResultId,
        requestId,
    });
};

/**
 * trackRecommendationNotShown
 * @param requestId the request id
 * @param recommendedProduct the recommended product
 * @param searchResultId the search result id
 * @param reason the reason why the recommendation was not shown
 */
export const trackRecommendationNotShown = (
    recommendedProduct: string,
    recommendedQuoteId: number,
    recommendedPrice: number,
    searchResultId: string,
    requestId: string,
    reason: string
) => {
    track('trackRecommendationNotShown', {
        teamId: UserDataHelper.getTeamId(),
        recommendedProduct: recommendedProduct,
        recommendedQuoteId: recommendedQuoteId,
        recommendedPrice: recommendedPrice,
        searchResultId: searchResultId,
        requestId: requestId,
        reason: reason,
    });
};

export const trackConsignmentBooked = (
    consignment: BookingConsignment,
    searchResultId: string,
    selectedTransportAlternative: SearchResult,
    bookingRequest: BookingRequest,
    revenue: number,
    totalShipments: number
) => {
    if (consignment) {
        const event = getConsigmentBookedEvent(
            consignment,
            searchResultId,
            selectedTransportAlternative,
            bookingRequest,
            revenue,
            totalShipments
        );
        track('Booked', event);
        trackHubspot('pe25159882_booked', event);
    }
};

export const trackConsignmentUnbooked = (shipment: any) => {
    if (shipment) {
        const event = getConsignmentUnbookedEvent(shipment);
        track('Consignment Unbooked', event);
    }
};
export const trackFirstCheckout = (
    user: User,
    team: Team,
    currency: string,
    revenue: number,
    checkout_id: string
) => {
    const event = getFirstCheckoutEvent(user.email, currency);

    const transactionId = 'fc_' + checkout_id;
    const context = [getTransactionIdContext(transactionId)];

    track('First Checkout', event, context);
    trackGTMEvent<CheckoutEvent>(
        'FirstCheckout_',
        getGTMParameters(user, team, transactionId),
        {
            checkout_revenue: revenue,
            currency_code: currency,
        }
    );
};

export const trackCheckout = (
    owner: any,
    email: string,
    consignments: number,
    currency: string,
    revenue: number,
    checkoutId: string
) => {
    const event = getCheckoutEvent(
        email,
        consignments,
        currency || UserDataHelper.getCurrency() || '',
        revenue
    );

    const context = [getTransactionIdContext(checkoutId)];

    track('Checkout', event, context);
};

export const trackQualification = (
    user: User,
    team: Team,
    qualification: Qualification
) => {
    const flattenObject = (ob: any) => {
        const toReturn = {} as any;

        for (const i in ob) {
            if (!Object.prototype.hasOwnProperty.call(ob, i)) continue;

            if (typeof ob[i] == 'object' && ob[i] !== null) {
                const flatObject = flattenObject(ob[i]);
                for (const x in flatObject) {
                    if (!Object.prototype.hasOwnProperty.call(flatObject, x))
                        continue;

                    toReturn[i + '_' + x] = flatObject[x];
                }
            } else {
                toReturn[i] = ob[i];
            }
        }
        return toReturn;
    };
    const event = getQualificationEvent(qualification);
    const flat = flattenObject(event);
    identifyQualificationUserProperties(user.id, flat);
    track('Signup Qualification', flat);
    trackGTMEvent<QualificationEvent>(
        'SignupQualification_',
        getGTMParameters(user, team),
        flat
    );
};

export const trackConsignmentAdded = (id: string) => {
    track('Consignment Added To Cart From Draft', {
        consignment_id: id,
    });
};

export const trackConsignmentAddedToCart = (
    pathname: string,
    req: ConsignmentRequest
) => {
    track('Add Consignment To Cart', {
        pathname: pathname,
        system: req.externalSource?.system,
        source: req.externalSource?.source,
    });
};

export const trackCartOpened = (source: string) => {
    track('Cart Opened', {
        source,
    });
};

export const trackStartBookingFlow = (
    pathname: string,
    consignments: Consignment[]
) => {
    let has_integration = false;
    const systems = consignments.reduce((acc, consignment) => {
        if (consignment.externalSource !== undefined) {
            has_integration = true;
            const integration = consignment.externalSource?.system
                ? `integration_${consignment.externalSource?.system}`
                : 'integration_UNKNOWN';

            return {
                ...acc,
                [integration]: true,
            };
        } else {
            return acc;
        }
    }, {});
    const event = {
        pathname: pathname,
        number_of_consignments: consignments.length,
        has_integration,
        ...systems,
    };
    track('Start Booking Flow', event);
};

export const trackSearchResults = (
    user: User,
    team: Team,
    searchResultId: string,
    consignment: BookingConsignment,
    alternatives: TransportAlternative[],
    flow: Flow,
    newBadges: string[]
) => {
    const cheapestAlternative =
        alternatives.length === 0
            ? undefined
            : alternatives.reduce((a, b) =>
                  a.customerCost < b.customerCost ? a : b
              );

    const goods = consignment.packages.map((p) => ({
        goodsType: p.type,
        quantity: p.quantity,
        weightKg: p.weightKg,
        depthCm: p.depthCm,
        widthCm: p.widthCm,
        heightCm: p.heightCm,
    }));
    const event: SearchResultEvent = {
        search_origin: flow === 'qq' ? 'QQ' : 'new shipment',
        newBadges,
        consignment_id: isRequest(consignment) ? undefined : consignment.id,
        search_result_id: searchResultId,
        solutions: alternatives.map((a) => ({
            quote_id: a.id,
            lead_time_days: {
                min: a.leadTimeWorkingDaysMin,
                max: a.leadTimeWorkingDaysMax,
            },
        })),
        goods,
        from_country_code: consignment.sender.address.countryCode,
        from_post_code: consignment.sender.address.postalCode,
        stackable: consignment.packages[0].stackable,
        to_country_code: consignment.recipient.address.countryCode,
        to_post_code: consignment.recipient.address.postalCode,
        to_private: getPrivate(consignment.recipient.isPrivate),
        number_of_solutions: alternatives.length,
        cheapest_solution_sendify_contract:
            cheapestAlternative?.customerCost.toString() || '',
        cheapest_solution_sendify_contract_product: cheapestAlternative
            ? cheapestAlternative.product.code
            : '',
    };
    const context = [getTransactionIdContext(searchResultId)];

    track('Search Results', event, context);
    trackGTMEvent<SearchResultEvent>(
        'SearchResults_',
        getGTMParameters(user, team, searchResultId),
        event
    );
};

export const trackBookingEvents = async (
    bookingsToTrack: CompletedBooking[],
    team: Team
) => {
    const successfulBookings = bookingsToTrack.filter((b) => b.success);

    const revenue = successfulBookings.reduce(
        (accumulator: number, booking) =>
            accumulator + (booking.request.expectedCost || 0),
        0
    );

    const user = await getUser();
    const currency =
        bookingsToTrack[0]?.selectedTransportAlternative.currency || '';
    const owner = bookingsToTrack[0]?.consignment.owner;

    // Create a unique transaction ID for the checkout event based on all the consignment ids in the checkout.
    // sha256 is chosen since the max length of this field in Google Tag Manager is 64 bytes == 256 bits.
    const checkoutId = sha256(
        successfulBookings.reduce((prev: string, booking: CompletedBooking) => {
            return prev + booking.consignment.id;
        }, '')
    );

    const shouldTrackFirstCheckout = owner && !owner.hasBooked;

    if (shouldTrackFirstCheckout) {
        trackFirstCheckout(user, team, checkoutId, revenue, currency);
    }

    trackCheckout(
        owner,
        user.email,
        successfulBookings.length,
        currency,
        revenue,
        checkoutId
    );
    trackGTMEvent<CheckoutEvent>(
        'Checkout_',
        getGTMParameters(user, team, checkoutId),
        {
            checkout_revenue: revenue,
            currency_code: currency,
        }
    );
    await Promise.all(
        successfulBookings.map((booking) =>
            trackConsignmentBooked(
                booking.consignment,
                booking.searchResultId,
                booking.selectedTransportAlternative,
                booking.request,
                revenue,
                successfulBookings.length
            )
        )
    );
};

export const trackFortnoxConnect = () =>
    track('Fortnox Activated', { team_id: UserDataHelper.getTeamId() });

export const trackFortnoxDisconnect = () =>
    track('Fortnox Deactivated', { team_id: UserDataHelper.getTeamId() });

export const trackFortnoxOrderImport = (
    total: number,
    successful: number,
    failed: number
) =>
    track('Fortnox Order Import', {
        team_id: UserDataHelper.getTeamId(),
        total_imported: total,
        successful,
        failed,
    });

export const trackFortnoxOrderSearch = (total: number, source: string) =>
    track('Fortnox Order Search', {
        team_id: UserDataHelper.getTeamId(),
        total_orders: total,
        source,
    });

export const trackIntegrationAborted = (team_id: string) => {
    track('Integration Aborted', { team_id });
};

export const trackIntegrationStarted = (integration: Integration) => {
    track('Integration Started', {
        integration,
    });
};

export const trackIntegrationComplete = (integration: Integration) => {
    track('Integration Complete', {
        integration,
    });
};

export const trackViewFreightForwarding = (
    freightForwarding: FreightForwarding
) => {
    track('View Freight Forwarding', {
        total_weight: freightForwarding.totalWeight,
        to_country: freightForwarding.toCountry,
        from_country: freightForwarding.fromCountry,
    });
};

export const trackPressFreightForwardingButton = (
    freightForwarding: FreightForwarding
) => {
    track('Press Freight Forwarding Button', {
        total_weight: freightForwarding.totalWeight,
        to_country: freightForwarding.toCountry,
        from_country: freightForwarding.fromCountry,
    });
};

export const trackSubmitFreightForwarding = (
    freightForwardingSubmission: FreightForwardingSubmission
) => {
    track('Submit Freight Forwarding', {
        total_weight: freightForwardingSubmission.totalWeight,
        to_country: freightForwardingSubmission.toCountry,
        from_country: freightForwardingSubmission.fromCountry,
        services: freightForwardingSubmission.services,
        goods_value: freightForwardingSubmission.goodsValue,
        freight_types: freightForwardingSubmission.freightTypes,
        earliest_pickup: freightForwardingSubmission.earliestPickup,
        latest_delivery: freightForwardingSubmission.latestDelivery,
    });
};

/**
 * Tracks that the user submits a carrier claim.
 * @param remindedOfInvoice The user didn't submit an invoice when initially pressing submit
 * @param withInvoice The form contains a commercial invoice
 * @param consignmentHash Our internal hash of the consignment
 * @param trackingId The tracking number, supplied by the carrier
 */
export const trackSubmitClaim = (
    remindedOfInvoice: boolean,
    withInvoice: boolean,
    consignmentHash: string,
    trackingId: string
) => {
    track('Submit Claim', {
        with_invoice: withInvoice,
        reminded_of_invoice: remindedOfInvoice,
        tracking_id: trackingId,
        consignment_id: consignmentHash,
    });
};

/**
 * Tracks which label format the user selects (3x1, 1x2, printer are the only options for now)
 * @param labelFormat The format the user selected.
 * @param numberOfLabels How many labels were to be printed
 */
export const trackLabelFormatSelected = (
    labelFormat: LabelFormat,
    numberOfLabels: number
) => {
    track('Select label format', {
        format: labelFormat,
        amount: numberOfLabels,
    });
};

export const trackMonthlySpendOnShipmentsChanges = (
    monthlyShipment: MonthlyShipmentsData
) => {
    track('Click on input field on Price Tier Tab', {
        monthlyShipment,
    });
};

export const trackSliderMonthlySpendOnShipmentsChanges = (
    monthlyShipment: MonthlyShipmentsData
) => {
    track('Click on slider on Price Tier Tab', {
        monthlyShipment,
    });
};

export const trackPaymentButton = (paymentUserData: PaymentUserData) => {
    track('Press Payment Button', {
        userName: paymentUserData.userName,
        countryCode: paymentUserData.countryCode,
        city: paymentUserData.city,
    });
};

export const trackUserTypeButtonClick = (user_type: string) => {
    track('Select User Type', { user_type });
};

export const trackRemoveIntegrationModalOpen = () =>
    track('Remove Integration Modal Open', {
        team_id: UserDataHelper.getTeamId(),
    });

export const trackRemoveIntegrationModalSuccess = () =>
    track('Remove Integration Modal Success', {
        team_id: UserDataHelper.getTeamId(),
    });

export const trackBulkAddToCart = (numberOfConsignmentsUpdated: number) =>
    track('Bulk Add To Cart', {
        numberOfConsignmentsUpdated,
    });

export const trackBulkPickupDate = (numberOfConsignmentsUpdated: number) =>
    track('Bulk Update Pickup Date', {
        numberOfConsignmentsUpdated,
    });

export const trackBulkAddParcels = (numberOfConsignmentsUpdated: number) =>
    track('Bulk Update Parcels', {
        numberOfConsignmentsUpdated,
    });

export const trackCustomsInvoiceFormButtonClick = () =>
    track('Click customs invoice link', {});

export const trackCloseSendifyPlusCTA = () =>
    track('Close Sendify Plus CTA', {
        team_id: UserDataHelper.getTeamId(),
    });

export const trackClickSendifyPlusCTA = () =>
    track('Click Sendify Plus CTA', {
        team_id: UserDataHelper.getTeamId(),
    });

/**
 * Tracks that the user toggled Sendify insurance from the area where they
 * may toggle it for all shipments.
 *
 * @param enabled true if and only if the new value of the toggle is "true"
 * @numberOfShipments the number of shipments toggled
 */
export const trackMultiToggleInsurance = (
    enabled: boolean,
    numberOfShipments: number
) =>
    track('Toggle Sendify insurance for a multiple shipments', {
        numberOfShipments,
        toggle: enabled ? 'on' : 'off',
    });

/**
 * Tracks that the user toggled Sendify insurance from an individual
 * shipment area.
 *
 * @param enabled true if and only if the new value of the toggle is "true"
 */
export const trackSingleToggleInsurance = (enabled: boolean) =>
    track('Toggle Sendify insurance for a single shipment', {
        toggle: enabled ? 'on' : 'off',
    });

/**
 * The user searches for a shipment in the shipment list.
 *
 * @param query The user-inputted search query.
 */
export const trackShipmentSearch = (query: string) =>
    track('Search for a shipment', {
        query,
    });

/**
 * The user filters on status in the shipment list.
 *
 * @param statuses The selected statuses. May be empty.
 */
export const trackStatusFilter = (statuses: ConsignmentStatus[]) =>
    track('Filter shipment list on statuses', {
        statuses,
    });

/**
 * The user filters on date in the shipment list.
 *
 * @param start the start date, as selected in the date picker
 * @param end the end date, as selected in the date picker
 */
export const trackDateFilter = (start: DateTime, end: DateTime) =>
    track('Filter shipment list on dates', {
        startDate: start.toISODate(),
        endDate: end.toISODate(),
    });

/**
 * The user tracks a shipment.
 *
 * @param consignmentHash the tracked shipment's consignment hash
 * @param status the tracked shipment's status (sent along as it may
 * change over time)
 */
export const trackShipmentTracked = (
    consignmentHash: string,
    status: ConsignmentStatus
) =>
    track('Track a shipment', {
        consignmentHash,
        status,
    });

/**
 * The user clicks an item in the action menu of a shipment.
 *
 * @param action The action, or list item, that was clicked
 * @param consignmentHash the tracked shipment's consignment hash
 * @param consignmentStatus the tracked shipment's status (sent along
 * as it may change over time)
 */
export const trackActionMenuClick = (
    action: string,
    consignmentHash: string,
    consignmentStatus: ConsignmentStatus
) =>
    track('Click an action menu item for a shipment', {
        action,
        consignmentHash,
        consignmentStatus,
    });

/**
 * The user clicks the "Switch" to opt in/out of Sendify Secure.
 * @param teamId
 * @param value
 */
export const trackSendifySecureOptIn = (teamId: string, value: boolean) => {
    track('Toggle Sendify Secure Opt in/out', {
        teamId,
        value: value ? 'opt in' : 'opt out',
    });
};

export const trackOpenAddonsModal = (
    consignmentHash: string,
    button: string
) => {
    track('Open Addons Modal', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
        button,
    });
};

export const trackCloseAddonsModal = (consignmentHash: string) => {
    track('Close Addons Modal', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
    });
};

export const trackSaveAddonsModal = (
    consignmentHash: string,
    selectedAddons: string[]
) => {
    track('Save Addons Modal', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
        selectedAddons,
    });
};

export const trackClearAddonsModal = (consignmentHash: string) => {
    track('Clear Addons Modal', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
    });
};

export const trackOpenLegacyAddonsModal = (consignmentHash: string) => {
    track('Open Legacy Addons Modal', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
    });
};

export const trackOpenLegacyInsuranceModal = (consignmentHash: string) => {
    track('Open Legacy Insurance Modal', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
    });
};

export const trackCloseLegacyAddonsModal = (
    consignmentHash: string,
    button: string
) => {
    track('Close Legacy Addons Modal', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
        button,
    });
};

export const trackCloseLegacyInsuranceModal = (
    consignmentHash: string,
    button: string
) => {
    track('Close Legacy Insurance Modal', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
        button,
    });
};

export const trackSaveLegacyAddonsModal = (
    consignmentHash: string,
    selectedAddons: AddonCode[]
) => {
    track('Save Legacy Addons Modal', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
        selectedAddons,
    });
};

export const trackSaveLegacyInsuranceModal = (consignmentHash: string) => {
    track('Save Legacy Insurance Modal', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
    });
};

export const trackRemoveLegacyAddon = (
    consignmentHash: string,
    selectedAddon: AddonCode
) => {
    track('Remove Legacy Addon', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
        selectedAddon,
    });
};

export const trackIncompatibleAddons = (
    consignmentHash: string,
    selectedAddons: AddonCode[]
) => {
    track('Incompatible Addons Selected', {
        teamId: UserDataHelper.getTeamId(),
        consignmentHash,
        selectedAddons,
    });
};

export const trackCancelShipment = (
    userId: number,
    teamId: string,
    consignmentId: string
) => {
    track('CANCEL_SHIPMENT', {
        userId,
        teamId,
        consignmentId,
    });
};

export const trackLabelsOpened = (
    consignmentIds: string[],
    teamId: string,
    userId: number,
    labelSize: LabelFormat
) => {
    const payload = {
        consignmentIds,
        selectedCount: consignmentIds.length,
        teamId,
        userId,
        labelSize,
    };
    track('PRINT_LABEL', payload);
};

export const trackTrackingAdLinkClicked = (
    elementId: string,
    trackingId: string
) => {
    const payload = {
        elementId,
        trackingId,
    };
    track('Tracking ad link clicked', payload);
};

export const trackOnboardingMarkedAsDone = (step: string) => {
    track('Onboarding Marked As Done', { step });
};

/** Tracks the time spent on the registration modal
 *  @param step The step the user is currently on
 *  @param duration The time spent on the step
 * */
export const trackRegistrationModalStep = (step: string, duration?: number) => {
    track('Full Registration Step', { step, duration });
};
/**
 * Tracks the KYC search.
 * @param nameQuery
 * @param orgNumberQuery
 * @param cityQuery
 * @param resultsLength
 */
export const trackKycSearch = (
    searchTerm: string,
    cityQuery: string,
    resultsLength: number
) => {
    track('Full registration kyc search', {
        searchTerm,
        cityQuery,
        resultsLength,
    });
};
/** Tracks the time spent on the registration modal
 * @param step The step the user is currently on
 */
export const trackRegistrationModalClosed = (step: string) => {
    track('Registration modal closed', { step });
};

/** Tracks that the user has opened the registration modal.
 * @param currentPageUrl The URL of the page the user was on when they opened the modal.
 * If applicable.
 */
export const trackRegistrationModalOpened = (currentPageUrl: string) => {
    track('Registration modal opened', { currentPageUrl });
};

/** The user selects a KYC search result.
 * @param ranking The ranking of the selected search result.
 * @param organization The selected organization.
 */
export const trackSelectedKycSearchResult = (
    ranking: number,
    organization: Organization
) => {
    track('Use kyc search result', {
        ranking,
        organization,
    });
};

export const trackKycManualEntrySelected = () => {
    track('Click kyc manual entry', { teamId: UserDataHelper.getTeamId() });
};

/** The user selects the hint text to search with help of City from the KYC search results.
 * @param currentSearchTerm The current search term in the KYC search field when the hint was selected.
 * @param currentCityInput The current city input in the KYC search field when the hint was selected.
 * Default is empty string.
 */
export const trackKycHintSelected = (
    currentSearchTerm: string,
    currentCityInput: string
) => {
    track('Use kyc hint', {
        currentSearchTerm,
        currentCityInput,
    });
};

/** Tracks that the user have submitted the KYC registration form.
 * @param manual True if the user has manually entered the information, false if they have used the KYC search.
 * @param modifiedFields The fields the user has modified.
 */
export const trackKycRegistration = (
    teamId: string,
    manual: boolean,
    modifiedFields: string[]
) => {
    track('Submit kyc registration', {
        teamId,
        manual,
        modifiedFields,
    });
};

export const trackOnboardingCtaClicked = (step: string) => {
    track('Onboarding CTA Click', { step });
};

export const trackOnboardingBannerCtaClicked = () => {
    track('Onboarding Banner CTA Click', {});
};

export const trackOnboardingBannerDismissed = () => {
    track('Onboarding Banner Dismissed', {});
};

export const trackOnboardingStepDismissed = (
    step: string,
    method: 'close' | 'not_interested'
) => {
    track('Onboarding Step Dismissed', {
        step,
        method,
    });
};

export const trackOnboardingMainCtaClicked = () => {
    track('Onboarding Main CTA Click', {});
};

export const trackOnboardinDismissMainActions = () => {
    track('Dismiss Main Actions', {});
};

export const trackMultiPrintReceipt = (
    consignmentIds: string[],
    teamId: string,
    userId: number
) => {
    track('SHIPMENT_MULTI_PRINT_RECEIPT_CLICK', {
        consignmentIds,
        selectedCount: consignmentIds.length,
        teamId,
        userId,
    });
};

export const trackMultiCancelClick = (
    consignmentIds: string[],
    teamId: string,
    userId: number
) => {
    track('SHIPMENT_MULTI_CANCEL_CLICK', {
        consignmentIds,
        selectedCount: consignmentIds.length,
        teamId,
        userId,
    });
};

export const trackMultiPrintLabelClick = (
    consignmentIds: string[],
    teamId: string,
    userId: number
) => {
    track('SHIPMENT_MULTI_PRINT_LABEL_CLICK', {
        consignmentIds,
        selectedCount: consignmentIds.length,
        teamId,
        userId,
    });
};

/** Temporary event to test vs Snowplow's built-in pv event */
export const trackPageView = () => {
    track('PAGE_VIEW', {
        pageReferer: document.referrer,
        pageUrl: window.location.href,
        userAgent: window.navigator.userAgent,
    });
};

export const trackOpenFavoriteConsignmentModal = (consignmentId: string) => {
    track('Open Favorite Consignment Modal', {
        consignmentId,
    });
};

export const trackCloseFavoriteConsignmentModal = (
    consignmentId: string,
    method: 'cross' | 'cancel'
) => {
    track('Close Favorite Consignment Modal', { consignmentId, method });
};

export const trackCreateFavoriteConsignment = (
    favoriteId: number,
    consignmentId: string
) => {
    track('Create Favorite Consignment', {
        favoriteId,
        consignmentId,
    });
};

interface NavBarExperiments {
    book_shipment_as_a_cta?: boolean;
}

export const trackCreateNewShipment = (
    source: 'navbar' | 'dropdown',
    numberOfFavorites: number,
    experiments: NavBarExperiments,
    favoriteId?: number
) => {
    const context = [getExperimentContext(experiments)];

    track(
        'Create New Shipment',
        {
            source,
            numberOfFavorites,
            favoriteId,
        },
        context
    );
};

export const trackAddNewShipmentToCart = (
    consignmentId: string,
    favoriteId?: number
) => {
    track('Add New Shipment To Cart', {
        favoriteId,
        consignmentId,
    });
};

export const trackDeleteShipment = (favoriteId: number) => {
    track('Remove Integration Modal Open', {
        team_id: UserDataHelper.getTeamId(),
        favoriteId,
    });
};

/**
 * Tracks both interactions and final changes of the time slider.
 *
 * @param productCode - The product code of the shipment.
 * @param searchResultId - The search result id of the shipment.
 * @param consignmentHash - The consignment hash of the shipment.
 * @param {DateTime} startTime - The current or final start time.
 * @param {DateTime} endTime - The current or final end time.
 * @param {DateTime} [defaultStartTime] - Default start time before the user made changes.
 * @param {DateTime} [defaultEndTime] - Default end time before the user made changes.
 */
export const trackTimeSliderEvent = (
    productCode: string,
    searchResultId: string,
    consignmentHash: string | undefined,
    startTime: DateTime,
    endTime: DateTime,
    defaultStartTime: DateTime,
    defaultEndTime: DateTime
) => {
    track('Time Slider Changed', {
        productCode: productCode,
        searchResultId: searchResultId,
        consignmentHash: consignmentHash ?? 'unknown_consignment_id',
        startTime: startTime.toISO(),
        endTime: endTime.toISO(),
        defaultStartTime: defaultStartTime.toISO(),
        defaultEndTime: defaultEndTime.toISO(),
    });
};

export const trackCollapseShipment = (
    consignmentId: string,
    index: number,
    collapsed: boolean
) => {
    track('Collapse Shipment', {
        consignmentId,
        index,
        collapsed,
    });
};

export const trackSelectFavoritePackage = (
    favoriteId: number,
    /** The shipment form where the selector is located */
    form: string,
    /**  The index of the goods form in the shipment form */
    index: number
) => {
    track('Select Favorite Package', {
        favoriteId,
        form,
        index,
    });
};

export const trackAddUnit = (form: string, existingAmount: number) => {
    track('Add Unit', {
        form,
        existingAmount,
    });
};

export const trackClickEditQQ = (
    consignment: BookingConsignment,
    method: string
) => {
    track('Click Edit QQ', {
        consignment,
        method,
    });
};

export const trackSubmitCompareQQ = (
    consignment: QuickQuotationConsignment
) => {
    track('Submit Compare QQ Edit', {
        consignment,
    });
};

export const trackCancelCompareQQ = () => {
    track('Cancel Compare QQ Edit', {});
};

export const trackClearCompareQQ = () => {
    track('Click Clear QQ', {});
};

export const trackClearDashboardQQ = () => {
    track('Click clear in dashboard QQ', {});
};

export const trackSearchDashboardQQ = () => {
    track('Click search in dashboard QQ', {});
};

export const trackCloseRecommendationModal = (
    recommendedProduct: string,
    recommendedQuoteId: number,
    recommendedPrice: number,
    searchResultId: string,
    requestId: string
) => {
    track('Close recommendation modal', {
        recommendedProduct,
        recommendedQuoteId,
        recommendedPrice,
        searchResultId,
        requestId,
    });
};

export const trackClickSaveRecommendationModal = (
    recommendedProduct: string,
    recommendedQuoteId: number,
    recommendedPrice: number,
    searchResultId: string,
    requestId: string
) => {
    track('Save recommendation modal', {
        recommendedProduct,
        recommendedQuoteId,
        recommendedPrice,
        searchResultId,
        requestId,
    });
};
