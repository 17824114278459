import { AsyncSelect } from 'components/FormElements/Select';
import styled from 'styled-components/macro';

export const SelectContainer = styled.div`
    align-items: end;
    display: grid;
    grid-gap: ${({ theme }) => theme.space.s4};
    grid-template-columns: 1fr auto;
    justify-content: center;
    justify-self: center;
    max-width: 550px;
    padding: ${({ theme }) => theme.space.s1};
    width: 100%;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        max-width: 100%;
    }
`;

export const FullWitdhSelect = styled(AsyncSelect)`
    align-self: end;
    width: 100%;
`;
