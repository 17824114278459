import * as Sentry from '@sentry/react';
import {
    BackendQualification,
    BackendSentInvite,
    BackendTeam,
    BackendTeamSettings,
    BackendTeamStats,
    SentInvite,
    Team,
    TeamSettings,
    TeamStats,
} from 'api/teams/types';
import { backendUserToUser } from 'api/users/mapper';
import { getQualificationMinMax } from 'external/analytics/calculations';
import { Qualification } from 'hooks/Qualification/types';
import { DateTime } from 'luxon';

import { backendAddressToAddress } from '../consignments/mapper';
import {
    BackendEnrichMatch,
    BackendIdentifier,
    BackendKycAddress,
    BackendMatch,
    EnrichOrganization,
    Identifier,
    KYC,
    KycAddress,
    Organization,
} from '../enrichment/types';

export const backendTeamToTeam = (backendTeam: BackendTeam): Team => ({
    avatarUrl: backendTeam.avatar_url,
    blocked: backendTeam.blocked,
    currency: backendTeam.currency,
    customerId: backendTeam.customer_id,
    hasBillingInfo: backendTeam.has_billing_info,
    homeAddress: backendAddressToAddress(backendTeam.home_address),
    id: backendTeam.id,
    erp: backendTeam.erp,
    identityNumber: backendTeam.identity_number,
    invoiceAddress: backendTeam.invoice_address
        ? backendAddressToAddress(backendTeam.invoice_address)
        : undefined,
    members: backendTeam.members.map((member) => backendUserToUser(member)),
    name: backendTeam.name,
    systemCountry: backendTeam.system_country,
    priceTier: backendTeam.price_tier,
    apiTier: backendTeam.api_tier,
    marginGroups: backendTeam.margin_groups,
    hasExperiment: backendTeam.has_experiment,
    legacySendifyPlusExpiryDate: backendTeam.legacy_sendify_plus_expiry_date
        ? DateTime.fromISO(backendTeam.legacy_sendify_plus_expiry_date)
        : undefined,
    createdAt: DateTime.fromISO(backendTeam.createdAt),
});

export const backendKycToKyc = (backendKycMatches: BackendMatch[]): KYC => {
    // Map and filter out invalid matches, skip those without DUNS identifier
    const matches = backendKycMatches
        .map((backendMatch) => {
            try {
                return backendMatchToMatch(backendMatch);
            } catch (e) {
                Sentry.captureException(e);
                return null; // Skip this match if an error occurs
            }
        })
        .filter((match): match is Organization => !!match);

    return {
        matches,
    };
};

export const backendEnrichMatchToEnrichedMatch = (
    backendEnrichMatch: BackendEnrichMatch
): EnrichOrganization => {
    return {
        id: backendEnrichMatch.id,
        name: backendEnrichMatch.name,
        identifiers: backendIdentifiersToIdentifiers(
            backendEnrichMatch.identifiers
        ),
        address: backendKycAddressToAddress(backendEnrichMatch.address),
    };
};

export const backendMatchToMatch = (
    backendMatch: BackendMatch
): Organization => {
    // A match will always have a DUNS identifier, and possibly other identifiers.
    // We can thus use the DUNS identifier as a unique identifier for the organization.
    const id = backendMatch.identifiers.find(
        (identifier) => identifier.type === 'DUNS'
    )?.identifier;

    if (!id) {
        // This should never happen due to the pre-check in backendKycToKyc, but is here as a safeguard
        throw new Error(
            `No DUNS identifier found for match ${backendMatch.name}`
        );
    }

    return {
        id,
        name: backendMatch.name,
        identifiers: backendIdentifiersToIdentifiers(backendMatch.identifiers),
        address: backendKycAddressToAddress(backendMatch.address),
        companyLegalForm: backendMatch.companyLegalForm,
    };
};

export const backendKycAddressToAddress = (
    address: BackendKycAddress
): KycAddress => ({
    city: address.city,
    countryCode: address.country_code,
    postalCode: address.postal_code,
    province: address.province,
    streetLine1: address.street_line_1,
    streetLine2: address.street_line_2,
});

export const backendIdentifiersToIdentifiers = (
    identifiers: BackendIdentifier[]
): Identifier[] => {
    return identifiers.map((identifier) => ({
        identifier: identifier.identifier,
        type: identifier.type,
    }));
};

export const backendTeamStatsToTeamStats = (
    backendTeamStats: BackendTeamStats
): TeamStats => ({
    total: backendTeamStats.total,
    ordered: backendTeamStats.ordered,
    confirmed: backendTeamStats.confirmed,
    inTransit: backendTeamStats.in_transit,
    delivered: backendTeamStats.delivered,
    returnBooked: backendTeamStats.return_booked,
    exception: backendTeamStats.exception,
    deliveredMarked: backendTeamStats.delivered_marked,
    canceled: backendTeamStats.canceled,
});

export const toBackendTeamSettingsPayload = (
    t: Partial<TeamSettings>
): Partial<BackendTeamSettings> => ({
    team_integrations_fortnox_activated: t.teamIntegrationsFortnoxActivated,
    team_integrations_fortnox_activation_code:
        t.teamIntegrationsFortnoxActivationCode,
    team_onboarding: t.teamOnboarding,
    team_send_consignee_mail: t.teamSendConsigneeMail,
    team_send_consignor_mail: t.teamSendConsignorMail,
    team_send_loading_mail: t.teamSendLoadingMail,
    team_send_unloading_mail: t.teamSendUnloadingMail,
    team_sendify_secure_active: t.teamSendifySecureActive,
    team_sendify_secure_default_goods_value:
        t.teamSendifySecureDefaultGoodsValue,
});

export const backendTeamSettingsToTeamSettings = (
    backendSettings: BackendTeamSettings
): TeamSettings => ({
    teamIntegrationsFortnoxActivated:
        backendSettings.team_integrations_fortnox_activated,
    teamIntegrationsFortnoxActivationCode:
        backendSettings.team_integrations_fortnox_activation_code,
    teamOnboarding: backendSettings.team_onboarding,
    teamSendConsigneeMail: backendSettings.team_send_consignee_mail,
    teamSendConsignorMail: backendSettings.team_send_consignor_mail,
    teamSendLoadingMail: backendSettings.team_send_loading_mail,
    teamSendUnloadingMail: backendSettings.team_send_unloading_mail,
    teamSendifySecureDefaultGoodsValue:
        backendSettings.team_sendify_secure_default_goods_value,
    teamSendifySecureActive: backendSettings.team_sendify_secure_active,
});

export const backendSentInviteToSentInvite = (
    invite: BackendSentInvite
): SentInvite => {
    return {
        invitedByUser: invite.invited_by_user,
        token: invite.token,
        createdAt: DateTime.fromISO(invite.created_at),
        email: invite.email,
        active: invite.active,
    };
};

export const qualificationToBackendQualification = (
    qualification: Qualification
): BackendQualification => {
    const turnover = qualification.company?.turnover;
    const size = qualification.company?.size;
    const parcel = qualification.shipping?.volume?.parcel;
    const pallet = qualification.shipping?.volume.pallet;
    const multipallet = qualification.shipping?.volume?.multipallet;

    const { min: turnover_min, max: turnover_max } =
        getQualificationMinMax(turnover);
    const { min: size_min, max: size_max } = getQualificationMinMax(size);
    const { min: parcel_min, max: parcel_max } = getQualificationMinMax(parcel);
    const { min: pallet_min, max: pallet_max } = getQualificationMinMax(pallet);
    const { min: multipallet_min, max: multipallet_max } =
        getQualificationMinMax(multipallet);
    const carriers = qualification.shipping?.carriers;
    const integrations = qualification.systems?.integrations;
    return {
        company: {
            industry: qualification?.company?.industry,
            turnover_min,
            turnover_max,
            size_min,
            size_max,
        },
        shipping: {
            volume: {
                parcel_min,
                parcel_max,
                pallet_min,
                pallet_max,
                multipallet_min,
                multipallet_max,
            },
            carriers: Object.keys(carriers ?? {}).filter(
                (key) => typeof carriers?.[key] === 'boolean'
            ),
            otherCarrier: carriers?.other as string,
        },
        systems: {
            integrations: Object.keys(integrations ?? {}).filter(
                (key) => typeof integrations?.[key] === 'boolean'
            ),
            otherIntegration: qualification.systems?.integrations
                ?.other as string,
        },
    };
};
