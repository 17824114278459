import { Subscription } from 'api/subscriptions/types';

import { useTeam } from './Queries';
import { useSubscriptions } from './Queries/subscriptions';

export interface TrialInfo {
    status: TrialStatus;
    subscription?: Subscription;
    productCode: TrialProductCode;
    trialDaysLeft: number;
}

type TrialProductCode =
    | 'plus_14_trial_sweden'
    | 'pro_14_trial_global'
    | 'plus_14_trial_germany';

type TrialStatus = 'notUsed' | 'used' | 'started' | 'ending' | 'ended';

const isSubscriptionATrial = (subscription: Subscription) => {
    const trialCodes: TrialProductCode[] = [
        'plus_14_trial_sweden',
        'pro_14_trial_global',
        'plus_14_trial_germany',
    ];

    return subscription.items.some((i) => {
        return trialCodes.includes(i.product.code as TrialProductCode);
    });
};

const getTrialSubscription = (subscriptions: Subscription[]) => {
    return subscriptions.find(isSubscriptionATrial);
};

const useSubscriptionTrialInfo = (): TrialInfo | undefined => {
    const { subscriptions } = useSubscriptions();
    const { team } = useTeam();

    if (!subscriptions?.subscriptions || !team) return undefined;

    const trialSub = getTrialSubscription(subscriptions.subscriptions);
    if (
        !trialSub ||
        !trialSub.endDate ||
        trialSub.items.length < 1 ||
        trialSub.items[0].marginGroups.length < 1
    ) {
        return undefined;
    }

    const today = new Date();
    const trialEndDate = new Date(trialSub?.endDate);
    const timeDifference = trialEndDate.getTime() - today.getTime();
    const trialDaysLeft = Math.round(timeDifference / (1000 * 3600 * 24));

    let status: TrialStatus;
    if (trialDaysLeft >= 4) status = 'started';
    else if (trialDaysLeft >= 0) status = 'ending';
    else if (trialDaysLeft >= -7) status = 'ended';
    else status = 'used';

    return {
        status: status,
        trialDaysLeft: trialDaysLeft,
        subscription: trialSub,
        productCode: trialSub.items[0].product.code as TrialProductCode,
    };
};

export default useSubscriptionTrialInfo;
