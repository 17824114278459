import countries, { Alpha2Code } from 'i18n-iso-countries';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlagWrapper, StyledFlag } from './StyledFlag';

interface Props {
    className?: string;
    countryCode?: Alpha2Code;
    size?: string;
}

const Flag = ({ className, countryCode, size = '24px' }: Props) => {
    const [flagSource, setFlagSource] = useState<string>();
    const { i18n } = useTranslation();
    const isMountedRef = useRef(true);
    const isMounted = useCallback(() => isMountedRef.current, []);

    useEffect(() => {
        return () => void (isMountedRef.current = false);
    }, []);

    const setFlagIfMounted = (src?: string) => {
        if (isMounted()) {
            setFlagSource(src);
        }
    };

    useEffect(() => {
        const loadUnknownFlag = async () => {
            try {
                const src = await import(`assets/flags/_unknown.png`);
                setFlagIfMounted(src.default);
            } catch {
                setFlagIfMounted(undefined);
            }
        };
        const loadFlag = async () => {
            try {
                const src = await import(`assets/flags/${countryCode}.png`);
                setFlagIfMounted(src.default);
            } catch {
                loadUnknownFlag();
            }
        };
        if (countryCode) {
            loadFlag();
        }
    }, [countryCode]);

    const altText = countryCode
        ? countries.getName(countryCode, i18n.languages[0])
        : countryCode;
    return (
        <FlagWrapper className={className} size={size}>
            {flagSource && (
                <StyledFlag size={size} alt={altText} src={flagSource} />
            )}
        </FlagWrapper>
    );
};

export default Flag;
