import { useQuery } from '@tanstack/react-query';
import { getFavoritePackages } from 'api/packages';
import { FavoritePackage } from 'api/packages/types';

import { MetaData } from './../../api/types';

/** Get the favorite packages for the current team.
 * @param pageNo The page number to get. Default is 0.
 * @param perPage The number of items per page. Default is 100.
 */
export const useFavoritePackages = (pageNo = 0, perPage = 100) => {
    const { data, ...rest } = useQuery<{
        packages: FavoritePackage[];
        meta: MetaData;
    }>(['favoritePackages', pageNo, perPage], () =>
        getFavoritePackages(perPage, pageNo)
    );
    return {
        packages: data?.packages,
        meta: data?.meta,
        ...rest,
    };
};
