import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';

/**
 * LaunchDarkly flags with our own types
 */

export type SendifyInsurancePricing =
    | {
          minPrice: number;
          multiplier: number;
      }
    | undefined;

type infoBannerContent = {
    buttonText?: string;
    buttonLink?: string;
    title: string;
    subtitle: string;
};

export type IntegrationStatus =
    | 'ENABLED'
    | 'DISABLED'
    | 'UPCOMING'
    | 'BETA'
    | 'NEW';

export interface LDFlags {
    adminCanUpdatePriceTier: boolean;
    beamer: boolean;
    dpdLogoVariants: '1' | '2' | '3';
    importNewBadge: boolean;
    infoBanner: {
        name: string;
        sv: infoBannerContent;
        de: infoBannerContent;
        en: infoBannerContent;
    };
    plusTrialMarginGroup: {
        targetingEnabled: boolean;
        marginGroupName: string;
        externalLink: string;
        startDate: string;
        userSelected: boolean;
    };
    signupQualificationDisabled: boolean;
    integrationOwnIntegration: IntegrationStatus;
    integrationFortnox: IntegrationStatus;
    integrationWooCommerce: IntegrationStatus;
    integrationShopify: IntegrationStatus;
    integrationVisma: IntegrationStatus;
    integrationSpecter: IntegrationStatus;
    integrationOngoing: IntegrationStatus;
    integrationProsmart: IntegrationStatus;
    integrationPyramid: IntegrationStatus;
    integrationMagento: IntegrationStatus;
    integrationVismaAdmin2000: IntegrationStatus;
    integrationDynamics365: IntegrationStatus;
    integrationWinbas: IntegrationStatus;
    integrationMonitor: IntegrationStatus;
    newProductBadge: string[];
    recommendedSlider: boolean;
    sendifyInsurancePricing: SendifyInsurancePricing;
    showBlockButton: boolean;
    useNewWaybillEndpoint: boolean;
    useNewManifestEndpoint: boolean;
    signupExperiment: boolean;
    showGuaranteedDelivery: boolean;
    useStampedLabels: boolean;
    newDeInsuranceClaimFlow: boolean;
    useBookWithoutPickup: boolean;
    userOnboarding: boolean;
    enableInvoiceSubscription: boolean;
    showAnalyticsNavigationOption: boolean;
    showAnalyticsBetaBadge: boolean;
    dnbFullRegistration: boolean;
    newCompareQuickQuoteForm: boolean;
    removeBookedPage: boolean;
    bookAShipmentAsACta: boolean;
    customsBeforeBook: boolean;
    showRecommendations: boolean;
    showSenderReceiverReference: boolean;
}

export const useFlags = () => useLDFlags<LDFlags>();
