import { page, trackPageView } from 'external/analytics';
import { trackGTMEvent } from 'external/gtm';
import { PageViewEvent } from 'external/gtm/events';
import { getGTMParameters } from 'external/gtm/helpers';
import { useTeam, useUser } from 'hooks/Queries';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { WithChildren } from 'types';

/**
 * This component can be used as wrapper to send Page event to our trackers on location change
 */
const PageTracker = ({ children }: WithChildren) => {
    const { pathname } = useLocation();
    const { user } = useUser();
    const { team } = useTeam();
    /**
     * Intialize prevpath to document.referrer so that we track the correct referrer to GTM
     * when referred to the app from external sources.
     */
    const prevPath = useRef<string>(document.referrer);
    useEffect(() => {
        const url = `${window.location.origin}${pathname}`;
        page();
        if (user && team) {
            trackGTMEvent<PageViewEvent>(
                'VirtualPageView_',
                getGTMParameters(user, team),
                {
                    page_name: pathname,
                    page_path: pathname,
                    page_location: url,
                    document_referrer: prevPath.current,
                }
            );
        }
        /**
         * Update the ref with current path so the next internal routing gets referred to by it.
         */
        prevPath.current = url;

        trackPageView();
    }, [pathname]);

    return <>{children}</>;
};

export default PageTracker;
