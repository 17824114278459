import { get, patch, post, put } from 'api';
import ApiError from 'api/ApiError';
import { addressToBackendAddress } from 'api/consignments/mapper';
import {
    BackendSentInvite,
    BackendTeam,
    BackendTeamSettings,
    BackendTeamUpdate,
    SentInvite,
    Team,
    TeamSettings,
    TeamStats,
    TeamUpdate,
} from 'api/teams/types';
import { group } from 'external/analytics';
import queryString from 'query-string';

import * as config from '../../config';
import { Qualification } from '../../hooks/Qualification/types';
import { CURRENCY, SYSTEM_COUNTRY } from '../../types/LocalStorage';
import {
    backendSentInviteToSentInvite,
    backendTeamSettingsToTeamSettings,
    backendTeamStatsToTeamStats,
    backendTeamToTeam,
    qualificationToBackendQualification,
    toBackendTeamSettingsPayload,
} from './mapper';

export const getTeam = async (): Promise<Team> => {
    const response = await get<{ data: BackendTeam }>(
        `${config.goDomain}v2/team`
    );

    if (response?.parsedBody?.data) {
        const team = backendTeamToTeam(response.parsedBody.data);
        localStorage.setItem(CURRENCY, team.currency);
        localStorage.setItem(SYSTEM_COUNTRY, team.systemCountry);
        group(team);
        return team;
    }
    throw new ApiError(response);
};

export const updateTeam = async (team: TeamUpdate): Promise<Team> => {
    const payload: BackendTeamUpdate = {
        identity_number: team.identityNumber,
        name: team.name,
        home_address: addressToBackendAddress(team.homeAddress),
    };
    if (team.organizationId) {
        payload.organization_id = team.organizationId;
    }
    if (team.invoiceAddress) {
        payload.invoice_address = addressToBackendAddress(team.invoiceAddress);
    }
    const response = await put<{ data: BackendTeam }>(
        `${config.goDomain}v2/team`,
        payload
    );
    if (response?.parsedBody?.data) {
        return backendTeamToTeam(response.parsedBody.data);
    } else {
        throw new ApiError(response);
    }
};

export const updateTeamAvatar = async (data: FormData): Promise<void> => {
    const response = await post(`${config.goDomain}v2/team/avatar`, data);
    if (response) {
        return;
    }
    throw new ApiError(response);
};

export const getTeamAvatar = async (): Promise<string | undefined> => {
    const response = await get(`${config.goDomain}v2/team/avatar`);
    if (response.status === 204) {
        return undefined;
    }
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    return url;
};

export const searchTeam = async (query: string): Promise<Team[]> => {
    const params = {
        page: 1,
        per_page: 50,
    };
    const response = await get<{ data: BackendTeam[] }>(
        `${config.goDomain}v2/team/search?q=${query}&${queryString.stringify(
            params
        )}`
    );
    if (response?.parsedBody?.data) {
        return response.parsedBody.data.map((team) => backendTeamToTeam(team));
    }
    throw new ApiError(response);
};

export const getSentInvites = async (): Promise<SentInvite[]> => {
    const response = await get<{ data: BackendSentInvite[] }>(
        `${config.goDomain}v2/team/invites`
    );
    if (response?.parsedBody?.data) {
        return response.parsedBody.data.map(backendSentInviteToSentInvite);
    }
    throw new ApiError(response);
};

export const updateTeamSettings = async (
    settings: Partial<TeamSettings>
): Promise<TeamSettings> => {
    const response = await patch<BackendTeamSettings>(
        `${config.goDomain}v2/team/settings`,
        toBackendTeamSettingsPayload(settings)
    );
    if (response?.parsedBody) {
        return backendTeamSettingsToTeamSettings(response.parsedBody);
    }
    throw new ApiError(response);
};

export const getTeamStats = async (): Promise<TeamStats> => {
    const response = await get(`${config.goDomain}v2/team/stats`);

    if (response?.parsedBody) {
        return backendTeamStatsToTeamStats(response.parsedBody);
    }
    throw new ApiError(response);
};

export const qualify = async (qualification: Qualification) => {
    return post(
        `${config.goDomain}v2/team/signupQualification`,
        qualificationToBackendQualification(qualification)
    );
};

export const getHasQualified = async (): Promise<boolean> => {
    const response = await get<{ qualified: boolean }>(
        `${config.goDomain}v2/team/signupQualification`
    );
    if (response?.parsedBody?.qualified !== undefined) {
        return response.parsedBody.qualified;
    }
    throw new ApiError(response);
};
