import { Close } from 'assets/icons/material';
import { IconButton } from 'components/Buttons';
import { usePersistentState } from 'hooks/usePersistentState';
import { ReactNode } from 'react';

import { Content, InfoWrapper } from './StyledInfoBanner';

export const useTrialBanner = (name: string) => {
    const [showBanner, setShowBanner] = usePersistentState(name, true);

    const closeBanner = () => {
        setShowBanner(false);
    };

    return { showBanner, closeBanner };
};

interface Props {
    onClose: () => void;
    children?: ReactNode;
    show: boolean;
}

const InfoBanner = ({ onClose, show, children }: Props) => {
    if (!show) {
        return null;
    }

    return (
        <InfoWrapper>
            <Content>
                {children}
                <IconButton icon={<Close />} onClick={onClose} />
            </Content>
        </InfoWrapper>
    );
};

export default InfoBanner;
