import { sendSlackMessage } from 'api/slack';
import { Button } from 'components/Buttons';
import { useTrialBanner } from 'components/InfoBanner/InfoBanner';
import Modal, {
    ModalContent,
    ModalFooter,
    ModalHeader,
} from 'components/Modal';
import Text from 'components/Typography/Text';
import { usePersistentState } from 'hooks/usePersistentState';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { WithChildren } from 'types';

type ModalTrigger = 'click' | 'auto';

interface UseTrialBannersOptions {
    // Whether the user has seen the modal via a 'click' event eg. CTA.
    // This is used to determine whether we should show the modal again
    // when the trial has ended.
    hasSeenModal: boolean;
    // Set whether the user has seen the modal.
    setHasSeenModal: (shown: boolean) => void;
    // Whether to show the trial banner.
    showTrialBanner: boolean;
    // Close the trial banner.
    closeTrialBanner: () => void;
    // Show the pro modal.
    // They type of trigger determines the message to show in the modal.
    // set to null to close the modal.
    setModalTrigger: (trigger: ModalTrigger | null) => void;
}

const TrialContext = createContext<UseTrialBannersOptions | null>(null);

TrialContext.displayName = 'TrialBannersContext';

export const useTrialContext = (): UseTrialBannersOptions => {
    const context = useContext(TrialContext) as UseTrialBannersOptions;
    if (!context) {
        throw new Error('useTrialBannersContext used outside provider');
    }
    return context;
};

// TrialProvider is a context provider that manages the state of the trial banner and popups.
// It provides a context for the trial banner and pro modal.
// The plus trial is handled via redirects to the settings modal since users can buy that at any time.
export const TrialProvider = ({ children }: WithChildren) => {
    const [modalTrigger, setModalTrigger] = useState<ModalTrigger | null>(null);
    const [hasSeenModal, setHasSeenModal] = usePersistentState(
        'seenPaymentModal',
        false
    );

    const { showBanner, closeBanner } = useTrialBanner('trialBanner');

    const onClosePro = () => {
        setModalTrigger(null);
    };

    return (
        <TrialContext.Provider
            value={{
                hasSeenModal,
                setHasSeenModal,
                showTrialBanner: showBanner,
                closeTrialBanner: closeBanner,
                setModalTrigger,
            }}
        >
            <ProModal trigger={modalTrigger} onClose={onClosePro} />

            {children}
        </TrialContext.Provider>
    );
};

interface ProModalProps {
    onClose: () => void;
    trigger: ModalTrigger | null;
}

const ProModal = ({ onClose, trigger }: ProModalProps) => {
    const { t } = useTranslation('dashboard');

    const [isLoading, setIsLoading] = useState(false);

    const onProTrialRequest = async () => {
        try {
            setIsLoading(true);
            await sendSlackMessage({ type: 'proTrialRequest' });
            onClose();

            toast.success(t('dashboard.proTrialModal.success'));
        } catch (error: any) {
            toast.error(t('dashboard.proTrialModal.error'));
        } finally {
            setIsLoading(false);
        }
    };

    let title = t('dashboard.proTrialModal.title');
    let description = t('dashboard.proTrialModal.description');

    if (trigger == 'auto') {
        title = t('dashboard.proTrialModal.autoTitle');
        description = t('dashboard.proTrialModal.autoDescription');
    }

    return (
        <Modal showModal={trigger != null} onClose={onClose} width="600px">
            <ModalHeader title={title} />
            <ModalContent>
                <Text>{description}</Text>
            </ModalContent>
            <ModalFooter>
                <StyledFooterContent>
                    <Button variant="secondary" onClick={onClose}>
                        {t('dashboard.proTrialModal.noThanks')}
                    </Button>
                    <Button loading={isLoading} onClick={onProTrialRequest}>
                        {t('dashboard.proTrialModal.button')}
                    </Button>
                </StyledFooterContent>
            </ModalFooter>
        </Modal>
    );
};

const StyledFooterContent = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;
`;
