import { post } from 'api';
import ApiError from 'api/ApiError';

import * as config from '../../config';

export interface SlackMessage {
    type: string;
}

export const sendSlackMessage = async (
    slackMessage: SlackMessage
): Promise<SlackMessage> => {
    const response = await post<SlackMessage>(
        `${config.goDomain}v2/slack`,
        slackMessage
    );
    if (response.parsedBody) {
        return response.parsedBody;
    }
    throw new ApiError(response);
};
